import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {AdminRole, BrandAdminRoleDto, EventDto, ParentEventDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import { nameof } from "../../helpers/nameofHelper";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {useSelector} from "react-redux";

export default function EventsList(props: CrudTableWrapperProps<EventDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<EventDto> = [{
        key: nameof<EventDto>("name"),
        render: (names) => <LocalizableReadonly value={names} />
    }, {
        key: nameof<EventDto>("startDate"),
        render: (date: string) => {
            return<>
                {moment(date).format("YYYY-MM-DD")}
            </>
        }
    }, {
        key: nameof<EventDto>("endDate"),
        render: (date: string) => {
            return <>
                {moment(date).format("YYYY-MM-DD")}
            </>
        }
    }, {
        key: nameof<EventDto>("parentEvent"),
        render: (date: ParentEventDto | null) => <LocalizableReadonly value={date?.name || undefined} />
    }];

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle="+ Add new event"
        />
    </>
}
