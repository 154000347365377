import ReportsView from "./reportsView";
import React, { useState } from "react";
import { AchievementApi, AchievementUpdateDto, MissingLanguageDto } from "../../api";
import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";
import AchievementModalFormAddContainer from "../achievements/achievementModalFormAddContainer/achievementModalFormAddContainer";

export default function AchievementReportViewContainer(props: AchievementReportViewContainerProps) {
    const [isAddAchievementFormVisible, setAddAchievementFormVisible] = useState(false);
    const [idAchievementToEdit, setIdAchievementToEdit] = useState<number | null>(null);
    const [AchievementModalFormContainerTitle, setAchievementModalFormContainerTitle] = useState<string>();
    const [AchievementToEdit, setAchievementToEdit] = useState<AchievementUpdateDto | null>(null);

    const apiService = useInjection(ApiService);

    const onSubmit = async (values: any) => {
        await apiService.getApi(AchievementApi).apiAchievementIdPut(idAchievementToEdit!, values);
        setAddAchievementFormVisible(false);
        props.onReportsRefreshClicked();
    }

    const onEditClicked = async (entityId: number, type: any) => {
        setAchievementModalFormContainerTitle(`Edit Achievement with id: ${entityId}`);
        setIdAchievementToEdit(entityId);

        const resp = await apiService.getApi(AchievementApi).apiAchievementIdGet(entityId);
        setAchievementToEdit(resp.data as AchievementUpdateDto);

        setAddAchievementFormVisible(true);
    }

    return <>
        <AchievementModalFormAddContainer
            onFinish={() => setAddAchievementFormVisible(false)}
            onSubmit={onSubmit}
            addFormVisibility={isAddAchievementFormVisible}
            title={AchievementModalFormContainerTitle || ""}
            achievementToEdit={AchievementToEdit}
        />

        <ReportsView
            reportDetails={props.reportDetails}
            onReportsRefreshClicked={props.onReportsRefreshClicked}
            onEditClicked={onEditClicked}
        />
    </>
}

interface AchievementReportViewContainerProps {
    reportDetails: MissingLanguageDto[][],
    onReportsRefreshClicked: () => any,
}