import { useInjection } from "inversify-react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { ApplicationUserApi, EndForgotPasswordDto } from "../../../api";
import ApiService from "../../../services/apiService";
import { RoutingPaths } from "../../routingContainer";
import EndForgotPassword from "./endForgotPassword";

export default function EndForgotPasswordContainer() {
    
    const apiService = useInjection(ApiService);
    const history = useHistory();
    const location = useLocation();
    
    const token = new URLSearchParams(location.search).get("token");

    if (!token) {
        history.replace(RoutingPaths.Login);

        return null;
    }

    const onSubmit = async (value: EndForgotPasswordDto): Promise<any> => {
        const applicationUserApi = apiService.getApi(ApplicationUserApi);

        try {
            value.token = token;
            await applicationUserApi.apiApplicationUserEndForgotPasswordPost(value);
    
            history.replace(RoutingPaths.Login);
        } catch (e) {
            return e.response.data;
        }        
    }

    return <>
        <EndForgotPassword onSubmit={onSubmit} />
    </>;
}