import { Skeleton } from "antd";
import { useInjection } from "inversify-react";
import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ApplicationUserApi, BrandAdminRoleApi, LanguageApi, ProductAvailabilityLanguageApi } from "../api";
import ProtectedRoute from "../components/protectedRoute/protectedRoute";
import { setAllBrandAdminRoles } from "../redux/brandAdminRolesSlice";
import { setAllLanguages } from "../redux/languageSlice";
import { setUser } from "../redux/userSlice";
import ApiService from "../services/apiService";
import ActivateContainer from "./authentication/activate/activateContainer";
import EndForgotPasswordContainer from "./authentication/endForgotPassword/endForgotPasswordContainer";
import ForgotPasswordContainer from "./authentication/forgotPassword/forgotPasswordContainer";
import BrandTermsListContainer from "./brandTerms/brandTermsListContainer";
import CourseEditRoutingContainer from "./courses/courseEditLayout";
import CoursesListContainer from "./courses/coursesListContainer";
import EventEditFormContainer from "./events/eventEditFormContainer/eventEditFormContainer";
import EventsListContainer from "./events/eventsListContainer";
import LivestreamsListContainer from "./livestreams/livestreamsListContainer";
import OrganisationsRoutingContainer from "./organisations/organisationsRoutingContainer";
import ProductsListContainer from "./products/productsListContainer";
import TranslationsListContainer from "./translations/translationsListContainer";
import VideosListContainer from "./videos/videosListContainer";
import ReportsListContainer from "./reports/reportsListContainer";
import AchievementsListContainer from "./achievements/achievementsListContainer";
import AchievementEditFormContainer from "./achievements/achievementEditFormContainer/achievementEditFormContainer";
import AchievementCategoriesListContainer from "./achievementCategories/achievementCategoriesListContainer";
import AchievementCategoryEditFormContainer from "./achievementCategories/achievementCategoryEditFormContainer/achievementCategoryEditFormContainer";
import ProductEditFormContainer from "./products/productEditFormContainer/productEditFormContainer";
import KenticoLogin from "./authentication/kenticoLogin/kenticoLogin";
import { setProductLanguages } from "../redux/productLanguageSlice";
import CalendarsListContainer from "./calendars/calendarsListContainer";
import UsersListContainer from "./users/usersListContainer";
import UserStatsFormContainer from "./users/userStatsFormContainer/userStatsFormContainer";
import KenticoRegistrationForm from "./authentication/kenticoRegistration/kenticoRegistrationForm";

export enum RoutingPaths {
    Login = "/login",
    Activate = "/activate",
    ForgotPassword = "/forgot-password",
    EndForgotPassword = "/forgot-password-reset",
    Organisations = "/brands",
    OrganisationCreate = "/brands/create",
    OrganisationEdit = "/brands/edit/:id",
    CoursesList = "/brand/:brandId/courses",
    CourseEdit = "/brand/:brandId/course/:courseId",
    CourseModuleEdit = "/brand/:brandId/course/:courseId/module/:moduleId",
    TranslationList = "/brand/:brandId/translations",
    VideosList = "/brand/:brandId/videos",
    LivestreamsList = "/brand/:brandId/livestreams",
    CalendarsList = "/brand/:brandId/calendars",
    ProductsList = "/brand/:brandId/products",
    ProductEdit = "/brand/:brandId/product/:productId",
    EventsList = "/brand/:brandId/events",
    EventEdit = "/brand/:brandId/event/:eventId",
    BrandTermsList = "/brand/:brandId/brandTerms",
    ReportsList = "/brand/:brandId/reports",
    AchievementsList = "/brand/:brandId/achievements",
    AchievementEdit = "/brand/:brandId/achievement/:achievementId",
    AchievementCategoriesList = "/brand/:brandId/achievementCategories",
    AchievementCategoryEdit = "/brand/:brandId/achievementCategory/:achievementCategoryId",
    UsersList = "/brand/:brandId/users",
    UserStats = "/brand/:brandId/user/:userId",
    KenticoLogin = "/kentico-login",
}

export default function RoutingContainer() {
    const roleRefreshInterval = 60_000; // 1 min
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();

    useEffect(() => {
        apiService.getApi(LanguageApi).apiLanguageGet()
            .then(l => dispatch(setAllLanguages(l.data)));

        apiService.getApi(BrandAdminRoleApi).apiBrandAdminRoleGet()
            .then(l => dispatch(setAllBrandAdminRoles(l.data)));
    }, []);

    const auth = useAuth();

    useEffect(() => {
        if (!auth.userData) {
            return;
        }

        apiService.getApi(ApplicationUserApi).apiApplicationUserIdGet(auth.userData.profile.sub as any)
            .then(u => dispatch(setUser(u.data)));

        apiService.getApi(ProductAvailabilityLanguageApi).apiProductAvailabilityLanguageGet()
            .then(l => dispatch(setProductLanguages(l.data)));

        const id = setInterval(() => {
            apiService.getApi(ApplicationUserApi).apiApplicationUserIdGet(auth.userData!.profile.sub as any)
                .then(u => dispatch(setUser(u.data)));
        }, roleRefreshInterval);
        return () => clearInterval(id);
    });

    if (auth.isLoading) {
        return <Skeleton />;
    }

    return <Switch>
        <Route exact path={[RoutingPaths.Activate]}>
            <ActivateContainer />
        </Route>
        <Route exact path={[RoutingPaths.ForgotPassword]}>
            <ForgotPasswordContainer />
        </Route>
        <Route exact path={[RoutingPaths.EndForgotPassword]}>
            <EndForgotPasswordContainer />
        </Route>

        <Route exact path={[RoutingPaths.KenticoLogin]}>
            <KenticoLogin />
        </Route>

        <ProtectedRoute path={RoutingPaths.CoursesList}>
            <CoursesListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.VideosList}>
            <VideosListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.LivestreamsList}>
            <LivestreamsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.CalendarsList}>
            <CalendarsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.ProductsList}>
            <ProductsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.ProductEdit}>
            <ProductEditFormContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.TranslationList}>
            <TranslationsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.EventsList}>
            <EventsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.EventEdit}>
            <EventEditFormContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.BrandTermsList}>
            <BrandTermsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.ReportsList}>
            <ReportsListContainer/>
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.CourseEdit}>
            <CourseEditRoutingContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.AchievementsList}>
            <AchievementsListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.AchievementEdit}>
            <AchievementEditFormContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.AchievementCategoriesList}>
            <AchievementCategoriesListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.AchievementCategoryEdit}>
            <AchievementCategoryEditFormContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.UsersList}>
            <UsersListContainer />
        </ProtectedRoute>

        <ProtectedRoute path={RoutingPaths.UserStats}>
            <UserStatsFormContainer/>
        </ProtectedRoute>

        <Route path="/test/externalauthentication">
            <KenticoRegistrationForm />
        </Route>

        <ProtectedRoute path={[RoutingPaths.Organisations, "/"]}>
            <OrganisationsRoutingContainer />
        </ProtectedRoute>
    </Switch>;
}
