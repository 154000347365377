import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import {nameof} from "../../../helpers/nameofHelper";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import {DatePicker, Select} from "antd";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import {
    EventLivestreamCreateDto,
    EventLivestreamDetailsDto,
    EventLivestreamUpdateDto, FileInfoUpdateDtoLocalizableEntityDto,
    VimeoLiveEventDto
} from "../../../api";
import moment from "moment";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";
import LocalizableFileUploader from "../../../components/localizableFileUploader/localizableFileUploader";

const {Option} = Select;


export default function EventLivestreamModalForm(props: EventLivestreamModalFormProps) {
    let initValues = null;

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<EventLivestreamCreateDto>("title"),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Event livestream title is required"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>("description"),
            children: <LocalizableInput/>
        }, {
            name: nameof<EventLivestreamCreateDto>("start"),
            children: <DatePicker showTime />,
            rules: [
                {
                    required: true,
                    message: "Event livestream start field is required"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>("end"),
            children: <DatePicker showTime/>,
            rules: [
                {
                    required: true,
                    message: "Event livestream end filed is required"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>("image"),
            label: "Image",
            children: <LocalizableFileUploader
                fileUploaderProps={{ useOrganisationFileApi: true }}
                areFilesPublic={true}
            />,
            // children: <SingleFileUploader
            //     accept="image/jpeg,image/png"
            //     sizeLimitMb={2}
            //     defaultFile={
            //         props.initialValues?.eventLivestreamFile
            //             ? Mapper.FileInfoDto_UploadFile(props.initialValues?.eventLivestreamFile)
            //             : undefined
            //     }
            //     useOrganisationFileApi={true}
            // />,
            rules: [
                {
                    required: true,
                    message: "Event livestream image is required"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>("languageSpoken"),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Event livestream spoken language is required"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>("vimeoLiveEventId"),
            label: "Vimeo Livestream Name",
            children: <Select>
                {props.vimeoLiveEvents?.map(vle =>
                    <Option key={vle.id!} value={vle.id!}>{vle.title}</Option>
                )}
            </Select>,
            rules: [
                {
                    required: true,
                    message: "Vimeo livestream has to be assigned"
                }
            ]
        }, {
            name: nameof<EventLivestreamCreateDto>('isVisible'),
            label: "Is visible",
            children: <AsyncSwitch/>
        }
    ]

    if (props.initialValues !== null) {
         initValues = {
            "title": props.initialValues!.title,
            "description": props.initialValues!.description,
            "start": moment(props.initialValues!.start),
            "end": moment(props.initialValues!.end),
            "image": props.initialValues!.image,
            "languageSpoken": props.initialValues!.languageSpoken,
            "vimeoLiveEventId": props.initialValues!.vimeoLiveEventId,
             "isVisible": props.initialValues!.isVisible
        }
    }

    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={initValues ?? props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
        width={875}
    />;
}

interface EventLivestreamModalFormProps {
    initialValues?: EventLivestreamDetailsDto,
    isModalVisible: boolean,
    setModalVisibility: (isVisible: boolean) => any,
    onSubmit: (value: EventLivestreamCreateDto | EventLivestreamUpdateDto) => Promise<any>,
    modalTitle: string,
    vimeoLiveEvents: VimeoLiveEventDto[] | null,
    saveButtonTitle: string;
}