import { message } from "antd";
import { useInjection } from "inversify-react";
import React from "react";
import { useHistory } from "react-router";
import { ApplicationUserApi, StartForgotPasswordDto } from "../../../api";
import ApiService from "../../../services/apiService";
import { RoutingPaths } from "../../routingContainer";
import ForgotPassword from "./forgotPassword";

export default function ForgotPasswordContainer() {

    const apiService = useInjection(ApiService);
    const history = useHistory();

    const onSubmit = async (value: StartForgotPasswordDto): Promise<any> => {
        const applicationUserApi = apiService.getApi(ApplicationUserApi);

        try {
            await applicationUserApi.apiApplicationUserBeginForgotPasswordPost(value);

            message.info("If the email address you provided belongs to an account, you'll receive an email with instructions on how to reset your password.");
            history.replace(RoutingPaths.Login);
        } catch (e) {
            return e.response.data;
        }
    }

    return <>
        <ForgotPassword onSubmit={onSubmit} />
    </>;
}