
import { PickerDndObject } from "../../../picker-items/PickerDndObject";
import { SectionItemType } from "../../../../../../api";
import { FontSizeOutlined, PictureOutlined, VideoCameraOutlined, FileOutlined } from "@ant-design/icons";
import SplittedElementPickerItemTile from "../../../../components/elementPickerItem/splitted-version/splittedElementPickerTile";

export default function ElementPickerDragOverlay(props: ElementPickerDragOverlayProps) {

  const getIconByType = (type: SectionItemType) => {
    switch (type) {
      case SectionItemType.Text:
        return <FontSizeOutlined />
      case SectionItemType.File:
        return <PictureOutlined />
      case SectionItemType.Video:
        return <VideoCameraOutlined />
      case SectionItemType.Scorm:
        return <FileOutlined />
      default:
        throw Error(`No service for type ${type}`);
    }
  }

  return <SplittedElementPickerItemTile
    icon={getIconByType(props.item.pickerElementType)}
  />;
}

export interface ElementPickerDragOverlayProps {
  item: PickerDndObject
}
