import ReportsView from "./reportsView";
import TranslationModalFormAddContainer from "../translations/translationModalFormAddContainer/translationModalFormAddContainer";
import React, { useState } from "react";
import { MissingLanguageDto, TranslationApi, TranslationUpdateDto } from "../../api";
import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";

export default function TranslationReportViewContainer(props: TranslationReportViewContainerProps) {
    const [isAddTranslationFormVisible, setAddTranslationFormVisible] = useState(false);
    const [idTranslationToEdit, setIdTranslationToEdit] = useState<number | null>(null);
    const [translationModalFormContainerTitle, setTranslationModalFormContainerTitle] = useState<string>();
    const [translationToEdit, setTranslationToEdit] = useState<TranslationUpdateDto | null>(null);

    const apiService = useInjection(ApiService);

    const onSubmit = async (values: any) => {
        await apiService.getApi(TranslationApi).apiTranslationIdPut(idTranslationToEdit!, values);
        setAddTranslationFormVisible(false);
        props.onReportsRefreshClicked();
    }

    const onEditClicked = async (entityId: number, type: any) => {
        setTranslationModalFormContainerTitle(`Edit translation with id: ${entityId}`);
        setIdTranslationToEdit(entityId);

        const resp = await apiService.getApi(TranslationApi).apiTranslationIdGet(entityId)
        setTranslationToEdit(resp.data as TranslationUpdateDto);

        setAddTranslationFormVisible(true);
    }

    return <>
        <TranslationModalFormAddContainer
            onFinish={() => setAddTranslationFormVisible(false)}
            onSubmit={onSubmit}
            addFormVisibility={isAddTranslationFormVisible}
            title={translationModalFormContainerTitle || ""}
            translationToEdit={translationToEdit}
        />

        <ReportsView
            reportDetails={props.reportDetails}
            onReportsRefreshClicked={props.onReportsRefreshClicked}
            onEditClicked={onEditClicked}
        />
    </>
}

interface TranslationReportViewContainerProps {
    reportDetails: MissingLanguageDto[][],
    onReportsRefreshClicked: () => any,
}