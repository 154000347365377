import React from "react";
import { CalendarCreateDto, CalendarUpdateDto } from "../../../api";
import CalendarModalForm from "../calendarModalForm/calendarModalForm";

export default function CalendarModalFormAddContainer(props: CalendarModalFormAddContainerProps) {
    return <CalendarModalForm
        isModalVisible={props.addFormVisibility}
        setModalVisibility={props.onFinish}
        onSubmit={props.onSubmit}
        modalTitle={props.title}
        saveButtonTitle="Submit"
        initialValues={props.calendarToEdit!}
    />
}

interface CalendarModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void,
    onSubmit: (values: CalendarCreateDto | CalendarUpdateDto) => Promise<any>,
    addFormVisibility: boolean,
    title: string,
    calendarToEdit: CalendarUpdateDto | null
}
