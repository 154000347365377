import { useDraggable } from "@dnd-kit/core";
import { ReactNode } from "react";
import { CSS } from '@dnd-kit/utilities';

export default function ElementPickerDraggableWrapper(props: ElementPickerDraggableWrapperProps) {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
    });

    return <div ref={setNodeRef} style={{transform: CSS.Translate.toString(transform)}} {...listeners} {...attributes}>
        {props.children}
    </div>;
}

export interface ElementPickerDraggableWrapperProps {
    children: ReactNode,
    id: string
}
