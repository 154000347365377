import { Input, Layout } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationUserActivationDto } from '../../../api';
import CrudForm from '../../../components/crudForm/crudForm';
import { CrudFormItemProps, CrudFormWrapperProps } from '../../../components/crudForm/crudFormProps';
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from '../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb';
import { nameof } from '../../../helpers/nameofHelper';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 4 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 4 },
};

const formItems: CrudFormItemProps[] = [{
    name: nameof<ApplicationUserActivationDto>("email"),
    children: <Input disabled={true} />
},{
    name: nameof<ApplicationUserActivationDto>("password"),
    rules: [{ required: true, message: 'This field is required' }],
    children: <Input.Password />
}];

export default function Activate(props: CrudFormWrapperProps<ApplicationUserActivationDto>) {
    const location = useLocation();
    
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const initValues: ApplicationUserActivationDto = {
        token,
        email
    };
    
    return  <Layout>
        <CypherCmsBreadcrumb>
            <CypherCmsBreadcrumbItem>
                Activate account
            </CypherCmsBreadcrumbItem>
        </CypherCmsBreadcrumb>
        <CrudForm
            initialValues={initValues}
            onSubmit={props.onSubmit}
            formItems={formItems}
            saveButtonTitle="Activate"
            formProps={layout}
            tailProps={tailLayout} />
    </Layout>;
}