import styles from "../elementPickerItem.module.scss";

export default function SplittedElementPickerItemSubtitle(props: SplittedElementPickerItemSubtitleProps) {

    return <>
        <div className={styles.title}>
            {props.title}
        </div>
    </>;
}

export interface SplittedElementPickerItemSubtitleProps {
    title: string;
}
