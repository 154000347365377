import { useSelector } from "react-redux";
import { BrandAdminRoleDto, LanguageDto, OrganisationUserInvitationDto, OrganisationUserRole } from "../../../../api";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../../components/crudFormModal/crudFormModal";
import EntityTransfer from "../../../../components/entityTransfer/entityTransfer";
import { nameof } from "../../../../helpers/nameofHelper";
import { getAllBrandAdminRoles } from "../../../../redux/brandAdminRolesSlice";
import { getAllLanguages } from "../../../../redux/languageSlice";

export default function OrganisationUserInviteModalForm(props: OrganisationFormProps) {
    const allLocales = useSelector(getAllLanguages);
    const allBrandAdminRoles = useSelector(getAllBrandAdminRoles);

    const initValues: OrganisationUserInvitationDto = {
        role: OrganisationUserRole.BrandAdmin,
    };

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<OrganisationUserInvitationDto>("email"),
            rules: [
                { required: true, message: 'This field is required' },
                { type: "email", message: "Email format is incorrect" }
            ]
        },
        {
            name: nameof<OrganisationUserInvitationDto>("name"),
            rules: [{ required: true, message: 'This field is required' }]
        },
        {
            name: nameof<OrganisationUserInvitationDto>("availableLocalesIds"),
            label: "Locales",
            rules: [{required: true, message: 'This field is required'}],
            children:
                <EntityTransfer
                    dataSource={allLocales as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<LanguageDto>("locale")}
                />
        },
        {
            name: nameof<OrganisationUserInvitationDto>("brandAdminRolesIds"),
            label: "Roles",
            rules: [{required: true, message: 'This field is required'}],
            children:
                <EntityTransfer
                    dataSource={allBrandAdminRoles as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<BrandAdminRoleDto>("role")}
                />
        }
    ];

    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={initValues}
        saveButtonTitle="Invite" 
        modalOkText="Invite"
        modalTitle="Invite brand administrator"
        isModalVisible={props.isModalVisible}
    />;
}

interface OrganisationFormProps {
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: OrganisationUserInvitationDto) => Promise<any>
}
