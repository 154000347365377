import React, {useEffect, useState} from "react";
import LocalizableInput, { getEntryValueForLanguageOrEmptyString } from "../localizableInput/localizableInput";
import styles from "./localizableVideo.module.scss"
import {Result} from 'antd'
import {RuleObject} from "antd/lib/form";
import {StoreValue} from "antd/lib/form/interface";
import { LocalizableStringDto } from "../../api";
import { Language } from "../languageSelector/languageSelector";
import { useSelector } from "react-redux";
import { getGlobalLanguage } from "../../redux/languageSlice";

export function isVimeoLinkCorrect(rule: RuleObject, value: StoreValue, callback: (error?: string) => void) {
    const link = value as string;

    if (link.includes("player.vimeo.com/video")) {
        if(!new RegExp("[0-9]+$").test(link.substring(link.lastIndexOf('/')))) {
            callback('Vimeo link is incorrect');
        }
    } else {
        callback('Vimeo link is incorrect');
    }
}

export default function LocalizableVideo(props: LocalizableVideoProps) {
    const [currentValue, setCurrentValue] = useState(props.value || []);
    const defaultLanguage = useSelector(getGlobalLanguage);
    const [language, setLanguage] = useState(defaultLanguage);

    useEffect(() => {
        setCurrentValue(props.value || []);
    }, [props.value]);

    const isLinkCorrect = (link: string): boolean => {
        if (link.includes("player.vimeo.com/video")) {
            return new RegExp("[0-9]+$").test(link.substring(link.lastIndexOf('/')));
        }
        return false;
    }

    const formatLink = (currentLink: any) => currentLink !== null
        ? currentLink.replace('vimeo.com', 'player.vimeo.com/video')
        : "";

    const onCurrentValueChange = (value: LocalizableStringDto[]) => {
        setCurrentValue([...value]);
        props.onChange?.(value);
    }

    const currentDisplayValue = formatLink(getEntryValueForLanguageOrEmptyString(language, currentValue));

    return <div>
        <LocalizableInput
            value={currentValue}
            onChange={onCurrentValueChange}
            onLanguageChange={setLanguage}
        />
        {currentDisplayValue !== "" ?
            isLinkCorrect(currentDisplayValue) ?
                <div className={styles.iframeWrapper}>
                    <iframe
                        title={currentDisplayValue}
                        className={styles.iframeStyle}
                        src={currentDisplayValue}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen/>
                </div>
                : <Result
                    status="warning"
                    title="Error occured while loading video"
                />
            : <Result
                title="Provide video link in order to see its content"
            />
        }
    </div>
}

interface LocalizableVideoProps {
    value?: LocalizableStringDto[],
    onChange?: (value: LocalizableStringDto[]) => any; 
}