import { Card, Col, Row } from "antd";
import { useInjection } from "inversify-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { OrganisationCreateDto, OrganisationApi, UpdateVimeoTokenDto } from "../../../../api";
import { setCurrentBrandId, setOrganisations } from "../../../../redux/organisationsSlice";
import ApiService from "../../../../services/apiService";
import { RoutingPaths } from "../../../routingContainer";
import OrganisationForm from "../../components/organisationForm/organisationForm";

export default function OrganisationFormCreateContainer() {

    const apiService = useInjection(ApiService);
    const history = useHistory();
    const dispatch = useDispatch();

    const organisationApi = apiService.getApi(OrganisationApi);

    const onSubmit = async (value: OrganisationCreateDto & UpdateVimeoTokenDto) => {
        try {
            let response = await organisationApi.apiOrganisationPost(value);

            if (value.token) {
                await organisationApi.apiOrganisationVimeoTokenPost({ organisationId: response.data.id, token: value.token });
            }
            
            organisationApi.apiOrganisationGet().then(o => {
                dispatch(setOrganisations(o.data));
            });

            history.push(generatePath(RoutingPaths.OrganisationEdit, { id: response.data!.id!.toString() }))
        } catch (e) {
            return e.response.data;
        }
    };

    useEffect(() => {
        dispatch(setCurrentBrandId(0));
    }, [dispatch]);

    return <>
        <Row gutter={16}>
            <Col span={12} offset={6}>
                <Card title="Create new brand" bordered={true}>
                    <OrganisationForm onSubmit={onSubmit} saveButtonTitle={"Create"}/>
                </Card>
            </Col>
        </Row>
    </>;
}
