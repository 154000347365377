import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { AchievementCategoryApi, AchievementCategoryDto, AchievementCreateDto, AchievementUpdateDto } from "../../../api";
import ApiService from "../../../services/apiService";
import AchievementModalForm from "../achievementModalForm/achievementModalForm";

export default function AchievementModalFormAddContainer(props: AchievementModalFormAddContainerProps) {
    const apiService = useInjection(ApiService);
    const [achievementCategories, setAchievementCategories] = useState<AchievementCategoryDto[] | null>(null);

    useEffect(() => {
        apiService.getApi(AchievementCategoryApi).apiAchievementCategoryGet()
            .then(response => {
                setAchievementCategories(response.data);
            });
    }, [])

    return <AchievementModalForm
        isModalVisible={props.addFormVisibility}
        setModalVisibility={props.onFinish}
        onSubmit={props.onSubmit}
        modalTitle={props.title}
        saveButtonTitle="Submit"
        initialValues={props.achievementToEdit!}
        achievementCategories={achievementCategories}
    />
}

interface AchievementModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void;
    onSubmit: (values: AchievementCreateDto | AchievementUpdateDto) => Promise<any>;
    addFormVisibility: boolean;
    title: string;
    achievementToEdit: AchievementUpdateDto | null;
}