import { DndSectionItemType } from "./DndSectionItemType";
import { UnifiedDndObject } from "./UnifiedDndObject";


export class SectionItemDndObject implements UnifiedDndObject {
    typeDisclaimer: DndSectionItemType = DndSectionItemType.SectionItem;
    readonly sectionId: number;
    readonly sectionItemId: number;

    constructor(sectionId: number, sectionItemId: number) {
        this.sectionId = sectionId;
        this.sectionItemId = sectionItemId;
    }
    static create(sectionId: number, sectionItemId: number) {
        return JSON.stringify(new SectionItemDndObject(sectionId, sectionItemId));
    }
}
