import { Breadcrumb, BreadcrumbProps } from "antd";
import React, { ReactNode } from "react";
import styles from './cypherCmsBreadcrumb.module.scss';

export function CypherCmsBreadcrumbItem(props: CypherCmsBreadcrumbItemProps) {
    return <Breadcrumb.Item><span className={styles.elBreadcrumbItem}>{props.children}</span></Breadcrumb.Item>;
}

interface CypherCmsBreadcrumbItemProps {
    children?: ReactNode
}

export default function CypherCmsBreadcrumb(props: CypherCmsBreadcrumbProps) {
    return <Breadcrumb {...props} className={styles.elBreadcrumb} />
}

interface CypherCmsBreadcrumbProps extends BreadcrumbProps {
    children?: ReactNode
}