import {createSlice} from "@reduxjs/toolkit";
import { ApplicationUserDetailsDto } from "../api";

const initialState: {
    user: ApplicationUserDetailsDto | undefined
} = {
    user: undefined
};

const userSlice = createSlice({
   name: "userSlice",
   initialState,
   reducers: {
       setUser: (state, action) => {
           state.user = action.payload;
       }
   }
});

export const {setUser} = userSlice.actions;
export const getUser: (x: any) => ApplicationUserDetailsDto = (state: any) => state.user.user;
export default userSlice.reducer;
