import { ColumnsType } from "antd/lib/table";
import {AdminRole, BrandAdminRoleDto, CalendarDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import { nameof } from "../../helpers/nameofHelper";
import moment from "moment";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {useSelector} from "react-redux";
import { getAllBrandAdminRoles } from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";

export default function CalendarsList(props: CrudTableWrapperProps<CalendarDto>) {
    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<CalendarDto> = [
        {
            key: nameof<CalendarDto>("title"),
            render: (names) => <LocalizableReadonly value={names} />
        },
        {
            key: nameof<CalendarDto>("date"),
            render: (date: string) => {
                return <>
                    {moment(date).toLocaleString()}
                </>
            }
        }
    ];

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle={"+ Add new calendar entry"}
        />
    </>
}
