import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import { CalendarApi, CalendarCreateDto, CalendarDto, CalendarUpdateDto } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentBrandId, setCurrentBrandId } from "../../redux/organisationsSlice";
import CalendarsList from "./calendarsList";
import CalendarModalFormAddContainer from "./calendarModalFormAddContainer/calendarModalFormAddContainer";
import { Layout } from "antd";
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import { Content } from "antd/lib/layout/layout";
import styles from "./calendarsListContainer.module.scss";
import { RoutingPaths } from "../routingContainer";
import BrandSider from "../../components/brandSider/brandSider";
import moment from "moment";

export default function CalendarsListContainer() {
    const apiService = useInjection(ApiService);
    const [calendars, setCalendars] = useState<CalendarDto[] | null>();
    const [isAddFormVisible, setAddFormVisible] = useState(false);
    const [calendarModalFormContainerTitle, setCalendarModalFormContainerTitle] = useState<string>();
    const [calendarToEdit, setCalendarToEdit] = useState<CalendarUpdateDto | null>(null);
    const [idCalendarToEdit, setIdCalendarToEdit] = useState<number | null>(null);
    const brandIdFromState = useSelector(getCurrentBrandId);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshCalendarsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshCalendarsList = async () => {
        try {
            const calendars = await apiService.getApi(CalendarApi).apiCalendarGet();
            setCalendars(calendars.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = () => {
        setCalendarModalFormContainerTitle("Add new calendar entry");
        setCalendarToEdit(null);
        setIdCalendarToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = async (id: number) => {
        setCalendarModalFormContainerTitle(`Edit calendar entry with id: ${id}`);

        const response = await apiService.getApi(CalendarApi).apiCalendarIdGet(id);
        const calendarUpdateDto = response.data as CalendarUpdateDto;

        // Convert date to moment object for DatePicker
        calendarUpdateDto.date = moment(calendarUpdateDto.date) as any;

        setCalendarToEdit(calendarUpdateDto);
        setIdCalendarToEdit(id);

        setAddFormVisible(true);
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(CalendarApi).apiCalendarIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log("Error occured while deleting data"); //add some warning?
                } else {
                    refreshCalendarsList();
                }
            })
    }

    const onSubmit = async (values: CalendarCreateDto | CalendarUpdateDto) => {
        try {
            if (calendarToEdit === null) {
                await apiService.getApi(CalendarApi).apiCalendarPost(values);
            } else if (idCalendarToEdit !== null) {
                await apiService.getApi(CalendarApi).apiCalendarIdPut(idCalendarToEdit, values)
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false)
        refreshCalendarsList();
    }

    return <>
        <Layout style={{ backgroundColor: "white" }}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Manage calendar
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.CalendarsList} />
                    <Content className={styles.content}>
                        <CalendarsList
                            data={calendars || null}
                            onAddClicked={onAddClicked}
                            onEditClicked={onEditClicked}
                            onDeleteClicked={onDeleteClicked}
                        />
                        <CalendarModalFormAddContainer
                            onFinish={() => setAddFormVisible(false)}
                            onSubmit={onSubmit}
                            addFormVisibility={isAddFormVisible}
                            title={calendarModalFormContainerTitle || ""}
                            calendarToEdit={calendarToEdit}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
