import { MailOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { useSelector } from "react-redux";
import {
    AdminRole,
    BrandAdminRoleDto,
    LanguageDto,
    OrganisationUserDto,
    OrganisationUserRole,
    OrganisationUserUpdateDto
} from "../../../../api";
import AsyncButtonPopconfirm from "../../../../components/asyncButtonPopconfirm/asyncButtonPopconfirm";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import CrudFormSmall from "../../../../components/crudFormSmall/crudFormSmall";
import EntityTransfer from "../../../../components/entityTransfer/entityTransfer";
import { nameof } from "../../../../helpers/nameofHelper";
import { getAllBrandAdminRoles } from "../../../../redux/brandAdminRolesSlice";
import { getAllLanguages } from "../../../../redux/languageSlice";
import styles from "./organisationUserEditForm.module.scss";
import {brandAdminRolesForOrgUser, userHasRole} from "../../../../helpers/brandAdminRoleHelper";
import {getCurrentOrganisationUser} from "../../../../redux/organisationsSlice";

export default function OrganisationUserEditForm(props: OrganisationUserFormContainerProps) {
    const allLocales = useSelector(getAllLanguages);
    const allBrandAdminRoles = useSelector(getAllBrandAdminRoles);
    const orgUser = useSelector(getCurrentOrganisationUser)

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    let initialValue: OrganisationUserUpdateDto = {
        role: props?.item?.role || OrganisationUserRole.BrandAdmin,
        email: props?.item?.email,
        name: props?.item?.name,
        availableLocalesIds: props?.item?.availableLocalesIds,
        brandAdminRolesIds: props?.item?.brandAdminRolesIds
    };

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<OrganisationUserUpdateDto>("name"),
            label: "Change name",
            rules: [{ required: true, message: 'This field is required' }]
        },
        {
            name: nameof<OrganisationUserUpdateDto>("email"),
            label: "Change email",
            rules: [
                { required: true, message: 'This field is required' },
                { type: "email", message: "Email format is incorrect" }]
        },
        {
            name: nameof<OrganisationUserUpdateDto>("availableLocalesIds"),
            label: "Locales",
            rules: [{required: true, message: 'This field is required'}],
            children: 
                <EntityTransfer
                    dataSource={allLocales as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<LanguageDto>("locale")}
                />
        },
        {
            name: nameof<OrganisationUserUpdateDto>('brandAdminRolesIds'),
            label: "Roles",
            rules: [{required: true, message: 'This field is required'}],
            children:
                <EntityTransfer
                    dataSource={allBrandAdminRoles as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<BrandAdminRoleDto>('role')}
                />
        }
    ];

    const onSubmit = async (value: OrganisationUserUpdateDto) => {
        let toSend: OrganisationUserUpdateDto = {
            ...props.item,
            name: value.name,
            email: value.email,
            availableLocalesIds: value.availableLocalesIds,
            brandAdminRolesIds: value.brandAdminRolesIds
        };
        await props.onSubmit(toSend);
    };

    return <>
        {
            initialValue
            ?
                <CrudFormSmall
                    formItems={formItems}
                    onSubmit={onSubmit}
                    initialValues={initialValue}
                    extraActions={
                        <Space>
                            {props.item.isActivated && <AsyncButtonPopconfirm
                                title={"Are you sure to reset this password?"}
                                onConfirm={props.onResetPasswordClick}
                                onCancel={() => {}}
                                okText={"Ok"}
                                cancelText={"Cancel"}
                            >
                                Reset password
                            </AsyncButtonPopconfirm>}
                            {userHasRole(AdminRole.ContentLead, brandAdminRoles) && <AsyncButtonPopconfirm
                                title={"Are you sure to delete this item?"}
                                onConfirm={props.onDeleteClicked}
                                onCancel={() => {}}
                                okText={"Ok"}
                                cancelText={"Cancel"}
                            >
                                Delete user
                            </AsyncButtonPopconfirm>}
                        </Space>
                    }
                    extraUnderInputAction={
                        <div>
                            {!props.item.isActivated &&
                                <>
                                    <Text>User has not yet activated their account</Text><br/>
                                    <MailOutlined className={styles.resendIcon}/>&nbsp;
                                    User didn't receive the message?
                                    <Button
                                        type="text"
                                        className={styles.resendButton}
                                        onClick={props.onResendInvitationClick}
                                    >
                                        <b>Resend the invitation</b>
                                    </Button>
                                </>
                            }
                            
                        </div>
                    }
                    saveButtonTitle="Save changes" />
            : ''
        }
    </>;
}

export interface OrganisationUserFormContainerProps {
    item: OrganisationUserDto,
    onDeleteClicked: () => any,
    onResendInvitationClick: () => any,
    onResetPasswordClick: () => any,
    onSubmit: (value: OrganisationUserUpdateDto) => Promise<any>
}
