import { PictureOutlined, ReadOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row } from "antd";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { AdminRole, BrandAdminRoleDto, FileApi, OrganisationDetailsDto } from "../../../../api";
import ApiService from "../../../../services/apiService";
import styles from "./organisationView.module.scss";
import { generatePath, useHistory } from "react-router";
import { RoutingPaths } from "../../../routingContainer";
import { brandAdminRolesForOrgUser, userHasRole } from "../../../../helpers/brandAdminRoleHelper";
import { useSelector } from "react-redux";
import { getCurrentOrganisationUser } from "../../../../redux/organisationsSlice";
import { getAllBrandAdminRoles } from "../../../../redux/brandAdminRolesSlice";

export default function OrganisationView(props: OrganisationViewProps) {
    const [avatarFile, setAvatarFile] = useState<any>();
    const apiService = useInjection(ApiService);
    const history = useHistory();

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    useEffect(() => {
        if (avatarFile || !props.organisation.catalogFileId) {
            return;
        }
        apiService.getApi(FileApi).apiFileFileIdGet(
            props.organisation.catalogFileId,
            { responseType: 'blob' }
        ).then(response => {
            saveAvatarUrl(
                response.data, 
                props.organisation.catalogFile?.name || 'download-file'
            );
        });

        return () => { // componentWillUnmount - function component edition
            cleanAvatarUrl();
        }
    });

    const brandColorItem = (colorHex: any) => {
        return <>
            <div className={styles.brandColorItem}>
                <span
                    className={styles.brandColorItemCircle}
                    style={{ background: colorHex }}
                ></span>
                <span className={styles.brandColorItemText}>
                    {colorHex}
                </span>
            </div>
        </>
    }

    const saveAvatarUrl = (
        data: any,
        mime?: any,
        bom?: any
    ) =>  {
        let blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
        let blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

        let blobURL = (window.URL && window.URL.createObjectURL)
            ? window.URL.createObjectURL(blob) 
            : window.webkitURL.createObjectURL(blob);
        setAvatarFile(blobURL);
    }

    const cleanAvatarUrl = () => {
        if (avatarFile) {
            if (window.URL && window.URL.revokeObjectURL) {
                window.URL.revokeObjectURL(avatarFile) 
            } else {
                window.webkitURL.revokeObjectURL(avatarFile);
            }
            setAvatarFile(null);
        }
    }
    const onManageCoursesClick = () => {
        history.push(generatePath(RoutingPaths.CoursesList, { brandId: props.organisation.id! }));
    };

    const onManageTranslationsClick = () => {
        history.push(generatePath(RoutingPaths.TranslationList, { brandId: props.organisation.id! }));
    }

    const onManageEventsClick = () => {
        history.push(generatePath(RoutingPaths.EventsList, { brandId: props.organisation.id! }));
    }

    const onManageCalendarClick = () => {
        history.push(generatePath(RoutingPaths.CalendarsList, { brandId: props.organisation.id! }));
    }

    const onManageVideosClick = () => {
        history.push(generatePath(RoutingPaths.VideosList, { brandId: props.organisation.id! }));
    }

    const onManageTermsClick = () => {
        history.push(generatePath(RoutingPaths.BrandTermsList, { brandId: props.organisation.id! }));
    }

    const onManageLivestreamsClick = () => {
        history.push(generatePath(RoutingPaths.LivestreamsList, { brandId: props.organisation.id! }));
    }

    const onManageAchievementsClick = () => {
        history.push(generatePath(RoutingPaths.AchievementsList, { brandId: props.organisation.id! }));
    }

    const onViewReportsClick = () => {
        history.push(generatePath(RoutingPaths.ReportsList, { brandId: props.organisation.id! }));
    }

        return <>
            <div>
                {avatarFile 
                    ? <Avatar 
                        size={64}
                        src={avatarFile}/>
                    : <Avatar 
                        size={64}
                        icon={<PictureOutlined />}
                        className={styles.emptyAvatar}
                        />
                }
                <span className={styles.brandName}>
                    {props?.organisation?.name}
                </span>
            </div>
            <div className={styles.shortDescription}>
                {props?.organisation?.description}
            </div>
            <div className={styles.brandColors}>
                <div className={styles.brandColorsTitle}>
                    Brand colors
                </div>
                {brandColorItem(props?.organisation?.primaryColor)}
                {brandColorItem(props?.organisation?.secondaryColor)}
                {brandColorItem(props?.organisation?.tetriaryColor)}
        </div>

        <Row gutter={8}>
            {
                userHasRole(AdminRole.CalendarManager, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageCalendarClick}>Manage Calendar</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.VideoViewer, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageVideosClick}>Manage Videos</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.TermsEditor, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageTermsClick}>Manage Brand Terms</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.LivestreamViewer, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageLivestreamsClick}>Manage Livestreams</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.EventEditor, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageEventsClick}>Manage Events</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.TranslationEditor, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageTranslationsClick}>Manage Translations</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.ElearningEditor, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageCoursesClick}>Manage Elearning</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.AchievementsEditor, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onManageAchievementsClick}>Manage Achievements</Button>
                    </Col>
                )
            }
            {
                userHasRole(AdminRole.ReportsViewer, brandAdminRoles) && (
                    <Col span={8} style={{ marginBottom: 8 }}>
                        <Button block onClick={onViewReportsClick}>View Reports</Button>
                    </Col>
                )
            }
        </Row>
    </>
    }

interface OrganisationViewProps {
    organisation: OrganisationDetailsDto
}
