import classNames from 'classnames';
import styles from "./insertIndicatorLine.module.scss"

export default function InsertIndicatorLine(props: InsertIndicatorLineProps) {
  return (
    <div
      className={classNames(
        styles.container,
        props.isActive ? styles.active : styles.disabled
      )}
    >
    </div>
  );
}

interface InsertIndicatorLineProps {
  isActive: boolean
}
