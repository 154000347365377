import { SectionItemType, SectionTextItemDetailsDto, SectionVideoItemDetailsDto } from "../../../../api";
import { SectionItemShorthandType } from "../section/sectionItemShorthandType";
import SectionFileItem from "./sectionFileItem/sectionFileItem";
import SectionScormItem from "./sectionScormItem/sectionScormItem";
import SectionVideoItem from "./sectionVideoItem/sectionVideoItem";
import SectionTextItem from "./sectionTextItem/sectionTextItem";
import { ReactNode, useEffect, useState } from "react";

export default function SectionItemSwitch(props: SectionItemSwitchProps) {
  const [sectionItem, setSectionItem] = useState<ReactNode | null>(null);

  const renderSectionItem = (sectionItem: SectionItemShorthandType) => {
    switch (sectionItem.sectionItemType) {
      case SectionItemType.Text:
        return <SectionTextItem data={sectionItem as SectionTextItemDetailsDto}
          onSectionUpdate={props.onSectionUpdate} />;
      case SectionItemType.File:
        return <SectionFileItem data={sectionItem}
          onSectionUpdate={props.onSectionUpdate} />;
      case SectionItemType.Video:
        return <SectionVideoItem data={sectionItem as SectionVideoItemDetailsDto}
          onSectionUpdate={props.onSectionUpdate} />;
      case SectionItemType.Scorm:
        return <SectionScormItem data={sectionItem}
          onSectionUpdate={props.onSectionUpdate}/>;
      default:
        return <>Invalid section item.</>;
    }
  }

  useEffect(() => {
    setSectionItem(renderSectionItem(props.item));
  }, [props.item.sectionItemType]);

  return <>{sectionItem}</>;
}

export interface SectionItemSwitchProps {
  item: SectionItemShorthandType,
  onSectionUpdate: () => void
}
