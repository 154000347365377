import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import {useInjection} from "inversify-react";
import ApiService from "../../services/apiService";
import {useEffect, useState} from "react";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import EventLivestreamList from "./eventLivestreamList";
import EventLivestreamModalFormAddContainer
    from "./eventLivestreamModalFormAddContainer/eventLivestreamModalFormAddContainer";
import {
    EventLivestreamApi,
    EventLivestreamCreateDto,
    EventLivestreamDetailsDto,
    EventLivestreamDto,
    EventLivestreamUpdateDto,
    VimeoLiveEventDto
} from "../../api";

export default function EventLivestreamsListContainer(props: EventLivestreamProps) {
    const {brandId} = useParams<{ brandId: string }>();
    const {eventId} = useParams<{ eventId: string }>();
    const brandIdFromState = useSelector(getCurrentBrandId);
    const dispatch = useDispatch();
    const apiService = useInjection(ApiService);
    const [eventLivestreams, setEventLivestreams] = useState<EventLivestreamDto[] | null>();
    const [eventLivestreamsModalFormContainerTitle, setEventLivestreamsModalFormContainerTitle] = useState<string>();
    const [eventLivestreamToEdit, setEventLivestreamToEdit] = useState<EventLivestreamDetailsDto | null>(null);
    const [idEventLivestreamToEdit, setIdEventLivestreamToEdit] = useState<number | null>(null);
    const [isAddFormVisible, setAddFormVisible] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setCurrentBrandId(brandId));
    }, [brandId, dispatch]);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshEventlivestreamsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshEventlivestreamsList = async () => {
        try {
            const eventLivestreams = await apiService.getApi(EventLivestreamApi).apiEventLivestreamGet();
            setEventLivestreams(eventLivestreams.data.filter(el => el.eventId === +props.eventId));
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = () => {
        setEventLivestreamsModalFormContainerTitle("Add new event livestream");
        setEventLivestreamToEdit(null);
        setIdEventLivestreamToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = async (id: number) => {
        setEventLivestreamsModalFormContainerTitle(`Edit event livestream with id: ${id}`)

        await apiService.getApi(EventLivestreamApi).apiEventLivestreamIdGet(id)
            .then(response => {
                setEventLivestreamToEdit(response.data as EventLivestreamDetailsDto);
            });

        setIdEventLivestreamToEdit(id);
        setAddFormVisible(true);
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(EventLivestreamApi).apiEventLivestreamIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log(`Error deleting event livestream with id: ${id}`);
                } else {
                    refreshEventlivestreamsList();
                }
            })
    }

    const onSubmit = async (values: EventLivestreamCreateDto | EventLivestreamUpdateDto) => {
        try {
            let eventLivestream = values;
            eventLivestream.eventId = +eventId;

            if (eventLivestreamToEdit === null) {
                await apiService.getApi(EventLivestreamApi).apiEventLivestreamPost(eventLivestream);
            } else if (idEventLivestreamToEdit !== null) {
                await apiService.getApi(EventLivestreamApi).apiEventLivestreamIdPut(idEventLivestreamToEdit, eventLivestream);
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false);
        refreshEventlivestreamsList();
    }

    return <>
        <CypherCmsBreadcrumb>
            <CypherCmsBreadcrumbItem>
                Event Livestreams
            </CypherCmsBreadcrumbItem>
        </CypherCmsBreadcrumb>
        <br/>
        <EventLivestreamList
            data={eventLivestreams || null}
            onAddClicked={onAddClicked}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
        />
        <EventLivestreamModalFormAddContainer
            onFinish={() => setAddFormVisible(false)}
            onSubmit={onSubmit}
            addFormVisibility={isAddFormVisible}
            title={eventLivestreamsModalFormContainerTitle || ""}
            eventLivestreamToEdit={eventLivestreamToEdit}
            vimeoLiveEvents={props.vimeoLiveEvents}
        />
    </>
}

interface EventLivestreamProps {
    eventId: string,
    vimeoLiveEvents: VimeoLiveEventDto[] | null;
}