import { DeleteTwoTone } from "@ant-design/icons";
import { Button, Form, Input, message, Popconfirm, Result } from "antd";
import { useInjection } from "inversify-react";
import { ChangeEventHandler, useEffect, useState } from "react";
import { LocalizableStringDto, SectionScormItemApi, SectionScormItemDetailsDto, SectionScormItemUpdateDto } from "../../../../../api";
import LocalizableHelper from "../../../../../helpers/localizableHelper";
import ApiService from "../../../../../services/apiService";
import styles from "./sectionScormItem.module.scss";
import {getGlobalLanguage} from "../../../../../redux/languageSlice";
import { useSelector } from "react-redux";
import LocalizableInput from "../../../../../components/localizableInput/localizableInput";

export default function SectionScormItem(props: SectionScormItemProps) {
  const apiService = useInjection(ApiService);
  const globalLanguage = useSelector(getGlobalLanguage);
  const [scormSection, setScormSection] = useState<SectionScormItemDetailsDto>(props.data);
  const [currentLanguage, setCurrentLanguage] = useState(globalLanguage);
  const [currentLocalisedValue, setCurrentLocalisedValue] = useState<LocalizableStringDto[]>(scormSection.scorm!);
  const [slug, setSlug] = useState<string>(scormSection.slug!);
  const [isSaveVisible, setSaveVisible] = useState(false);

  useEffect(() => {
    setCurrentLanguage(globalLanguage);
  }, [globalLanguage]);

  useEffect(() => {
    setSlug(scormSection.slug!);
  }, [scormSection.slug]);

  const onDeleteSubmit = async () => {
    try {
      await apiService.getApi(SectionScormItemApi).apiSectionScormItemIdDelete(props.data.id!);
      message.success("Deleted.");
      props.onSectionUpdate();
    } catch (e: any) {
      console.log(e.response.data);
      message.error("Something went wrong.");
    }
  };

  const onSaveClick = async () => {
    try {
      var result = await apiService.getApi(SectionScormItemApi).apiSectionScormItemIdPut(props.data.id!, {
        sectionId: props.data.sectionId,
        sectionItemType: props.data.sectionItemType,
        order: props.data.order,
        scorm: currentLocalisedValue,
        slug: slug
      } as SectionScormItemUpdateDto);
      message.success("Saved.");
      setScormSection(result.data);
      setSaveVisible(false);
    } catch (e) {
      console.log(e.response.data);
      message.error("Something went wrong.");
    }
  }

  const onCurrentValueChange = (value: LocalizableStringDto[]) => {
    setCurrentLocalisedValue(value);
    if (!isSaveVisible) {
      setSaveVisible(true);
    }
  }
  
  const buttonsStyles = {
    opacity: isSaveVisible ? "1" : "0",
    visibility: isSaveVisible ? "visible" : "hidden"
  } as any;

  function isValidHttpUrl(string: string): boolean {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  function onLanguageChange (value: string): void {
    setCurrentLanguage(value);
  }

  const currentStringValue = LocalizableHelper.getEntryForLanguage(currentLanguage, currentLocalisedValue);

  const isLinkValid = isValidHttpUrl(currentStringValue?.value);

  return <div className={styles.container}>
    <div>
      <Form.Item label="Slug">
        <Input onChange={e => { setSlug(e.currentTarget.value) }} name="slug" value={slug} />
      </Form.Item>
      <Form.Item label="SCORM">
        <LocalizableInput onChange={onCurrentValueChange} value={currentLocalisedValue} onLanguageChange={onLanguageChange} />
      </Form.Item>
      <Input.Group compact>
        <Button type="primary" onClick={onSaveClick}>Save</Button>
      </Input.Group>
      <div className={styles.iframeWrapper}>
        <p style={{ marginTop: '1rem' }}>Preview: {!isLinkValid ? 'No valid link to a SCORM package provided' : ''}</p>
        {
          isLinkValid && (
            <iframe
              title={currentStringValue?.value}
              className={styles.iframeStyle}
              src={currentStringValue?.value}
              frameBorder="0" />
          )
        }
        
      </div>
    </div>
    <Popconfirm title="Are you sure?" onConfirm={onDeleteSubmit} onCancel={e => e?.stopPropagation()}
      className={styles.deleteButton}>
      <DeleteTwoTone className={styles.actionButton} twoToneColor="#ff4d4f" />
    </Popconfirm>
  </div>;
}

export interface SectionScormItemProps {
  data: SectionScormItemDetailsDto
  onSectionUpdate: () => void
}
