import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useInjection } from "inversify-react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionDetailsDto, SectionApi, SectionDto } from "../../../../api";
import { getSectionsDetails, setSectionDetails } from "../../../../redux/sectionsSlice";
import ApiService from "../../../../services/apiService";
import styles from "./section.module.scss";
import SectionItemList from "../../section-drag-and-drop/components/section-item-list/sectionItemList";
import { SectionItemShorthandType } from "./sectionItemShorthandType";

const concatenateSectionItems = (section: SectionDetailsDto): (SectionItemShorthandType)[] => {
  return section?.sectionFileItems
    ?.concat(section.sectionScormItems || [])
    .concat(section.sectionTextItems || [])
    .concat(section.sectionVideoItems || [])
    || [];
}

export default function Section(props: SectionProps) {

  const apiService = useInjection(ApiService);
  const dispatch = useDispatch();

  const sectionsDetails = useSelector(getSectionsDetails);

  const [sectionItems, setSectionItems] = useState<SectionItemShorthandType[]>([]);
  // todo - move it to some kind of redux or something

  useEffect(() => {
    reloadSection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    setSectionItems(concatenateSectionItems(sectionsDetails[props.data.id!]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsDetails]);

  const reloadSection = async () => {
    await apiService.getApi(SectionApi).apiSectionIdGet(props.data.id!).then(response => {
      dispatch(setSectionDetails(response.data))
    });
  }

  return <>
    {sectionsDetails[props.data.id!]
      ? <>
        {sectionItems?.length
          ? <SectionItemList
              onSectionUpdate={reloadSection}
              sectionId={+props.data.id!}
              items={concatenateSectionItems(sectionsDetails[props.data.id!])} 
            />
          : <SectionItemList
              onSectionUpdate={reloadSection}
              sectionId={+props.data.id!}
              items={[]}
            />
        }
      </>
      : <div className={styles.spinWrapper}>
          <Spin 
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="Loading..." />
        </div>
    }
  </>;
}

export interface SectionProps {
  data: SectionDto,
}
