import { DndSectionItemType } from "./DndSectionItemType";
import { UnifiedDndObject } from "./UnifiedDndObject";


export class SectionDndObject implements UnifiedDndObject {
    typeDisclaimer: DndSectionItemType = DndSectionItemType.Section;
    readonly sectionId: number;

    constructor(sectionId: number) {
        this.sectionId = sectionId;
    }

    static create(sectionId: number) {
        return JSON.stringify(new SectionDndObject(sectionId));
    }
}
