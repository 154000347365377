import { ColumnsType } from "antd/lib/table";
import {AdminRole, BrandAdminRoleDto, ProductDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import { nameof } from "../../helpers/nameofHelper";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {useSelector} from "react-redux";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";

const columns: ColumnsType<ProductDto> = [
    {
        key: nameof<ProductDto>("productId"),
    },
    // TODO: Fix name rendering - display for current language
    //       instead of dropdown + text
    // {
    //     key: nameof<ProductDto>("name")
    // },
    {
        key: nameof<ProductDto>("productCode")
    },
    {
        key: nameof<ProductDto>("workstationSlug")
    },
    {
        key: nameof<ProductDto>("isNew"),
        render: (text, record) => {
            return record.isNew ? "Yes" : "No";
        }
    },
    {
        key: nameof<ProductDto>("isAchievable"),
        render: (text, record) => {
            return record.isAchievable ? "Yes" : "No";
        }
    }
];

export default function ProductsList(props: CrudTableWrapperProps<ProductDto>) {
    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }
    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle={"+ New Product"}
        />
    </>
}
