import { Select } from "antd";
import {AchievementCategoryCreateDto, AchievementCategoryUpdateDto} from "../../../api";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import SingleFileUploader from "../../../components/fileUploader/singleFileUploader";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import {nameof} from "../../../helpers/nameofHelper";

const {Option} = Select;

export default function AchievementCategoryModalForm(props: AchievementCategoryModalProps) {
    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<AchievementCategoryCreateDto>('name'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Name has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCategoryCreateDto>('description'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Name has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCategoryCreateDto>("badgeFileId"),
            label: "Badge Image",
            children: <SingleFileUploader
                accept="image/jpeg,image/png"
                sizeLimitMb={2}
                defaultFile = {undefined}
                useOrganisationFileApi={true}
                isFilePublic={true}
            />,
            rules: [
                {
                    required: true,
                    message: "Badge Image has to be assigned"
                }
            ]
        }
    ]

    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface AchievementCategoryModalProps {
    initialValues?: AchievementCategoryCreateDto | AchievementCategoryUpdateDto;
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: AchievementCategoryCreateDto | AchievementCategoryUpdateDto) => Promise<any>;
    modalTitle: string;
    saveButtonTitle: string;
}