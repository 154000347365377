import React from "react";
import { TranslationCreateDto, TranslationUpdateDto } from "../../../api";
import TranslationModalForm from "../translationModalForm/translationModalForm";

export default function TranslationModalFormAddContainer(props: TranslationModalFormAddContainerProps) {
    return <TranslationModalForm
        isModalVisible={props.addFormVisibility}
        setModalVisibility={props.onFinish}
        onSubmit={props.onSubmit}
        modalTitle={props.title}
        saveButtonTitle="Submit"
        initialValues={props.translationToEdit!}
    />
}

interface TranslationModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void,
    onSubmit: (values: TranslationCreateDto | TranslationUpdateDto) => Promise<any>,
    addFormVisibility: boolean,
    title: string,
    translationToEdit: TranslationUpdateDto | null
}
