import { createSlice } from "@reduxjs/toolkit";
import { LanguageDto } from "../api";

const initialState: {
    globalLanguage: string,
    allLanguages: LanguageDto[] | null,
    filteredLanguages: LanguageDto[] | null 
} = {
    globalLanguage: "en-GB",
    allLanguages: null,
    filteredLanguages: null
};

const languageSlice = createSlice({
    name: "languageSlice",
    initialState,
    reducers: {
        setGlobalLanguage: (state, action) => {
            state.globalLanguage = action.payload;
        },
        setAllLanguages: (state, action) => {
            state.allLanguages = action.payload;
        },
        setFilteredLanguages: (state, action) => {
            state.filteredLanguages = action.payload;
        }
    }
});

export const { setGlobalLanguage, setAllLanguages, setFilteredLanguages } = languageSlice.actions;
export const getGlobalLanguage = (state: any) => state.language.globalLanguage;
export const getAllLanguages: (x: any) => LanguageDto[] = (state: any) => state.language.allLanguages;
export const getFilteredLanguages: (x: any) => LanguageDto[] = (state: any) => state.language.filteredLanguages || state.language.allLanguages;
export default languageSlice.reducer;
