import { Button, Skeleton, Space, Table } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import React, { ReactNode, useState } from "react";
import { humanize } from "../../helpers/stringHelpers";
import AsyncButtonPopconfirm from "../asyncButtonPopconfirm/asyncButtonPopconfirm";
import TableFuzzySearch from "./tableFuzzySearch";

export default function CrudTable<T extends { id?: number }>(props: CrudTableProps<T>) {
    const [displayedData, setDisplayedData] = useState<T[] | undefined>(undefined);

    if (!props.data) {
        return <Skeleton active={true} />;
    }

    const actionsColumn: ColumnType<T> = {
        key: "actions",
        title: "",
        fixed: "right",
        render: (value: any, record: T) => {
            return <>
                <Space>
                    {props.onEditClicked ? <Button
                        onClick={() => props.onEditClicked!(record.id!)}
                    >Edit</Button> : null}
                    {props.onDeleteClicked ? <AsyncButtonPopconfirm
                        danger
                        title={"Are you sure to delete this item?"}
                        onConfirm={async () => await props.onDeleteClicked!(record.id!)}
                        onCancel={() => { }}
                        okText={"Ok"}
                        cancelText={"Cancel"}
                    >
                        Delete
                    </AsyncButtonPopconfirm> : null}

                    {props.customRowActions?.(record)}
                </Space>
            </>

        }
    };

    props.columns.forEach(c => {
        const column = c as ColumnType<T>;
        if (!column.dataIndex) {
            column.dataIndex = column.key;
        }

        if (!column.title) {
            column.title = humanize(column.key as string);
        }
    });

    const columns = [...props.columns, actionsColumn];

    const onFuzzySearch = (selectedData: T[]) => {
        setDisplayedData(selectedData);
    }

    const onFuzzyClear = () => {
        setDisplayedData(undefined);
    }

    const tableTitle = props.fuzzySearch == undefined || props.fuzzySearch
        ? () => <><TableFuzzySearch
                    data={props.data}
                    columns={props.columns}
                    onSearch={onFuzzySearch}
                    onSearchClear={onFuzzyClear}
                />{props.topActions}</>
        : () => props.topActions;

    return <>
        <Table
            columns={columns}
            dataSource={displayedData !== undefined ? displayedData : props.data}
            rowKey="id"
            scroll={{ x: true }}
            title={tableTitle}
        ></Table>
        <Space>
            {props.onAddClicked ? <Button
                type="primary"
                onClick={props.onAddClicked}
            >
                {props.addButtonTitle || "+ Add"}
            </Button> : null}
            {props.customActions?.()}
        </Space>
    </>;
}

interface CrudTableProps<T> {
    data: T[] | null,
    onAddClicked?: React.MouseEventHandler<HTMLElement>,
    onEditClicked?: (id: number) => any,
    onDeleteClicked?: (id: number) => any,
    columns: ColumnsType<T>,
    customRowActions?: (record: T) => ReactNode,
    customActions?: () => ReactNode,
    addButtonTitle?: string,
    fuzzySearch?: boolean,
    topActions?: JSX.Element
}

export interface CrudTableWrapperProps<T> {
    data: T[] | null,
    onAddClicked?: () => any,
    onEditClicked?: (id: number) => any,
    onDeleteClicked?: (id: number) => any
}