import {Button, Table} from "antd";

export default function ReportsList(props: ReportsListProps) {
    const data = [
        {
            key: '1',
            title: 'Missing translations'
        }
    ];

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        }, {
            render: () => <Button
                type="primary"
                onClick={props.onViewClick}
            >View report</Button>
        }
    ]

    return <>
        <Table
            dataSource={data}
            columns={columns}
        />
    </>
}

interface ReportsListProps {
    onViewClick: () => any,
}
