import { PlusCircleOutlined } from "@ant-design/icons";
import { Avatar, Input, Menu, Skeleton } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import styles from './brands-menu-sider.module.scss';
import { CrudSiderItemProps } from "./CrudSiderItemProps";
import { CrudSiderProps } from "./CrudSiderProps";
const { Search } = Input; // required to show search icon

function SiderSkeleton() {
    return <>
    <Skeleton.Input size="default" className={styles.searchInput}></Skeleton.Input>
    <Skeleton paragraph={{ rows: 5 }}  className={styles.searchInput} title={false}/>
    </>
}

export default function BrandsMenuSider(props: CrudSiderProps) {
    const [currentFilter, setCurrentFilter] = useState<string>('');
    const [filteredItems, setFilteredItems] = useState<CrudSiderItemProps[]>();
    const [indicateSearchActive, setIndicateSearchActive] = useState<boolean>(false);
    
    let onSearch = (value: string) => {
        let lowercasedValue = value.toLowerCase();
        updateList(props.items!, lowercasedValue);
        setCurrentFilter(lowercasedValue);
        setIndicateSearchActive(!!value);
    };

    useEffect(() => {
        if (!props.items) {
            return;
        }

        updateList(props.items, currentFilter);
    }, [props.items, currentFilter]);

    const updateList = (allItems: CrudSiderItemProps[], filterString: string) => {
        setFilteredItems(
            allItems.filter(x => x.title.toLocaleLowerCase().includes(filterString))
        );
    }

    const handleClick = (e: { item: any, key: any, keyPath: any, domEvent: any }) => {
        if (e.key === props.addButtonKey) {
            props.onAddClicked();
        } else {
            props.onEditClicked(e.key);
        }
    }

    return (<Sider className={styles.container} width={230}>
        {props.items ? <>
        <Search
            placeholder="Search"
            onSearch={onSearch}
            allowClear
            className={`
                ${styles.searchInput}
                ${indicateSearchActive ? styles.searchInputActive : ''}
            `}
        />
        <Menu
            onClick={handleClick}
            selectedKeys={[props.currentlyActiveItemKey]}
            mode="inline"
            className={styles.menu}
        >
            {
            filteredItems?
                filteredItems.map((item) =>
                    <Menu.Item
                        key={item.key}
                        icon={<Avatar
                            size={15}
                            style={{
                                color: 'white',
                                backgroundColor: item.iconLetterColor
                        }}>{item.iconLetter.toLocaleUpperCase()}</Avatar>}
                    >
                        {item.title}
                    </Menu.Item>
                )
                : ''
            }
            <Menu.Item style={{ display: "none" }}
                key={props.addButtonKey}
                icon={<PlusCircleOutlined />}
            >
                <strong>{props.addButtonText}</strong>
            </Menu.Item>
        </Menu>
        </>
        : <SiderSkeleton />}
    </Sider>);
}
