import { EllipsisOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Card, Dropdown, Menu, Popconfirm } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import {AdminRole, BrandAdminRoleDto, CourseDto} from "../../../../api";
import { RoutingPaths } from "../../../routingContainer";
import styles from "./coursesListItem.module.scss";
import {brandAdminRolesForOrgUser, userHasRole} from "../../../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../../../redux/organisationsSlice";
import {useSelector} from "react-redux";

export default function CoursesListItem(props: CoursesListItemProps) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const history = useHistory();

    const handleDelete = async () => {
        const error = await props.handleDelete();

        if (error) {
            console.error(error);
        }
    };

    const goToCourse = () => {
        history.push(generatePath(RoutingPaths.CourseEdit,
            { brandId: props.brandId, courseId: props.model.id! }))
    };

    // Local dropdown
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const deleteClick = (e: React.MouseEvent | undefined) => {
        e?.stopPropagation();
        handleDelete();
    }

    const editClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.startEdit();
        setDropdownVisible(false);
    };

    const dropdownActions = (
        <Menu>
            <Menu.Item>
                <span onClick={editClick}>
                    Edit
                </span>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm title="Are you sure?" onConfirm={deleteClick} onCancel={e => e?.stopPropagation()}>
                    {userHasRole(AdminRole.ContentLead, brandAdminRoles) && <span className="ant-typography ant-typography-danger" onClick={e => e.stopPropagation()}>
                        Delete
                    </span>}
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return <Card
        hoverable
        onClick={goToCourse}
        cover={<div style={{ backgroundColor: props.model.color!, paddingTop: "calc(100% - 62px)" }} ></div>}
        >
            <Title level={4} className={styles.cardTitle}>
                <div>
                    <span className={styles.cardText} >
                        {/* TODO: Fix name for current language */}
                        {props.model.name?.find(e => e)?.value}
                    </span>
                    {props.model.isVisible ? null : <EyeInvisibleOutlined />}
                </div>

                <Dropdown
                    overlay={dropdownActions}
                    trigger={[ "click" ]}
                    placement="bottomRight"
                    visible={dropdownVisible}
                    onVisibleChange={e => setDropdownVisible(e)}
                    >
                    <EllipsisOutlined className={styles.cardOptions} onClick={e => e.stopPropagation()} />
                </Dropdown>
            </Title>
    </Card>;
}

interface CoursesListItemProps {
    model: CourseDto,
    startEdit: () => void,
    handleVisibilityChange: (isVisible: boolean) => Promise<any>,
    handleDelete: () => Promise<any>,
    brandId: number
}
