import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import { SectionDto} from "../api";
import { SectionItemShorthandType } from "../pages/modules/components/section/sectionItemShorthandType";

export type SectionItemsDictionary = { [sectionId: string]: SectionItemShorthandType[] };

const initialState: { 
    orderedSections: SectionDto[],
    orderedSectionItems: SectionItemsDictionary | null,
    currentModuleId: number | null
} = {
    orderedSections: [],
    orderedSectionItems: null,
    currentModuleId: null
};

const sectionsDndSlice = createSlice({
    name: 'sectionsDndSlice',
    initialState,
    reducers: {
        setOrderedSections: (state, action) => {
            state.orderedSections = action.payload;
        },
        setOrderedSectionItems: (
            state, 
            action: PayloadAction<{
                sectionId: number,
                items: SectionItemShorthandType[]
            }>
        ) => {
            let detailsDict = state.orderedSectionItems || {};
            detailsDict[+action.payload.sectionId!] = action.payload.items;

            state.orderedSectionItems = detailsDict;
        },
        setCurrentModuleId: (state, action) => {
            state.currentModuleId = action.payload
        }
    }
});

// these methods only modify the state
export const {
    setOrderedSections,
    setOrderedSectionItems,
    setCurrentModuleId
} = sectionsDndSlice.actions;

// these methods only read the state
export const getOrderedSections: (x: any) => SectionDto[] = (state: any) => state.sectionsDnd.orderedSections;
export const getOrderedSectionItems = (state: any) => state.sectionsDnd.orderedSectionItems as SectionItemsDictionary;
export const getCurrentModuleId: (x: any) => number = (state: any) => state.sectionsDnd.currentModuleId;

export default sectionsDndSlice.reducer;
