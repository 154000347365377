import { Input } from "antd";
import { useEffect, useState } from "react";
import { LocalizableStringDto } from "../../api";
import LocalizableHelper from "../../helpers/localizableHelper";
import LanguageSelector, { Language } from "../languageSelector/languageSelector";
import {useSelector} from "react-redux";
import {getGlobalLanguage} from "../../redux/languageSlice";

export function getEntryValueForLanguageOrEmptyString(language: Language, value: LocalizableStringDto[] | undefined): string {
    return LocalizableHelper.getEntryForLanguage(language, value || [])?.value || "";
}

export default function LocalizableInput(props: LocalizableInputProps) {
    const defaultLanguage = useSelector(getGlobalLanguage);
    const [language, setLanguage] = useState(defaultLanguage);
    const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
    const [currentValue, setCurrentValue] = useState(props.value || []);
    const [currentTextValue, setCurrentTextValue] = useState(
        getEntryValueForLanguageOrEmptyString(currentLanguage, currentValue));

    useEffect(() => {
        setCurrentValue(JSON.parse(JSON.stringify(props.value || [])));
    }, [props.value]);

    useEffect(() => {
        setCurrentTextValue(getEntryValueForLanguageOrEmptyString(currentLanguage, currentValue))
    }, [currentValue, currentLanguage]);

    useEffect(() => {
        setCurrentLanguage(defaultLanguage);
    }, [defaultLanguage])

    const onLanguageSelected = (value: Language) => {
        setCurrentLanguage(value);
        props.onLanguageChange?.(value);
        setCurrentTextValue(getEntryValueForLanguageOrEmptyString(value, currentValue));
    };

    const onInputChange = (value: string) => {
        const localizableString = LocalizableHelper.getEntryForLanguage(currentLanguage, currentValue);
        setCurrentTextValue(value);

        if (!localizableString) {
            currentValue.push({
                locale: currentLanguage,
                value: value
            });
        } else {
            localizableString.value = value;
        }

        setCurrentValue(currentValue);
        props.onChange?.(currentValue);
    }

    const languageSelector = <LanguageSelector onSelect={onLanguageSelected} />

    if (props.customRenderer) {
        return props.customRenderer(languageSelector, onInputChange, currentTextValue);
    }
    return <Input addonBefore={languageSelector} onChange={event => onInputChange(event.target.value)} value={currentTextValue} />;
}

interface LocalizableInputProps {
    value?: LocalizableStringDto[],
    onChange?: (value: LocalizableStringDto[]) => void,
    onLanguageChange?: (value: Language) => void,
    customRenderer?: (languageSelector: JSX.Element, onInputChange: (value: string) => any, value: string) => JSX.Element
}
