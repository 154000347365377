import { useInjection } from "inversify-react";
import React, { useState } from "react";
import { CourseApi, CourseCreateDto, CourseDto } from "../../../../api";
import ApiService from "../../../../services/apiService";
import CoursesListItem from "../coursesListItem/coursesListItem";
import CourseModalForm from "../courseModalForm/courseModalForm";

export default function CoursesListItemContainer(props: CoursesListItemContainerProps) {
    const apiService = useInjection(ApiService);
    const [isModalVisible, setModalVisibility] = useState(false);

    const startEdit = () => {
        setModalVisibility(true);
    };

    const onSubmit = async (course: CourseCreateDto) => {
        try {
            await apiService.getApi(CourseApi).apiCourseIdPut(props.model.id!, course);
            props.onCourseItemUpdated();
            setModalVisibility(false);
        } catch (e) {
            return e.response.data;
        }
    }

    const handleDelete = async () => {
        await apiService.getApi(CourseApi).apiCourseIdDelete(props.model.id!);
        props.onCourseItemUpdated();
    };

    const handleVisibilityChange = async (isVisible: boolean) => {
        try {
            await apiService.getApi(CourseApi).apiCourseIdPatch(props.model.id!, { isVisible });
            props.onCourseItemUpdated();
        } catch (e) {
            // TODO: Add alert?
            return e.response.data;
        }
    };

    return <>
        <CoursesListItem
            handleDelete={handleDelete}
            handleVisibilityChange={handleVisibilityChange}
            model={props.model}
            startEdit={startEdit}
            brandId={props.brandId}
        />
        <CourseModalForm
            isModalVisible={isModalVisible}
            setModalVisibility={setModalVisibility}
            initialValues={props.model}
            onSubmit={onSubmit}
            modalTitle="Edit course"
            saveButtonTitle="Submit"
        />
    </>;
}

interface CoursesListItemContainerProps {
    model: CourseDto,
    onCourseItemUpdated: () => void,
    brandId: number
}
