import { message, Skeleton } from "antd";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ModuleApi, ModuleDetailsDto, SectionApi, SectionCreateDto, SectionUpdateDto } from "../../api";
import { setCurrentBrandId } from "../../redux/organisationsSlice";
import { setCurrentModuleId } from "../../redux/sectionsDndSlice";
import ApiService from "../../services/apiService";
import SectionModalFormAddContainer from "./components/sectionModalFormAddContainer/sectionModalFormAddContainer";
import ModuleEdit from "./moduleEdit";

export default function ModuleEditContainer() {
  const dispatch = useDispatch();
  const apiService = useInjection(ApiService);

  const { moduleId } = useParams<{ moduleId: string }>();
  const { brandId } = useParams<{ brandId: string }>();

  const [module, setModule] = useState<ModuleDetailsDto>();
  const [sectionToEdit, setSectionToEdit] = useState<SectionUpdateDto | null>(null);
  const [idSectionToEdit, setIdSectionToEdit] = useState<number | null>(null);
  const [isAddFormVisible, setIsAddFormVisible] = useState<boolean>(false);
  const [sectionTranslationModalFormContainerTitle, setSectionModalFormContainerTitle] = useState<string>();

  const reloadAllSections = async () => {
    await apiService.getApi(ModuleApi).apiModuleIdGet(+moduleId).then(o => {
      if (o.data) {
        setModule(o.data);
      }
    });
  }

  useEffect(() => {
    dispatch(setCurrentBrandId(brandId));
    dispatch(setCurrentModuleId(moduleId));
    reloadAllSections();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // keep this empty braces to prevent flickering

  const onAddClicked = async () => {
    setSectionModalFormContainerTitle("Add new section");
    setSectionToEdit(null);
    setIdSectionToEdit(null);
    setIsAddFormVisible(true);
  };

  const onEditClicked = async (id: number) => {
    setSectionModalFormContainerTitle(`Edit translation with id: ${id}`)

    await apiService.getApi(SectionApi).apiSectionIdGet(id)
        .then(response => {
          console.log(response.data as SectionUpdateDto);
          setSectionToEdit(response.data as SectionUpdateDto);
        });

    setIdSectionToEdit(id);
    setIsAddFormVisible(true);
  }

  const onSubmit = async (values: SectionUpdateDto | SectionCreateDto) => {
    try {
      if (sectionToEdit === null) {
        const createObject: SectionCreateDto = {
          moduleId: +moduleId,
          title: values.title
        }

        await apiService.getApi(SectionApi).apiSectionPost(createObject).then(async (x) => {
          message.success(`New section has been added`);
        });
      } else if (idSectionToEdit !== null) {
        let preparedSectionToEdit = sectionToEdit;
        if (preparedSectionToEdit !== null) {
          preparedSectionToEdit.title = values.title;
          await apiService.getApi(SectionApi).apiSectionIdPut(idSectionToEdit, preparedSectionToEdit);
          message.success(`Section has been edited`);
        }
      }
    } catch (e) {
      return e.response.data;
    }

    setIsAddFormVisible(false)
    reloadAllSections()
  }

  const handleVisibilityChange = async (id: number, isVisible: boolean) => {
      apiService.getApi(SectionApi).apiSectionIdPatch(id!,
        {
          moduleId: +moduleId
        }
      ).then(async () => {

        await reloadAllSections();
      })
  };

  const onDeleteClicked = async (id: number) => {
    await apiService.getApi(SectionApi).apiSectionIdDelete(id).then(async () => {
      await reloadAllSections();
      message.info(`Section has been deleted`);
    })
  }

  return <>{
    module?.sections
      ? <>
        <ModuleEdit
              data={module.sections}
              onAddClicked={onAddClicked}
              onDeleteClicked={onDeleteClicked}
              onEditClicked={onEditClicked}
              onVisbilityClicked={handleVisibilityChange}
          />
        <SectionModalFormAddContainer
            onFinish={() => setIsAddFormVisible(false)}
            title={sectionTranslationModalFormContainerTitle || ""}
            editFormVisibility={isAddFormVisible}
            sectionToEdit={sectionToEdit}
            onSubmit={onSubmit}
        />
      </>
      : <Skeleton title={false} paragraph={{ rows: 5 }} />
  }</>;
}
