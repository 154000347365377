import React from "react";
import {SectionCreateDto, SectionUpdateDto } from "../../../../api";
import SectionModalForm from "../sectionModalForm/sectionModalForm";

export default function SectionModalFormAddContainer(props: SectionModalFormAddContainerProps) {
    return <>
        <SectionModalForm
            isModalVisible={props.editFormVisibility}
            setModalVisibility={props.onFinish}
            onSubmit={props.onSubmit}
            modalTitle={props.title}
            saveButtonTitle="Submit"
            initialValues={props.sectionToEdit!}
        />
    </>
}

interface SectionModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void,
    onSubmit: (values: SectionCreateDto | SectionUpdateDto) => Promise<any>,
    editFormVisibility: boolean,
    title: string,
    sectionToEdit: SectionUpdateDto | null
}