export const orderableSortDesc = (a: any, b: any): number => {
    if (a.order >= 0 && b.order >= 0) {
        return a.order > b.order 
            ? -1
            : 1;
    } else if (a.order >= 0) {
        return 1;
    } else if (b.order >= 0) {
        return -1;
    } else {
        return a.id > b.id
            ? -1
            : 1;
    }
}

export const orderableSortAsc = (a: any, b: any): number => {
    if (a.order >= 0 && b.order >= 0) {
        return a.order > b.order 
            ? 1
            : -1;
    } else if (a.order >= 0) {
        return -1;
    } else if (b.order >= 0) {
        return 1;
    } else {
        return a.id > b.id
            ? 1
            : -1;
    }
}
