import { Layout, Skeleton } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CourseApi, CourseDto } from "../../api";
import BrandSider from "../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import { getCurrentBrandId, setCurrentBrandId } from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import { RoutingPaths } from "../routingContainer";
import CoursesList from "./components/coursesList/coursesList";
import styles from "./coursesListContainer.module.scss";

export default function CoursesListContainer() {
    const apiService = useInjection(ApiService);
    const [courses, setCourses] = useState<CourseDto[] | undefined>(undefined);
    const brandIdFromState = useSelector(getCurrentBrandId);

    const refreshCoursesList = async () => {
        try {
            const courses = await apiService.getApi(CourseApi).apiCourseGet();
            setCourses(courses.data);
        } catch (e) {
            // TODO: Add alert?
            console.log(e.response.data);
        }
    }

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }

        refreshCoursesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    return <Layout>
        <Content>
            <CypherCmsBreadcrumb>
                <CypherCmsBreadcrumbItem>
                    Your courses
                </CypherCmsBreadcrumbItem>
            </CypherCmsBreadcrumb>
            <Layout>
                <BrandSider selectedItem={RoutingPaths.CoursesList} />
                <Content className={styles.content}>
                    {courses ?
                    <CoursesList courses={courses} refreshCoursesList={refreshCoursesList} />
                    : <Skeleton />}
                </Content>
            </Layout>
        </Content>
    </Layout>;
}
