import { useInjection } from "inversify-react";
import React from "react";
import { AchievementApi, ExternalAuthorizationApi, OrganisationApi, OrganisationApiFp, OrganisationUserApi } from "../../../api";
import ApiService from "../../../services/apiService";

export default function KenticoLogin () {
  const apiService = useInjection(ApiService);
  
  const [userResponse, setUserResponse] = React.useState<any>();

  // const attemptLogin = () => {
  //     organisationId: 1
  //   })
  //   .then(response => {
  //     setUserResponse(response)
  //   });
  // }
  const attemptSetOrgUserId = () => {
    apiService.getApi(OrganisationApi).apiOrganisationCurrentPost({ id: 1 }).then(response => {
      console.log(response);
    });
  }

  const attemptGetAchievements = () => {
    apiService.getApi(OrganisationUserApi).apiOrganisationUserAchievementsGet().then(response => {
      console.log(response);
    });
  }

  return (
    <div>
      <h1>Kentico Login</h1>
      {/*<button onClick={attemptLogin}>Attempt Login</button>*/}
      <button onClick={attemptSetOrgUserId}>Attempt Org Id</button>
      <button onClick={attemptGetAchievements}>Attempt Get Achievements</button>
    </div>
  )
}