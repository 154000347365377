import './App.scss';
import Layout from 'antd/lib/layout/layout';
import styles from './App.module.scss';
import RoutingContainer from './pages/routingContainer';
import { Container } from 'inversify';
import * as inversifyReact from 'inversify-react';
import ApiService from './services/apiService';
import { Provider } from 'react-redux'
import { store } from './redux/store';
import AlertContainer from './components/alertContainer/alertContainer';
import { AuthProvider } from 'oidc-react';
import CypherCmsHeaderContainer from './components/cypherCmsHeader/cypherCmsHeaderContainer';
import SectionDndService from './pages/modules/section-drag-and-drop/sectionDndService';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';

function GetContainer(): Container {
  const container = new Container();
  container.bind(ApiService).toSelf();
  container.bind(SectionDndService).toSelf();
  return container;
}

function App() {
  const [userId, setUserId] = useState();

  return (
    <AuthProvider
      authority={process.env.REACT_APP_API_URL}
      clientId="Apptimise.CypherCms.Web"
      redirectUri={process.env.REACT_APP_URL}
      autoSignIn={false}
      automaticSilentRenew={true}
      scope="openid Apptimise.CypherCms.WebAPI profile"
      onSignIn={u => window.history.replaceState({}, document.title, window.location.pathname)}>
      <Provider store={store}>
        <inversifyReact.Provider container={GetContainer()}>
          <AlertContainer />
          <BrowserRouter>
            <Layout className={styles.container}>
              <CypherCmsHeaderContainer />
              <Layout>
                <RoutingContainer />
              </Layout>
            </Layout>
          </BrowserRouter>
        </inversifyReact.Provider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
