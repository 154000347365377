import {ProductAvailabilityLanguageDto, ProductDetailsDto, ProductUpdateDto} from "../../../api";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";
import CrudForm from "../../../components/crudForm/crudForm";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import {nameof} from "../../../helpers/nameofHelper";
import {useSelector} from "react-redux";
import {getAllLanguages} from "../../../redux/languageSlice";
import EntityTransfer from "../../../components/entityTransfer/entityTransfer";

const layout = {
    labelCol: {span: 2},
    wrapperCol: {span: 5},
};

const tailLayout = {
    wrapperCol: {offset: 1},
};

export default function ProductForm(props: ProductFormProps) {
    const allLocales = useSelector(getAllLanguages);

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<ProductDetailsDto>('workstationSlug'),
        },
        {
            name: nameof<ProductDetailsDto>("isNew"),
            children: <AsyncSwitch/>
        },
        {
            name: nameof<ProductDetailsDto>("isAchievable"),
            children: <AsyncSwitch/>
        },
        {
            name: nameof<ProductDetailsDto>("productAvailabilityLanguagesIds"),
            label: "Available to:",
            children:
                <EntityTransfer
                    dataSource={allLocales as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<ProductAvailabilityLanguageDto>("locale")}
                />
        }
    ];

    const initValues = {
        "workstationSlug": props.productToEdit!.workstationSlug,
        "isNew": props.productToEdit!.isNew,
        "isAchievable": props.productToEdit!.isAchievable,
        "productAvailabilityLanguagesIds": props.productToEdit!.productAvailabilityLanguagesIds
    }

    return <CrudForm
        formItems={formItems}
        formProps={layout}
        tailProps={tailLayout}
        saveButtonTitle={"Submit"}
        initialValues={initValues}
        onSubmit={props.onSubmit}
    />;
}

interface ProductFormProps {
    onSubmit: (name: ProductDetailsDto) => Promise<any>,
    productToEdit: ProductUpdateDto | null
}