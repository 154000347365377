import { ColumnsType } from "antd/lib/table";
import {AdminRole, BrandAdminRoleDto, VimeoLiveEventDto, VimeoVideoDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import { nameof } from "../../helpers/nameofHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {useSelector} from "react-redux";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";

const columns: ColumnsType<VimeoVideoDto> = [{
    key: nameof<VimeoLiveEventDto>("title")
}, {
    key: nameof<VimeoLiveEventDto>("uri")
}, {
    key: nameof<VimeoLiveEventDto>("updatedAt")
}];

export default function LivestreamsList(props: CrudTableWrapperProps<VimeoLiveEventDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }
    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
        />
    </>
}