import _ from 'lodash'
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganisationDetailsDto, OrganisationDto} from "../api";

export type OrganisationDetailsDictionary = { [id: string]: OrganisationDetailsDto };

const initialState: { 
    organisations: OrganisationDto[] | null,
    organisationsDetails: OrganisationDetailsDictionary | null,
    currentBrandId: number
} = {
    organisations: null,
    organisationsDetails: null,
    currentBrandId: 0
};

const organisationsSlice = createSlice({
    name: 'organisationsSlice',
    initialState,
    reducers: {
        setOrganisations: (state, action) => {
            state.organisations = action.payload;
        },
        setOrganisationDetails: (
            state, 
            action: PayloadAction<OrganisationDetailsDto>
        ) => {
            let organisationsDetailsDict = state.organisationsDetails || {};
            organisationsDetailsDict[+action.payload.id!] = action.payload;

            state.organisationsDetails = organisationsDetailsDict;
        },
        setCurrentBrandId: (state, action) => {
            state.currentBrandId = action.payload;
        }
    }
});

// these methods only modify the state
export const {
    setOrganisations,
    setOrganisationDetails,
    setCurrentBrandId,
} = organisationsSlice.actions;

// these methods only read the state
export const getOrganisations: (x: any) => OrganisationDto[] = (state: any) => state.organisations.organisations;
export const getOrganisationsDetails = (state: any) => state.organisations.organisationsDetails as OrganisationDetailsDictionary;
export const getCurrentBrandId = (state: any) => state.organisations.currentBrandId;
export const getCurrentBrandName = (state: any) => {
    if (state.organisations.organisationsDetails) {
        return state.organisations.organisationsDetails[state.organisations.currentBrandId]?.name ?? "";
    } else {
        return "";
    }
}
export const getCurrentOrganisationUser = (state: any) => {
    const currentBrandId = getCurrentBrandId(state)
    const orgUsers = _.get(state, 'user.user.organisationUsers', [])
    const orgUser = orgUsers.find((x: any) => x.organisationId === Number(currentBrandId))
    return orgUser
}
export default organisationsSlice.reducer;
