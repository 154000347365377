import {DatePicker, Select} from "antd";
import moment from "moment";
import {EventDetailsDto, EventUpdateDto, ParentEventDto} from "../../../api";
import CrudForm from "../../../components/crudForm/crudForm";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import LocalizableReadonly from "../../../components/localizableReadonly/localizableReadonly";
import {nameof} from "../../../helpers/nameofHelper";

const layout = {
    labelCol: {xxl:{span:2},xl:{span:3},lg:{span:4},md:{span:6}},
    wrapperCol: {span: 12},
};

const tailLayout = {
    wrapperCol: {offset: 1},
};

const {Option} = Select;

export default function EventForm(props: EventFormProps) {
    const formItems: CrudFormItemProps[] = [
        {
            label: "Name",
            name: nameof<EventDetailsDto>('name'),
            children: <LocalizableInput/>
        }, {
            label: "Start date",
            name: nameof<EventDetailsDto>('startDate'),
            children: <DatePicker/>
        }, {
            label: "End date",
            name: nameof<EventDetailsDto>('endDate'),
            children: <DatePicker/>
        },
        {
            name: nameof<EventDetailsDto>('parentEventId'),
            label: 'Parent event',
            children: <Select loading={!props.parentEvents}>
                {props.parentEvents?.map(pe =>
                    <Option key={pe.id!} value={pe.id!}>
                        <LocalizableReadonly userInputDisabled={true} value={pe.name || undefined} />
                    </Option>
                )}
            </Select>,
            rules: [
                {
                    required: true,
                    message: "Parent event has to be assigned"
                }
            ]
        }, {
            name: nameof<EventDetailsDto>('pixelStreamUrl')
        }, {
            name: nameof<EventDetailsDto>('mpVideo'),
            label: 'MP Video',
            children: <LocalizableInput/>
        }
    ];

    const initValues = {
        "name": props.eventToEdit!.name,
        "startDate": moment(props.eventToEdit!.startDate),
        "endDate": moment(props.eventToEdit!.endDate),
        "parentEventId": props.eventToEdit?.parentEventId,
        "pixelStreamUrl": props.eventToEdit?.pixelStreamUrl,
        "mpVideo": props.eventToEdit?.mpVideo
    }

    return <CrudForm
        formItems={formItems}
        formProps={layout}
        tailProps={tailLayout}
        saveButtonTitle={"Submit"}
        initialValues={initValues}
        onSubmit={props.onSubmit}
    />;
}

interface EventFormProps {
    onSubmit: (name: EventDetailsDto) => Promise<any>,
    eventToEdit: EventUpdateDto | null,
    parentEvents: ParentEventDto[] | null
}
