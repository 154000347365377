/**
 * This method returns new array with item from **startIndex** moved to **endIndex** and all other items shifted towards **startIndex**.
 * 
 *  This is *NOT* swapping indexes. It is used to reorded drag'n'drop lists.
 * @param list List to be reordered
 * @param startIndex Item from this index will be taken
 * @param endIndex Item will be moved to this index
 * @returns Reordered array
 * @example
 * reorder([1, 2, 3, 4], startIndex: 0, endIndex: 2)
 * // returns: [2, 3, 1, 4]
 */
export const reorder = <T>(list: Array<T>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
