import { createSlice } from "@reduxjs/toolkit";

export interface AlertObj {
    message: string;
    description: string;
    type: 'success' | 'warning' | 'error' | 'info';
}

const initialState: { alert: AlertObj | null } = {
    alert: null
};

const alertSlice = createSlice({
    name: 'alertSlice',
    initialState,
    reducers: {
        setAlert: (state, action) => {
            state.alert = action.payload;
        }
    }
});

// these methods only modify the state
export const { setAlert } = alertSlice.actions;

// these methods only read the state
export const getAlert = (state: any) => state.alert.alert as AlertObj;

export default alertSlice.reducer;