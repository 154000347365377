import { useAuth } from "oidc-react";
import { Route, RouteProps } from "react-router";

export default function ProtectedRoute(props: RouteProps) {
    const auth = useAuth();

    if (!auth.userData) {
        auth.userManager.signinSilent().catch(() => auth.signIn());

        return null;
    }

    return <Route {...props} />;
}