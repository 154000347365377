import { useSortable } from "@dnd-kit/sortable";
import { ReactNode, useEffect, useState } from "react";
import { CSS } from '@dnd-kit/utilities';
import { UnifiedDndObject } from "../../picker-items/UnifiedDndObject";
import { DndSectionItemType } from "../../picker-items/DndSectionItemType";
import styles from "./sortableItemSectionItemWrapper.module.scss"
import InsertIndicatorLine from "../insert-indicator-line/insertIndicatorLine";
import { PauseOutlined } from "@ant-design/icons";
import { Active, Over, useDndContext } from "@dnd-kit/core";

export default function SortableSectionItemWrapper(props: SortableSectionItemWrapperProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    over
  } = useSortable({ id: props.id });

  const {
    active
  } = useDndContext();

  const [insertLineVisible, setInsertLineVisible] = useState<boolean>(false);

  const isPickerElementOver = (over: Over | null, active: Active | null) => {
      if (!over || !active) {
        setInsertLineVisible(false)
        return;
      }

      let activeObj = JSON.parse(active.id) as UnifiedDndObject;
      const isSame = props.id.localeCompare(over.id) === 0;

      if (isSame && activeObj.typeDisclaimer === DndSectionItemType.PickerElement) {
        setInsertLineVisible(true);
      } else {
        setInsertLineVisible(false)
      }
  }

  useEffect(() => {
    isPickerElementOver(over, active);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [over]);

  return (<>
    <div
      ref={setNodeRef}
      {...attributes}
      style={{
        transition: transition!,
        transform: CSS.Transform.toString({
          x: transform?.x ?? 0,
          y: transform?.y ?? 0,
          scaleX: 1,
          scaleY: 1
        }),
      }}
      className={styles.container}
    >
      {<InsertIndicatorLine isActive={insertLineVisible} />}

      {!props.isHidden && <div className={styles.innerContainer}>
        <PauseOutlined
          {...listeners}
          rotate={90}
        />
        {props.children}
      </div>}
    </div>
    </>);
}

interface SortableSectionItemWrapperProps {
  id: string,
  children: ReactNode,
  isHidden: boolean
}
