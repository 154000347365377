import { useAuth } from "oidc-react";
import CypherCmsHeader from "./cypherCmsHeader";

export default function CypherCmsHeaderContainer() {
    const auth = useAuth();

    const onLogoutPressed = async () => {
        await auth.signOut();
        await auth.signOutRedirect();
    }

    const onLoginPressed = async () => {
        await auth.signIn();
    }

    return <CypherCmsHeader
        onLogoutPressed={onLogoutPressed}
        userData={auth.userData}
        onLoginPressed={onLoginPressed}
    />
}
