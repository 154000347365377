import { DeleteTwoTone } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import { useInjection } from "inversify-react";
import { useState } from "react";
import { LocalizableStringDto, SectionVideoItemApi, SectionVideoItemDetailsDto, SectionVideoItemUpdateDto } from "../../../../../api";
import LocalizableVideo from "../../../../../components/localizableVideo/localizableVideo";
import ApiService from "../../../../../services/apiService";
import styles from "./sectionVideoItem.module.scss";

export default function SectionVideoItem(props: SectionVideoItemProps) {
  const apiService = useInjection(ApiService);
  const [isSaveVisible, setSaveVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState<LocalizableStringDto[]>(props.data.videoUrl!);

  const onSaveClick = async () => {
    try {
      await apiService.getApi(SectionVideoItemApi).apiSectionVideoItemIdPut(props.data.id!, {
        sectionId: props.data.sectionId,
        sectionItemType: props.data.sectionItemType,
        order: props.data.order,
        videoUrl: videoUrl
      } as SectionVideoItemUpdateDto);
      message.success("Saved.");
      setSaveVisible(false);
    } catch (e) {
      console.log(e.response.data);
      message.error("Something went wrong.");
    }
  }

  const onCancelClick = async () => {
    const response = await apiService.getApi(SectionVideoItemApi).apiSectionVideoItemIdGet(props.data.id!);
    setVideoUrl(response.data.videoUrl!);
    setSaveVisible(false);
  };

  const onTextChange = (e: LocalizableStringDto[]) => {
    setVideoUrl(e);
    if (!isSaveVisible) {
      setSaveVisible(true);
    }
  };

  const onDeleteSubmit = async () => {
    try {
      await apiService.getApi(SectionVideoItemApi).apiSectionVideoItemIdDelete(props.data.id!);
      message.success("Deleted.");
      props.onSectionUpdate();
    } catch (e) {
      console.log(e.response.data);
      message.error("Something went wrong.");
    }
  };

  const buttonsStyles = {
    opacity: isSaveVisible ? "1" : "0",
    visibility: isSaveVisible ? "visible" : "hidden"
  } as any;

  return <div className={styles.container}>
    <LocalizableVideo value={videoUrl} onChange={onTextChange} />
    <div style={buttonsStyles} className={styles.buttonContainer}>
      <Button type="primary" onClick={onSaveClick} className={styles.actionButton}>Save</Button>
      <Button onClick={onCancelClick} className={styles.actionButton}>Cancel</Button>
    </div>
    <Popconfirm title="Are you sure?" onConfirm={onDeleteSubmit} onCancel={e => e?.stopPropagation()}
      className={styles.deleteButton}>
      <DeleteTwoTone className={styles.actionButton} twoToneColor="#ff4d4f" />
    </Popconfirm>
  </div>;
}

export interface SectionVideoItemProps {
  data: SectionVideoItemDetailsDto,
  onSectionUpdate: () => void
}
