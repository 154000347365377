import {Transfer} from "antd";

export default function EntityTransfer(props: EntityTransferProps) {

    const elementTitle = props.elementTitlePropertyName ?? 'title';
    const rowKey = props.customRowKeyName ?? 'id';

    const testOnChange = (nextTargetKeys: string[]) => {
        console.log('TRANSFER ON CHANGE', nextTargetKeys);
        props.onChange?.(nextTargetKeys);
    };

    return <>
        <Transfer
            className={props.className}
            showSearch
            listStyle={{
                width: 300,
                height: 400,
            }}
            render={item => item[elementTitle]}
            targetKeys={props.value}
            rowKey={record => record[rowKey]}
            onChange={testOnChange}
            dataSource={props.dataSource}
            titles={props.titles}
        />
    </>
}

interface EntityTransferProps {
    className?: string,
    dataSource: any[],
    onChange?: (nextTargetKeys: string[]) => void,
    titles?: string[],
    value?: any[],
    elementTitlePropertyName?: string,
    customRowKeyName?: string
}