import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useInjection } from "inversify-react";
import { ModuleApi, ModuleCreateDto } from "../../../../api";
import ApiService from "../../../../services/apiService";
import ModuleModalForm from "../moduleModalForm/moduleModalForm";
import styles from "./moduleModalFormAddContainer.module.scss";

export default function ModuleModalFormAddContainer(props: ModuleModalFormAddContainerProps) {
  const apiService = useInjection(ApiService);

  const handleAddNew = () => {
    props.setModalVisible(true);
  };

  const onSubmit = async (values: ModuleCreateDto) => {
    try {
      values.courseId = props.courseId;
      await apiService.getApi(ModuleApi).apiModulePost(values);
    } catch (e: any) {
      return e.response.data;
    }

    props.onFinish();
    props.setModalVisible(false);
  };

  return <>
    <Button
      type="text"
      className={styles.addNewModuleButton}
      block
      icon={<PlusCircleOutlined/>}
      onClick={handleAddNew}>Add new module
    </Button>

    <ModuleModalForm
      isModalVisible={props.isModalVisible}
      setModalVisibility={props.setModalVisible}
      onSubmit={onSubmit}
      modalTitle="Add new Module"
      saveButtonTitle="Submit"
    />
  </>;
}

interface ModuleModalFormAddContainerProps {
  courseId: number,
  onFinish: () => void,
  isModalVisible: boolean
  setModalVisible: (isVisible: boolean) => void;
}
