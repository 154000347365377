import {Upload, message, Button, Tooltip, Switch} from 'antd';
import {DownloadOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {RcFile, UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';
import {useInjection} from 'inversify-react';
import ApiService from '../../services/apiService';
import {FileApi, OrganisationFileApi} from '../../api';
import fileDownload from "js-file-download";
import {FileUploaderProps} from './fileUploaderProps';
import { useSelector } from 'react-redux';
import { getCurrentBrandId } from '../../redux/organisationsSlice';

const mapListToListWithDownloadButton = (files?: UploadFile[]) => {
    if (!files) {
        return [];
    }

    return files.map(f => {
        f.status = 'done'; // set status to show download-icon
        return f;
    })
}

const FileUploader = forwardRef((props: FileUploaderProps, ref: React.ForwardedRef<any>) => {
    const [fileList, setFileList] = useState<UploadFile[]>(mapListToListWithDownloadButton(props.defaultFileList));
    const filesToRemove = useRef<UploadFile[]>([]);
    const [pendingDownload, setPendingDownload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const brandIdFromState = useSelector(getCurrentBrandId);

    const apiService = useInjection(ApiService);
    const fileApi = apiService.getApi(FileApi);
    const organisationFileApi = apiService.getApi(OrganisationFileApi);
    const apiFileDelete = props.useOrganisationFileApi
        ? organisationFileApi.apiOrganisationFileDelete.bind(organisationFileApi)
        : fileApi.apiFileDelete.bind(fileApi);
    const apiFilePost = props.useOrganisationFileApi
        ? organisationFileApi.apiOrganisationFilePost.bind(organisationFileApi)
        : fileApi.apiFilePost.bind(fileApi);
    const apiFileIdGet = props.useOrganisationFileApi
        ? organisationFileApi.apiOrganisationFileFileIdGet.bind(organisationFileApi)
        : fileApi.apiFileFileIdGet.bind(fileApi);

    useEffect(() => {
        setFileList(props.defaultFileList || []);
    }, [props.defaultFileList]);

    useImperativeHandle(ref, () => ({
        async onFormFinish() {
            setIsLoading(true);

            const result = await Promise.all([
                ...fileList.map(f => uploadFile(f)),
                ...filesToRemove.current.map(f => removeFile(f))
            ]);

            setIsLoading(false);
            return result.filter(r => r !== undefined)[0];
        }
    }));

    const refreshFileList = () => {
        setFileList([...fileList]);
    }

    const getFileByUid = (uid: string) => {
        return fileList.find(f => f.uid === uid)!;
    }

    const removeFile = async (file: UploadFile) => {
        // await apiFileDelete(+file.uid);
        fileList.splice(fileList.indexOf(file), 1);
    }

    const uploadFile = async (file: UploadFile) => {
        if (+file.uid > 0) {
            return;
        }

        let fileInList = getFileByUid(file.uid);
        let isFileFromList = true;

        if (!fileInList) {
            isFileFromList = false;
            fileInList = file;
        }

        fileInList.status = "uploading";
        fileInList.percent = 0;
        refreshFileList();

        const httpRequestOptions = {
            onUploadProgress: (event: any) => {
                fileInList.percent = (event.loaded / event.total) * 100;
                refreshFileList();
            }
        }

        try {
            const result = await apiFilePost(file.originFileObj, undefined, true, httpRequestOptions)

            let newlyCreatedFileId = +result.data;

            fileInList.uid = newlyCreatedFileId.toString();
            fileInList.status = "success";
            fileInList.url = "https://"; // actual download is handled by onDownload

            if (isFileFromList) {
                fileList.push(file);
            }

            refreshFileList();
            props.onChange?.(fileList.map(f => f.uid), fileList);
        } catch (error) {
            fileInList.status = "error";
            fileInList.error = error;
            refreshFileList();

            return error;
        }
    }

    function beforeUpload(file: RcFile) {
        if (props.sizeLimitMb) {
            const isSizeOverLimit = file.size / 1024 / 1024 < props.sizeLimitMb;
            if (!isSizeOverLimit) {
                message.error(`Image must be smaller than ${props.sizeLimitMb}MB`);
            }
        }

        return false;
    }

    const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
        setFileList(info.fileList);
        props.onChange?.(info.fileList.map(f => f.uid), info.fileList);
        console.log("onchange", info);
    }

    const onRemove = async (file: UploadFile) => {
        if (+file.uid > 0) {
            filesToRemove.current.push(file);
        }
    }

    const onDownload = async (file: UploadFile) => {
        const id = +file.uid;
        if (!id) {
            throw Error(`Can't handle file without proper numeric ID. File id: ${id}`)
        }
        if (pendingDownload) {
            return;
        }

        setPendingDownload(true);
        apiFileIdGet(id, brandIdFromState, {responseType: 'blob'})
            .then((response) => {
                fileDownload(response.data, file.name || 'download-file');
            }).finally(() => {
            setPendingDownload(false);
        });
    }

    const downloadIcon = () => {
        return pendingDownload
            ? <LoadingOutlined disabled onClick={(event) => {
                event.preventDefault()
            }}/>
            : <DownloadOutlined/>
    }

    // handle maxCount manually as Upload component doesn't fire the onRemove command when replacing files after exceeding the maxCount,
    // possibly leading to orphaned files on the db
    const maxCountReached = fileList.length >= (props.maxCount || Number.MAX_VALUE);

    return (
        <>
            <Upload
                className="file-uploader"
                disabled={isLoading}
                openFileDialogOnClick={!maxCountReached}
                name="file"
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                onDownload={onDownload}
                onChange={onChange}
                fileList={fileList}
                accept={props.accept || ''}
                showUploadList={{
                    showDownloadIcon: true,
                    downloadIcon: downloadIcon(),
                    showRemoveIcon: true
                }}
            >
                {maxCountReached ? <Tooltip title={`Can't upload more than ${props.maxCount} files`}>
                        <Button loading={isLoading} disabled={true} icon={<UploadOutlined/>}>Click to Upload</Button>
                    </Tooltip>
                    : <Button loading={isLoading} disabled={isLoading} icon={<UploadOutlined/>}>Click to
                        Upload</Button>
                }
            </Upload>
        </>
    );
});

export default FileUploader;