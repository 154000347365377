import { Select } from "antd";
import {AchievementCreateDto, AchievementUpdateDto, AchievementCategoryDto} from "../../../api";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import SingleFileUploader from "../../../components/fileUploader/singleFileUploader";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import LocalizableReadonly from "../../../components/localizableReadonly/localizableReadonly";
import {nameof} from "../../../helpers/nameofHelper";

const {Option} = Select;

export default function AchievementModalForm(props: AchievementModalProps) {

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<AchievementCreateDto>('name'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Name has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCreateDto>('description'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Description has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementCreateDto>('eventType'),
            rules: [
                {
                    required: true,
                    message: "Event Type has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementCreateDto>('rewardPoints'),
            rules: [
                {
                    required: true,
                    message: "Reward Points has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementCreateDto>('occurrencesRequired'),
            rules: [
                {
                    required: true,
                    message: "Occurrences Required has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCreateDto>("badgeFileId"),
            label: "Badge Image",
            children: <SingleFileUploader
                accept="image/jpeg,image/png"
                sizeLimitMb={2}
                defaultFile = {undefined}
                useOrganisationFileApi={true}
                isFilePublic={true}
            />,
            rules: [
                {
                    required: true,
                    message: "Badge Image has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCreateDto>('achievementCategoryId'),
            label: 'Achievement Category',
            children: <Select loading={!props.achievementCategories}>
                {props.achievementCategories?.map(pe =>
                    <Option key={pe.id!} value={pe.id!}>
                        <LocalizableReadonly userInputDisabled={true} value={pe.name || undefined} />
                    </Option>
                )}
            </Select>,
            rules: [
                {
                    required: true,
                    message: "Achievement category has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCreateDto>('isDistinct'),
            label: 'Distinct',
            children: <AsyncSwitch />
        },
        {
            name: nameof<AchievementCreateDto>('isVisible'),
            label: 'Visible',
            children: <AsyncSwitch />
        }
    ]

    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
        width={1000}
    />;
}

interface AchievementModalProps {
    initialValues?: AchievementCreateDto | AchievementUpdateDto;
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: AchievementCreateDto | AchievementUpdateDto) => Promise<any>;
    modalTitle: string;
    saveButtonTitle: string;
    achievementCategories: AchievementCategoryDto[] | null;
}