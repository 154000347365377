import { PlusCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { useInjection } from "inversify-react";
import React, { useState } from "react";
import { CourseApi, CourseCreateDto } from "../../../../api";
import ApiService from "../../../../services/apiService";
import CourseModalForm from "../courseModalForm/courseModalForm";
import styles from './courseModalFormAddContainer.module.scss';

export default function CourseModalFormAddContainer(props: CourseModalFormAddContainerProps) {

    const [isModalVisible, setModalVisibility] = useState(false);
    const apiService = useInjection(ApiService);

    const handleAddNew = () => {
        setModalVisibility(true);
    };

    const onSubmit = async (values: CourseCreateDto) => {
        try {
            await apiService.getApi(CourseApi).apiCoursePost(values);
        } catch (e) {
            return e.response.data;
        }

        props.onFinish();
        setModalVisibility(false);
    };

    return <>
        <Card
            className={styles.addNewCard}
            hoverable
            onClick={handleAddNew}
            cover={<div style={{ paddingTop: "100%" }} ></div>}>
            <PlusCircleOutlined /> Add new
        </Card>
        <CourseModalForm
            isModalVisible={isModalVisible}
            setModalVisibility={setModalVisibility}
            onSubmit={onSubmit}
            modalTitle="Add new course"
            saveButtonTitle="Submit"
        />
    </>;
}

interface CourseModalFormAddContainerProps {
    onFinish: () => void
}