import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SectionDetailsDto, SectionDto} from "../api";

export type DetailedDtoDictionary = { [id: string]: SectionDetailsDto };

const initialState: { 
    sections: SectionDto[] | null,
    sectionsDetails: DetailedDtoDictionary | null,
} = {
    sections: [],
    sectionsDetails: {},
};

const sectionsSlice = createSlice({
    name: 'sectionsSlice',
    initialState,
    reducers: {
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setSectionDetails: (
            state, 
            action: PayloadAction<SectionDetailsDto>
        ) => {
            let detailsDict = state.sectionsDetails || {};
            detailsDict[+action.payload.id!] = action.payload;

            state.sectionsDetails = detailsDict;
        },
    }
});

// these methods only modify the state
export const {
    setSections,
    setSectionDetails
} = sectionsSlice.actions;

// these methods only read the state
export const getSections: (x: any) => SectionDto[] = (state: any) => state.sections.sections;
export const getSectionsDetails = (state: any) => state.sections.sectionsDetails as DetailedDtoDictionary;

export default sectionsSlice.reducer;
