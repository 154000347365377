import {Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {generatePath, useHistory, useParams} from "react-router-dom";
import {
    AchievementCategoryApi,
    AchievementCategoryCreateDto,
    AchievementCategoryDto,
    AchievementCategoryUpdateDto,
} from "../../api";
import BrandSider from "../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import {RoutingPaths} from "../routingContainer";
import AchievementCategoryModalFormAddContainer from "./achievementCategoryModalFormAddContainer/achievementCategoryModalFormAddContainer";
import AchievementCategoriesList from "./achievementCategoriesList";
import styles from "./achievementCategoriesListContainer.module.scss";
import { useSelector } from "react-redux";

export default function AchievementCategoriesListContainer() {
    const {brandId} = useParams<{ brandId: string }>();
    const brandIdFromState = useSelector(getCurrentBrandId);
    const apiService = useInjection(ApiService);
    const history = useHistory();
    const [achievementCategories, setAchievementCategories] = useState<AchievementCategoryDto[] | null>();
    const [achievementCategoryModalFormContainerTitle, setAchievementCategoryModalFormContainerTitle] = useState<string>()
    const [achievementCategoryToEdit, setAchievementCategoryToEdit] = useState<AchievementCategoryUpdateDto | null>(null);
    const [idAchievementCategoryToEdit, setIdAchievementCategoryToEdit] = useState<number | null>(null);
    const [isAddFormVisible, setAddFormVisible] = useState(false);
    
    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshAchievementCategoriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshAchievementCategoriesList = async () => {
        try {
            const achievementCategories = await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryGet();
            setAchievementCategories(achievementCategories.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = () => {
        setAchievementCategoryModalFormContainerTitle("Add new achievement category");
        setAchievementCategoryToEdit(null);
        setIdAchievementCategoryToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = (id: number) => {
        history.push(generatePath(RoutingPaths.AchievementCategoryEdit, {brandId: brandId, achievementCategoryId: id}));
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log(`Error while deleting achievement category with id: ${id}`);
                } else {
                    refreshAchievementCategoriesList();
                }
            })
    }

    const onSubmit = async (values: AchievementCategoryCreateDto | AchievementCategoryUpdateDto) => {
        try {
            if (achievementCategoryToEdit === null) {
                let achievementCategoryToCreate = values;
                await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryPost(achievementCategoryToCreate);
            } else if (idAchievementCategoryToEdit !== null) {
                await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryIdPut(idAchievementCategoryToEdit, values);
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false);
        refreshAchievementCategoriesList();
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Manage Achievement Categories
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.AchievementCategoriesList}/>
                    <Content className={styles.content}>
                        <AchievementCategoriesList
                            data={achievementCategories || null}
                            onAddClicked={onAddClicked}
                            onDeleteClicked={onDeleteClicked}
                            onEditClicked={onEditClicked}
                        />
                        <AchievementCategoryModalFormAddContainer
                            onFinish={() => setAddFormVisible(false)}
                            onSubmit={onSubmit}
                            addFormVisibility={isAddFormVisible}
                            title={achievementCategoryModalFormContainerTitle || ""}
                            achievementCategoryToEdit={achievementCategoryToEdit}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>;
}
