import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import {AdminRole, BrandAdminRoleDto, TagDto, VimeoVideoDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import { nameof } from "../../helpers/nameofHelper";
import {useSelector} from "react-redux";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";

const columns: ColumnsType<VimeoVideoDto> = [{
    key: nameof<VimeoVideoDto>("name")
}, {
    key: nameof<VimeoVideoDto>("uri")
}, {
    key: nameof<VimeoVideoDto>("tags"),
    render: (tags: Array<TagDto> | null) => {
        if (!tags) {
            return null;
        }

        return <>{tags.map(t => <Tag>{t.name}</Tag>)}</>;
    }
}, {
    key: nameof<VimeoVideoDto>("updatedAt")
}];

export default function VideosList(props: CrudTableWrapperProps<VimeoVideoDto>) {
    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }
    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
        />
    </>
}