import {Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {generatePath, useHistory, useParams} from "react-router-dom";
import {OrganisationUserApi, OrganisationUserDto} from "../../api";
import BrandSider from "../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import {RoutingPaths} from "../routingContainer";
import UsersList from "./usersList";
import styles from "./usersListContainer.module.scss";

export default function UsersListContainer() {
    const {brandId} = useParams<{ brandId: string }>();
    const brandIdFromState = useSelector(getCurrentBrandId);
    const apiService = useInjection(ApiService);
    const history = useHistory();
    const [users, setUsers] = useState<OrganisationUserDto[] | null>();

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshUsersList = async () => {
        try {
            const users = await apiService.getApi(OrganisationUserApi)
                .apiOrganisationUserOrganisationOrganisationIdGet(brandIdFromState);
            setUsers(users.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onEditClicked = (id: number) => {
        history.push(generatePath(RoutingPaths.UserStats, {brandId: brandId, userId: id}));
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Users
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.UsersList}/>
                    <Content className={styles.content}>
                        <UsersList
                            data={users || null}
                            onEditClicked={onEditClicked}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
