import { PlusOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { useState } from "react";
import { OrganisationUserInvitationDto } from "../../../../../../api";
import OrganisationUserInviteModalForm from "../../../../components/organisationUserInviteModalForm/organisationUserInviteModalForm";
import OrganisationUsersListContainer from "../../../../components/organisationUsersList/organisationUsersListContainer";
import styles from "./organisationFormAdministratorsCard.module.scss";

export default function OrganisationFormAdministratorsCard(props: OrganisationFormAdministratorsCardProps) {

    const [isOrganisationEditModalVisible, setIsOrganisationEditModalVisible] = useState(false);
    const showModal = () => {
        setIsOrganisationEditModalVisible(true);
    };

    const onInviteSubmit = async (value: OrganisationUserInvitationDto) => {
        const error = await props.onInviteSubmit(value);

        if (!error) {
            setIsOrganisationEditModalVisible(false);
            return;
        }

        return error;
    }

    return <>
        <Card
            title="Administrators"
            bordered={true}
            extra={
                <Button
                    onClick={showModal}
                    icon={<PlusOutlined />}
                >Add new administrator</Button>
            }
            className={styles.administratorsCard}
            bodyStyle={{ padding: 0 }}
            headStyle={{ paddingLeft: 16 }}
        >
            <OrganisationUsersListContainer />
        </Card>

        <OrganisationUserInviteModalForm
            isModalVisible={isOrganisationEditModalVisible}
            setModalVisibility={(isVisible: boolean) => setIsOrganisationEditModalVisible(isVisible)}
            onSubmit={onInviteSubmit}
        />
    </>;
}

export interface OrganisationFormAdministratorsCardProps {
    onInviteSubmit: (value: OrganisationUserInvitationDto) => Promise<any>;
}
