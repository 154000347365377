import { SectionCreateDto, SectionUpdateDto } from "../../../../api";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../../components/localizableInput/localizableInput";
import { nameof } from "../../../../helpers/nameofHelper";

const formItems: CrudFormItemProps[] = [
    {
        name: nameof<SectionUpdateDto>("title"),
        rules: [
            {
                required: true,
                message: 'Section name is required'
            }
        ],
        children: <LocalizableInput />
    }
]

export default function SectionModalForm(props: SectionModalProps) {
    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface SectionModalProps {
    initialValues?: SectionCreateDto | SectionUpdateDto,
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: SectionCreateDto | SectionUpdateDto) => Promise<any>,
    modalTitle: string,
    saveButtonTitle: string,
}