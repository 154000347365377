import { Col, Row, Skeleton } from "antd";
import BrandInformationCard from "./components/organisationFormEditBrandInfoCard/organisationFormEditBrandInfoCard";
import OrganisationFormAdministratorsCard from "./components/organisationFormAdministratorsCard/organisationFormAdministratorsCard";
import { OrganisationDetailsDto, OrganisationUserInvitationDto, OrganisationUpdateDto, UpdateVimeoTokenDto } from "../../../../api";

export default function OrganisationFormEdit(props: OrganisationFormEditProps) {
    return <>
        {props?.organisation
            ? <Row gutter={16}>
                <Col span={12}>
                    <BrandInformationCard 
                        deleteBrand={props.deleteBrand}
                        onEditSubmit={props.onEditSubmit}
                        organisation={props.organisation}
                    />
                </Col>
                <Col span={12}>
                    <OrganisationFormAdministratorsCard 
                        onInviteSubmit={props.onInviteSubmit}
                    />
                </Col>
            </Row>
            : <Skeleton />
        }
    </>;
}

export interface OrganisationFormEditProps {
    organisation?: OrganisationDetailsDto;
    onInviteSubmit: (value: OrganisationUserInvitationDto) => Promise<any>;
    deleteBrand: () => Promise<any>;
    onEditSubmit: (value: OrganisationUpdateDto & UpdateVimeoTokenDto) => Promise<any>;
}
