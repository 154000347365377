import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useInjection } from "inversify-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {OrganisationApi} from "../../api";
import EmptyScreenInfo from "../../components/emptyScreenInfo/emptyScreenInfo";
import Mapper from "../../helpers/mapper";
import { getOrganisations, setOrganisations } from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import { RoutingPaths } from "../routingContainer";
import BrandsMenuSider from "./components/brandsMenuSider/brands-menu-sider";
import styles from "./organisationsRoutingContainer.module.scss";
import OrganisationFormCreateContainer from "./sub-pages/organisationsFormCreate/organisationsFormCreateContainer";
import OrganisationFormEditContainer from "./sub-pages/organisationsFormEdit/organisationsFormEditContainer";

export default function OrganisationsRoutingContainer() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const siderItems = useSelector(getOrganisations)?.map(organisation => Mapper.OrganisationDto_CrudSiderItemProps(organisation));
    const apiService = useInjection(ApiService);

    useEffect(() => {
        apiService.getApi(OrganisationApi).apiOrganisationGet().then(o => {
            dispatch(setOrganisations(o.data));
        });

    }, [apiService, dispatch]);

    const onAddClicked = () => {
        history.push(RoutingPaths.OrganisationCreate);
    };

    const onEditClicked = (editOrganisationRoutePath: string) => {
        history.push(editOrganisationRoutePath);
    }

    return <>
        <BrandsMenuSider
            items={siderItems}
            currentlyActiveItemKey={location.pathname}
            onAddClicked={onAddClicked}
            addButtonText={"Add new brand"}
            addButtonKey={RoutingPaths.OrganisationCreate}
            onEditClicked={onEditClicked}
        />
        <Layout>
            <Content className={styles.content}>
                <Switch>
                    {/* key is required to force refresh after route change */}
                    <Route 
                        path={RoutingPaths.OrganisationEdit} 
                        key={location.pathname}
                    >
                        <OrganisationFormEditContainer />
                    </Route>
                    <Route path={RoutingPaths.OrganisationCreate}>
                        <OrganisationFormCreateContainer />
                    </Route>
                    <Route path="*">
                        <EmptyScreenInfo
                            text="Please select brand from sidemenu."
                        />
                    </Route>
                </Switch>
            </Content>
        </Layout>
    </>;
}
