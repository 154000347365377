import {Layout, message, Tabs} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    AchievementCategoryApi,
    AchievementCategoryDetailsDto
} from "../../../api";
import BrandSider from "../../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import ApiService from "../../../services/apiService";
import {RoutingPaths} from "../../routingContainer";
import AchievementCategoryForm from "../achievementCategoryModalForm/achievementCategoryForm";
import styles from "./achievementCategoryEditFormContainer.module.scss";

export default function AchievementCategoryEditFormContainer() {
    const { achievementCategoryId } = useParams<{ achievementCategoryId: string }>();
    const apiService = useInjection(ApiService);
    const [achievementCategory, setAchievementCategory] = useState<AchievementCategoryDetailsDto>();

    useEffect(() => {
        refreshAchievementCategoryDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievementCategoryId]);

    const onSubmit = async (value: AchievementCategoryDetailsDto) => {
        let achievementCategoryToUpdate = value;

        await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryIdPut(+achievementCategoryId, achievementCategoryToUpdate)
            .then(response => {
                if (response.request.status !== 200) {
                    message.error('Error occured while editing achievementCategory');
                } else {
                    message.success('Achievement category has been successfully edited');
                }
            })

        refreshAchievementCategoryDetails();
    }

    const refreshAchievementCategoryDetails = async () => {
        try {
            const achievementCategoryDetails = await apiService.getApi(AchievementCategoryApi).apiAchievementCategoryIdGet(+achievementCategoryId);
            setAchievementCategory(achievementCategoryDetails.data);
        } catch (e) {
            message.error('Error occured while fetching achievement category details');
        }
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Edit achievement category with id: {achievementCategoryId}
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.AchievementCategoriesList}/>
                    <Content className={styles.content}>
                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                Achievement Category details
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>
                        <br/>
                        {achievementCategory !== undefined ?
                            <AchievementCategoryForm
                                achievementCategoryToEdit={achievementCategory || null}
                                onSubmit={onSubmit}
                            /> : null}   
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
