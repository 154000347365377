import { Divider } from "antd";
import { useEffect, useState } from "react";
import { LocalizableStringDto } from "../../api";
import LocalizableHelper from "../../helpers/localizableHelper";
import LanguageSelector, { Language } from "../languageSelector/languageSelector";
import styles from "./localizableReadonly.module.scss";
import {useSelector} from "react-redux";
import {getGlobalLanguage} from "../../redux/languageSlice";

function getEntryValueForLanguageOrEmptyString(language: Language, value: LocalizableStringDto[] | undefined): string {
    return LocalizableHelper.getEntryForLanguage(language, value || [])?.value || "";
}

export default function LocalizableReadonly(props: LocalizableInputProps) {
    const currentValue = props.value;
    const defaultLanguage = useSelector(getGlobalLanguage);
    const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
    const [currentTextValue, setCurrentTextValue] = useState(
        getEntryValueForLanguageOrEmptyString(currentLanguage, currentValue));

    useEffect(() => {
        setCurrentTextValue(getEntryValueForLanguageOrEmptyString(currentLanguage, currentValue))
    }, [currentValue, currentLanguage]);

    useEffect(() => {
        setCurrentLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const onLanguageSelected = (value: Language) => {
        setCurrentLanguage(value);
        setCurrentTextValue(getEntryValueForLanguageOrEmptyString(value, currentValue));
    };

    if (!currentValue) {
        return null;
    }

    return <div className="localizable-readonly">
        <LanguageSelector
            disabled={props.userInputDisabled}
            bordered={false}
            onSelect={onLanguageSelected}
        />
        <Divider
            className={styles.verticalDivider}
            type="vertical"
        />
        <span className={styles.currentTextValue}>
            {currentTextValue}
        </span>
    </div>
}

interface LocalizableInputProps {
    value?: LocalizableStringDto[],
    userInputDisabled?: boolean
}
