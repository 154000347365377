import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { useEffect, useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, ContentState, EditorState } from 'draft-js';

function getEditorStateFromHtml(value?: string): EditorState | undefined {

    if (!value) {
        return;
    }

    const contentBlock = htmlToDraft(value);

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
}

export default function RichtextEditor(props: RichtextEditorProps) {
    const [editorState, setEditorState] = useState(getEditorStateFromHtml(props.value));
    const [currentHtmlValue, setCurrentHtmlValue] = useState(props.value);

    useEffect(() => {
        if (props.value !== currentHtmlValue) {
            setEditorState(getEditorStateFromHtml(props.value));
            setCurrentHtmlValue(props.value);
        }
    }, [props.value, currentHtmlValue]);

    const onContentStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        props.onChange?.(html);
        setCurrentHtmlValue(html);
    };

    return <Editor
        editorState={editorState}
        onEditorStateChange={onContentStateChange}
        editorClassName="ant-input"
    />;
}

interface RichtextEditorProps {
    value?: string,
    onChange?: (value: string) => void
}