import { FileOutlined, FontSizeOutlined, PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import { SectionItemType } from "../../../../api";
import { PickerDndObject } from "../../section-drag-and-drop/picker-items/PickerDndObject";
import styles from "./elementPicker.module.scss"
import ElementPickerDraggableWrapper from "../../section-drag-and-drop/components/element-picker/elementPickerDraggableWrapper";
import SplittedElementPickerItemSubtitle from "../elementPickerItem/splitted-version/splittedElementPickerSubtitle";
import SplittedElementPickerItemTile from "../elementPickerItem/splitted-version/splittedElementPickerTile";

export default function ElementPicker(props: ElementPickerProps) {
    function generateElement(title: string, icon: ReactNode, type: SectionItemType) {
        return <>
            <ElementPickerDraggableWrapper id={PickerDndObject.create(type)}>
                <SplittedElementPickerItemTile icon={icon} />
            </ElementPickerDraggableWrapper>

            <SplittedElementPickerItemSubtitle title={title}/>
        </>;
    }

    return <>
        <div className={styles.wrapper}>
            <div className={styles.title}>
                ADD ELEMENT
            </div>
            {generateElement("Text Field", <FontSizeOutlined />, SectionItemType.Text)}
            {generateElement("File", <PictureOutlined />, SectionItemType.File)}
            {generateElement("Video", <VideoCameraOutlined />, SectionItemType.Video)}
            {generateElement("SCORM", <FileOutlined />, SectionItemType.Scorm)}
        </div>
    </>;
}

export interface ElementPickerProps {

}
