import axios, { AxiosError, AxiosResponse } from "axios";
import { createBrowserHistory } from 'history';
import { injectable } from "inversify";
import "reflect-metadata";
import { Configuration } from "../api";
import { BaseAPI } from "../api/base";
import { RoutingPaths } from "../pages/routingContainer";
import { AlertObj, setAlert } from "../redux/alertSlice";
import { getCurrentBrandId } from "../redux/organisationsSlice";
import { store } from "../redux/store";

@injectable()
export default class ApiService {

    constructor() {
        axios.interceptors.response.use(
            (response: AxiosResponse) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            }, 
            function (error: AxiosError) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                if (error.response?.status === 401) {
                    store.dispatch(setAlert(
                        {
                            message: "Sorry, it looks like your login token expired",
                            description: "You were redirected to the login screen.",
                            type: "warning"
                        } as AlertObj
                    ));
                    createBrowserHistory().replace(RoutingPaths.Login);
                    window.location.reload();
                } else if (error.response?.status === 403) {
                    store.dispatch(setAlert(
                        {
                            message: "Sorry, it looks like you don't have access to this request",
                            description: "Please contact your system administrator.",
                            type: "warning"
                        } as AlertObj
                    ));
                } else if (error.response?.status !== 400) {
                    store.dispatch(setAlert(
                        {
                            message: "Sorry, something happened on the server, please contact your system administrator",
                            description: error.message,
                            type: "error"
                        } as AlertObj
                    ));
                }
                return Promise.reject(error);
            }
        );

        axios.defaults.withCredentials = true;
    }

    public getApi<T extends BaseAPI>(c: new (configuration?: Configuration, basePath?: string) => T): T {
        const apiUrl = process.env.REACT_APP_API_URL;
        const session = sessionStorage.getItem(`oidc.user:${apiUrl}:Apptimise.CypherCms.Web`);
        const accessToken = session ? JSON.parse(session)["access_token"] : null;
        const baseOptions = {
            headers: {
                "Organisation": getCurrentBrandId(store.getState())
            }
        };

        return new c(new Configuration({ accessToken, baseOptions }), apiUrl);
    }
}
