import EventLivestreamModalForm from "../eventLivestreamModalForm/eventLivestreamModalForm";
import {EventLivestreamCreateDto, EventLivestreamUpdateDto, VimeoLiveEventDto} from "../../../api";

export default function EventLivestreamModalFormAddContainer(props: EventLivestreamModalFormAddContainerProps) {

    return <>
        <EventLivestreamModalForm
            isModalVisible={props.addFormVisibility}
            setModalVisibility={props.onFinish}
            onSubmit={props.onSubmit}
            modalTitle={props.title}
            saveButtonTitle="Submit"
            initialValues={props.eventLivestreamToEdit!}
            vimeoLiveEvents={props.vimeoLiveEvents}
        />
    </>
}

interface EventLivestreamModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void,
    onSubmit: (values: EventLivestreamCreateDto | EventLivestreamUpdateDto) => Promise<any>,
    addFormVisibility: boolean,
    title: string,
    eventLivestreamToEdit: EventLivestreamUpdateDto | null,
    vimeoLiveEvents: VimeoLiveEventDto[] | null;
}