import { Layout, Menu } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, NavLink, useParams } from "react-router-dom";
import { AdminRole, BrandAdminRoleDto } from "../../api";
import { brandAdminRolesForOrgUser, userHasRole } from "../../helpers/brandAdminRoleHelper";
import { RoutingPaths } from "../../pages/routingContainer";
import { getAllBrandAdminRoles } from "../../redux/brandAdminRolesSlice";
import { getCurrentBrandId, getCurrentOrganisationUser, setCurrentBrandId } from "../../redux/organisationsSlice";

export default function BrandSider(props: BrandSiderProps) {
    const dispatch = useDispatch();
    const { brandId } = useParams<{ brandId: string }>();
    const brandIdFromState = useSelector(getCurrentBrandId);

    useEffect(() => {
        if (brandId !== brandIdFromState) {
            dispatch(setCurrentBrandId(brandId));
        }
    }, [brandId, dispatch]);

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []
    
    let brandAdminRoles : BrandAdminRoleDto[] = []

    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    return <Layout.Sider theme="light">
        <Menu defaultSelectedKeys={[props.selectedItem]}>
            {
                userHasRole(AdminRole.VideoViewer, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.VideosList}>
                        <NavLink to={generatePath(RoutingPaths.VideosList, { brandId })}>
                            Videos
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.LivestreamViewer, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.LivestreamsList}>
                        <NavLink to={generatePath(RoutingPaths.LivestreamsList, { brandId })}>
                            Livestreams
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.CalendarManager, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.CalendarsList}>
                        <NavLink to={generatePath(RoutingPaths.CalendarsList, { brandId })}>
                            Calendar
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.ProductViewer, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.ProductsList}>
                        <NavLink to={generatePath(RoutingPaths.ProductsList, { brandId })}>
                            Products
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.ElearningEditor, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.CoursesList}>
                        <NavLink to={generatePath(RoutingPaths.CoursesList, { brandId })}>
                            E-Learning
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.TranslationEditor, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.TranslationList}>
                        <NavLink to={generatePath(RoutingPaths.TranslationList, { brandId })}>
                            Translations
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.EventEditor, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.EventsList}>
                        <NavLink to={generatePath(RoutingPaths.EventsList, { brandId })}>
                            Events
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.TermsEditor, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.BrandTermsList}>
                        <NavLink to={generatePath(RoutingPaths.BrandTermsList, {brandId })}>
                            Brand terms
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.ReportsViewer, brandAdminRoles) && (
                    <Menu.Item key={RoutingPaths.ReportsList}>
                        <NavLink to={generatePath(RoutingPaths.ReportsList, {brandId})}>
                            Reports
                        </NavLink>
                    </Menu.Item>
                )
            }
            {
                userHasRole(AdminRole.AchievementsEditor, brandAdminRoles) && (
                    <>
                        <Menu.Item key={RoutingPaths.AchievementCategoriesList}>
                            <NavLink to={generatePath(RoutingPaths.AchievementCategoriesList, {brandId})}>
                                Achievement Categories
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key={RoutingPaths.AchievementsList}>
                            <NavLink to={generatePath(RoutingPaths.AchievementsList, {brandId})}>
                                Achievements
                            </NavLink>
                        </Menu.Item>
                    </>
                )
            }
            <Menu.Item key={RoutingPaths.UsersList}>
                <NavLink to={generatePath(RoutingPaths.UsersList, {brandId})}>
                    Users
                </NavLink>
            </Menu.Item>
        </Menu>
    </Layout.Sider>;
}

interface BrandSiderProps {
    selectedItem: string
}