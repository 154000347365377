import React from "react";
import { BrandTermCreateDto, BrandTermUpdateDto } from "../../../api";
import { CrudFormItemProps } from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import LocalizableRichtextEditor from "../../../components/localizableRichtextEditor/localizableRichtextEditor";
import { nameof } from "../../../helpers/nameofHelper";

const formItems: CrudFormItemProps[] = [
    {
        name: nameof<BrandTermCreateDto>("name"),
        children: <LocalizableInput/>,
        rules:[
            {
                required: true,
                message: 'Brand term name is required'
            }
        ]
    }, {
        name: nameof<BrandTermCreateDto>("content"),
        children: <LocalizableRichtextEditor/>,
        rules:[
            {
                required: true,
                message: 'Brand term content is required'
            }
        ]
    }
];

export default function BrandTermModalForm(props: BrandTermModalProps) {
    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
        width={1500}
    />
}

interface BrandTermModalProps {
    initialValues?: BrandTermCreateDto | BrandTermUpdateDto,
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: BrandTermCreateDto | BrandTermUpdateDto) => Promise<any>,
    modalTitle: string,
    saveButtonTitle: string,
}