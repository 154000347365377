
import { UnifiedDndObject } from "../../../picker-items/UnifiedDndObject";
import { PauseOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

const { Panel } = Collapse;

export default function SectionDragOverlay(props: SectionDragOverlayProps) {

  return <>
    <Collapse
      expandIconPosition="left"
      expandIcon={() => <PauseOutlined rotate={90} />}
      bordered={true}
    >
      <Panel
        header={`Section id: ${props.item.sectionId}`}
        key={props.item.sectionId?.toString()!}
      />
    </Collapse>
  </>;
}

export interface SectionDragOverlayProps {
  item: UnifiedDndObject
}
