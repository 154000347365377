import { Input } from "antd";
import { ReactNode } from "react";
import { OrganisationDetailsDto, UpdateVimeoTokenDto } from "../../../../api";
import ColorPicker from "../../../../components/colorPicker/colorPicker";
import CrudForm from "../../../../components/crudForm/crudForm";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import SingleFileUploader from "../../../../components/fileUploader/singleFileUploader";
import Mapper from "../../../../helpers/mapper";
import { nameof } from "../../../../helpers/nameofHelper";

export default function OrganisationForm<T>(props: OrganisationFormProps<T>) {
    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<OrganisationDetailsDto>("catalogFileId"),
            label: "Logo",
            children: <SingleFileUploader 
                accept="image/jpeg,image/png"
                sizeLimitMb={2}
                defaultFile = {
                    props.initialValues?.catalogFile
                        ? Mapper.FileInfoDto_UploadFile(props.initialValues?.catalogFile)
                        : undefined
                }
                isFilePublic={false}
            />
        },
        {
            name: nameof<OrganisationDetailsDto>("name"),
            rules: [{ required: true, message: 'This field is required' }]
        },{
            name: nameof<OrganisationDetailsDto>("description"),
            rules: []
        },{
            name: nameof<OrganisationDetailsDto>("primaryColor"),
            children: <ColorPicker />
        },{
            name: nameof<OrganisationDetailsDto>("secondaryColor"),
            children: <ColorPicker />
        },{
            name: nameof<OrganisationDetailsDto>("tetriaryColor"),
            children: <ColorPicker />
        },{
            name: nameof<OrganisationDetailsDto>("vimeoUserId"),
            rules: []
        },{
            name: nameof<UpdateVimeoTokenDto>("token"),
            label: "Vimeo token",
            children: <Input.Password placeholder="Fill this value to replace current Vimeo token" />,
            rules: []
        }];

    return <CrudForm 
        formItems={formItems} 
        onSubmit={props.onSubmit} 
        initialValues={props.initialValues} 
        extraActions={props.extraActions}
        saveButtonTitle={props.saveButtonTitle}
    />;
}

interface OrganisationFormProps<T> {
    onSubmit: (v: T) => Promise<any>,
    initialValues?: OrganisationDetailsDto,
    extraActions?: ReactNode,
    saveButtonTitle?: string
}
