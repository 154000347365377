import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {AchievementCategoryDto, AdminRole, BrandAdminRoleDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import { nameof } from "../../helpers/nameofHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {useSelector} from "react-redux";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";

export default function AchievementCategoriesList(props: CrudTableWrapperProps<AchievementCategoryDto>) {
    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<AchievementCategoryDto> = [{
        key: nameof<AchievementCategoryDto>("name"),
        render: (names) => <LocalizableReadonly value={names} />
    }];
    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle="+ Add new achievement category"
        />
    </>
}
