import { createSlice } from "@reduxjs/toolkit";
import { AdminRole, BrandAdminRoleDto } from "../api";

const initialState: {
    allBrandAdminRoles: BrandAdminRoleDto[] | null
} = {
    allBrandAdminRoles: null
};

const brandAdminRoleSlice = createSlice({
    name: "brandAdminRoleSlice",
    initialState,
    reducers: {
        setAllBrandAdminRoles: (state, action) => {
            state.allBrandAdminRoles = action.payload;
        }
    }
});

export const { setAllBrandAdminRoles } = brandAdminRoleSlice.actions;

export const getAllBrandAdminRoles: (x: any) => BrandAdminRoleDto[] = (state: any) => state.brandAdminRole.allBrandAdminRoles;

export default brandAdminRoleSlice.reducer;
