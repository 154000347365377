import { Button, Table } from "antd";
import { MissingLanguageDto } from "../../api";

export default function ReportsView(props: ReportsViewProps) {

    const columns = [
        {
            title: 'Property name',
            dataIndex: 'propertyName',
            key: 'propertyName'
        },
        {
            title: 'Missing locales',
            dataIndex: 'missingLocales',
            key: 'missingLocales'
        }
    ]

    return <>
        <h3>{props.reportDetails[0][0].entityType}</h3>

        {props.reportDetails.map((reportGroupedByEntityId, i) =>
            <div key={i}>
                <h4>Entity ID: {reportGroupedByEntityId[0].entityId}</h4>
                <Table
                    title={() => <Button
                        type='primary'
                        onClick={() => props.onEditClicked(reportGroupedByEntityId[0].entityId!,reportGroupedByEntityId[0].entityType)}
                    >Edit entity</Button>}
                    dataSource={reportGroupedByEntityId.map((reportPropertyNames, index) => {
                        return ({
                            key: index,
                            propertyName: reportPropertyNames.propertyName,
                            missingLocales: reportPropertyNames.missingLocales
                        })
                    })}
                    columns={columns}
                />
            </div>
        )}
    </>
}

interface ReportsViewProps {
    reportDetails: MissingLanguageDto[][],
    onReportsRefreshClicked: () => any,
    onEditClicked: any
}