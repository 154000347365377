import {useInjection} from "inversify-react";
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import { TranslationDto, VimeoLiveEventApi } from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import { Layout } from "antd";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {Content} from "antd/lib/layout/layout";
import styles from "./livestreamsListContainer.module.scss";
import { RoutingPaths } from "../routingContainer";
import BrandSider from "../../components/brandSider/brandSider";
import LivestreamsList from "./livestreamsList";

export default function LivestreamsListContainer() {
    const apiService = useInjection(ApiService);
    const [livestreams, setLivestreams] = useState<TranslationDto[] | null>();
    const brandIdFromState = useSelector(getCurrentBrandId);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshVideosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshVideosList = async () => {
        try {
            const videos = await apiService.getApi(VimeoLiveEventApi).apiVimeoLiveEventGet();
            setLivestreams(videos.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    return <>
        <Layout style={{ backgroundColor: "white" }}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Livestreams
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.LivestreamsList} />
                    <Content className={styles.content}>
                        <LivestreamsList
                            data={livestreams || null}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
