import {Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generatePath, useHistory, useParams} from "react-router-dom";
import {EventApi, EventCreateDto, EventDto, EventUpdateDto} from "../../api";
import BrandSider from "../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import {RoutingPaths} from "../routingContainer";
import EventModalFormAddContainer from "./eventModalFormAddContainer/eventModalFormAddContainer";
import EventsList from "./eventsList";
import styles from "./eventsListContainer.module.scss";

export default function EventsListContainer() {
    const {brandId} = useParams<{ brandId: string }>();
    const brandIdFromState = useSelector(getCurrentBrandId);
    const apiService = useInjection(ApiService);
    const history = useHistory();
    const [events, setEvents] = useState<EventDto[] | null>();
    const [eventModalFormContainerTitle, setEventModalFormContainerTitle] = useState<string>()
    const [eventToEdit, setEventToEdit] = useState<EventUpdateDto | null>(null);
    const [idEventToEdit, setIdEventToEdit] = useState<number | null>(null);
    const [isAddFormVisible, setAddFormVisible] = useState(false);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshEventsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshEventsList = async () => {
        try {
            const events = await apiService.getApi(EventApi).apiEventGet();
            setEvents(events.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = () => {
        setEventModalFormContainerTitle("Add new event");
        setEventToEdit(null);
        setIdEventToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = (id: number) => {
        history.push(generatePath(RoutingPaths.EventEdit, {brandId: brandId, eventId: id}));
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(EventApi).apiEventIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log(`Error while deleting event with id: ${id}`);
                } else {
                    refreshEventsList();
                }
            })
    }

    const onSubmit = async (values: EventCreateDto | EventUpdateDto) => {
        try {
            if (eventToEdit === null) {
                let eventToCreate = values;
                // eventToCreate.titles = null;
                await apiService.getApi(EventApi).apiEventPost(eventToCreate);
            } else if (idEventToEdit !== null) {
                await apiService.getApi(EventApi).apiEventIdPut(idEventToEdit, values);
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false);
        refreshEventsList();
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Manage events
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.EventsList}/>
                    <Content className={styles.content}>
                        <EventsList
                            data={events || null}
                            onAddClicked={onAddClicked}
                            onDeleteClicked={onDeleteClicked}
                            onEditClicked={onEditClicked}
                        />
                        <EventModalFormAddContainer
                            onFinish={() => setAddFormVisible(false)}
                            onSubmit={onSubmit}
                            addFormVisibility={isAddFormVisible}
                            title={eventModalFormContainerTitle || ""}
                            eventToEdit={eventToEdit}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
