import { Alert } from "antd";
import { useSelector } from "react-redux";
import { getAlert } from "../../redux/alertSlice";

export default function AlertContainer() {
    const alert = useSelector(getAlert);
    return <>
        { alert
            ? <Alert
                message={alert.message}
                description={alert.description}
                type={alert.type}
                closable
            />
          : ""
        }
    </>
}
