import { Button, ButtonProps, Popconfirm, PopconfirmProps } from "antd";
import React, { useState } from "react";

export default function AsyncButtonPopconfirm(
    props: (PopconfirmProps & ButtonProps & PopconfirmProps)
) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (event: any) => {
        setIsLoading(true);
        try {
            await props.onConfirm?.(event);
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        <Popconfirm
            title={props.title}
            onConfirm={onClick}
            onCancel={props.onCancel}
            okText={props.okText}
            cancelText={props.cancelText}
        >
            <Button
                children={props.children}
                danger={props.danger}
                type={props.type}
                loading={isLoading} 
                disabled={isLoading} 
            />
        </Popconfirm>
    </>
}
