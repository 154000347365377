import {Input} from "antd";
import {OrganisationUserDetailsDto} from "../../../api";
import styles from "./userStatsFormContainer.module.scss";

export default function UserStatsForm(props: UserStatsProps) {
    return <>

        <div style={{marginBottom: "25px"}}>
            <div className={styles.userDataContainer}>
                <p className={styles.userDataLabel}>Name: </p>
                <span className={styles.userDataField}>{props.user.name}</span>
            </div>
            <div className={styles.userDataContainer}>
                <p className={styles.userDataLabel}>Email: </p>
                <span className={styles.userDataField}>{props.user.email}</span>
            </div>
            <div className={styles.userDataContainer}>
                <p className={styles.userDataLabel}>Achievement Score: </p>
                <span className={styles.userDataField}>{props.user.achievementScore}</span>
            </div>
            <div className={styles.userDataContainer}>
                <p className={styles.userDataLabel}>Culture Code: </p>
                <span className={styles.userDataField}>{props.user.cultureCode}</span>
            </div>
        </div>
    </>
}

interface UserStatsProps {
    user: OrganisationUserDetailsDto;
}
