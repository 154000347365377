import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { getCoursesDetails } from "../../../../redux/coursesSlice";
import { getGlobalLanguage } from "../../../../redux/languageSlice";
import { getCurrentBrandId } from "../../../../redux/organisationsSlice";
import { RoutingPaths } from "../../../routingContainer";
import styles from "./moduleTopBar.module.scss";

export default function ModuleTopBar() {
  const { courseId } = useParams<{ courseId: string }>();
  const [courseName, setCourseName] = useState("");
  const defaultLanguage = useSelector(getGlobalLanguage);
  const brandId = useSelector(getCurrentBrandId);
  const history = useHistory();

  const course = useSelector(getCoursesDetails)?.[+courseId] || undefined;

  useEffect(() => {
      setCourseName(
        course?.name?.find(e => e.locale === defaultLanguage)?.value ?? ""
      );
  }, [course, defaultLanguage]);


  const onCoursesClick = () => {
    history.push(generatePath(RoutingPaths.CoursesList, { brandId: brandId }));
  };

  return <Breadcrumb className={styles.breadcrumb}>
    <Breadcrumb.Item onClick={onCoursesClick}>Your courses</Breadcrumb.Item>
    <Breadcrumb.Item>{courseName}</Breadcrumb.Item>
  </Breadcrumb>;
}
