import { AchievementCategoryDto, AchievementCategoryDetailsDto, AchievementCategoryUpdateDto } from "../../../api";
import CrudForm from "../../../components/crudForm/crudForm";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import SingleFileUploader from "../../../components/fileUploader/singleFileUploader";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import Mapper from "../../../helpers/mapper";
import {nameof} from "../../../helpers/nameofHelper";

const layout = {
    labelCol: {span: 2},
    wrapperCol: {span: 5},
};

const tailLayout = {
    wrapperCol: {offset: 1},
};

export default function AchievementCategoryForm(props: AchievementCategoryFormProps) {
    const initValues = {
        "name": props.achievementCategoryToEdit!.name,
        "description": props.achievementCategoryToEdit!.description,
        "badgeFileId": props.achievementCategoryToEdit?.badgeFileId,
        "badgeFile": props.achievementCategoryToEdit?.badgeFile
    }
    
    const formItems: CrudFormItemProps[] = [
        {
            label: "Name",
            name: nameof<AchievementCategoryDetailsDto>('name'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Name has to be assigned"
                }
            ]
        },
        {
            label: "Description",
            name: nameof<AchievementCategoryDetailsDto>('description'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Description has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementCategoryDetailsDto>("badgeFileId"),
            label: "Badge Image",
            children: <SingleFileUploader
                accept="image/jpeg,image/png"
                sizeLimitMb={2}
                defaultFile = {
                    initValues?.badgeFile
                        ? Mapper.FileInfoDto_UploadFile(initValues?.badgeFile)
                        : undefined
                }
                useOrganisationFileApi={true}
                isFilePublic={true}
            />,
            rules: [
                {
                    required: true,
                    message: "Badge Image has to be assigned"
                }
            ]
        }
    ];

    return <CrudForm
        formItems={formItems}
        formProps={layout}
        tailProps={tailLayout}
        saveButtonTitle={"Submit"}
        initialValues={initValues}
        onSubmit={props.onSubmit}
    />;
}

interface AchievementCategoryFormProps {
    onSubmit: (name: AchievementCategoryDetailsDto) => Promise<any>,
    achievementCategoryToEdit: AchievementCategoryUpdateDto | null
}