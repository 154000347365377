import {Button, Layout, Modal, Skeleton, Spin, Switch} from "antd";
import {Content} from "antd/lib/layout/layout";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import BrandSider from "../../components/brandSider/brandSider";
import {RoutingPaths} from "../routingContainer";
import styles from "./reportsListContainer.module.scss";
import ReportsList from "./reportsList";
import React, {useEffect, useState} from "react";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import {useDispatch, useSelector} from "react-redux";
import {useInjection} from "inversify-react";
import ApiService from "../../services/apiService";
import {
    MissingLanguageDto,
    ReportingApi,
} from "../../api";
import {CSVLink} from 'react-csv';
import LanguageSelector from "../../components/languageSelector/languageSelector";
import TranslationReportViewContainer from "./translationReportViewContainer";
import AchievementReportViewContainer from "./achievementsReportViewContainer";
import CalendarReportViewContainer from "./calendarReportViewContainer";

export default function ReportsListContainer() {
    const apiService = useInjection(ApiService);
    const brandIdFromState = useSelector(getCurrentBrandId);
    const [reports, setReports] = useState<MissingLanguageDto[][][] | null>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [csvData, setCsvData] = useState<MissingLanguageDto[]>([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filterLanguage, setFilterLanguage] = useState<string | undefined>("en-GB");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshReportsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState, isFiltered, filterLanguage]);

    const refreshReportsList = async () => {
        setIsLoading(true);
        
        try {
            const filterLang = isFiltered
                ? filterLanguage
                : undefined;

            const reports = await apiService.getApi(ReportingApi)
                .apiReportingMissingLocalesGet(filterLang);
            setReports(reports.data);
        } catch (e) {
            console.log(e.response.data);
        }

        setIsLoading(false);
    }

    const onCsvExportClicked = () => {
        setCsvData([])
        reports?.map(reportDetails => (reportDetails as [])
            .map(reportsGroupedById => (reportsGroupedById as MissingLanguageDto[])
                .map(reportsGroupedByType => setCsvData(csvData => [...csvData, reportsGroupedByType]))))
    }

    const onLanguageSelected = async (filterLanguage: string) => {
        setFilterLanguage(filterLanguage);
    };

    const onFilteredChange = async (isFiltered: boolean) => {
        setIsFiltered(isFiltered);
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        View reports
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider
                        selectedItem={RoutingPaths.ReportsList}
                    />
                    <Content className={styles.content}>
                        <ReportsList
                            onViewClick={() => setIsModalVisible(true)}
                        />
                        <Modal
                            visible={isModalVisible}
                            title="Report details"
                            onCancel={() => setIsModalVisible(false)}
                            width={1250}
                            footer={[
                                <Button
                                    key="close"
                                    onClick={() => setIsModalVisible(false)}
                                    type="primary"
                                    title="OK">
                                    Close
                                </Button>
                            ]}>
                            <CSVLink
                                data={csvData}
                                filename='MissingLocales.csv'
                                onClick={onCsvExportClicked}
                            >
                                <Button
                                    className={styles.csvButton}
                                    type="primary"
                                    disabled={reports?.length == 0}
                                >Download CSV</Button>
                            </CSVLink>

                            <div className={styles.langFilter}>
                                <Switch onChange={onFilteredChange} checkedChildren="Filter"
                                        unCheckedChildren="Filter"/>
                                <LanguageSelector onSelect={onLanguageSelected}/>
                            </div>
                            <Spin spinning={isLoading}>
                            {reports !== null
                                ? reports?.length == 0
                                    ? <div>No translations missing.</div>
                                    : reports?.map((report, i) => {
                                            switch (report[0][0].entityType) {
                                                case 'Achievement':
                                                    return <AchievementReportViewContainer
                                                        key={i}
                                                        reportDetails={report as []}
                                                        onReportsRefreshClicked={refreshReportsList}
                                                    />
                                                case 'Translation':
                                                    return <TranslationReportViewContainer
                                                        key={i}
                                                        reportDetails={report as []}
                                                        onReportsRefreshClicked={refreshReportsList}
                                                    />
                                                case 'Calendar':
                                                    return <CalendarReportViewContainer
                                                        key={i}
                                                        reportDetails={report as []}
                                                        onReportsRefreshClicked={refreshReportsList}
                                                    />
                                            }
                                        }
                                    )
                                : null}
                            </Spin>
                        </Modal>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}

