import { Input } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { EndForgotPasswordDto } from '../../../api';
import CrudForm from '../../../components/crudForm/crudForm';
import { CrudFormItemProps, CrudFormWrapperProps } from '../../../components/crudForm/crudFormProps';
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from '../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb';
import { nameof } from '../../../helpers/nameofHelper';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 4 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 4 },
};

const formItems: CrudFormItemProps[] = [{
    name: nameof<EndForgotPasswordDto>("email"),
    children: <Input disabled={true} />
},{
    name: nameof<EndForgotPasswordDto>("newPassword"),
    rules: [{ required: true, message: 'This field is required' }],
    children: <Input.Password />
},{
    label: "Confirm new password",
    name: "confirmNewPassword",
    rules: [{ required: true, message: 'This field is required' },
        ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue(nameof<EndForgotPasswordDto>("newPassword")) === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("Fields do not match!"));
        },
        })],
    children: <Input.Password />
}];

export default function EndForgotPassword(props: CrudFormWrapperProps<EndForgotPasswordDto>) {
    const location = useLocation();
    
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const initValues: EndForgotPasswordDto = {
        token,
        email
    };
    
    return <Layout>
        <CypherCmsBreadcrumb>
            <CypherCmsBreadcrumbItem>
                Set new password
            </CypherCmsBreadcrumbItem>
        </CypherCmsBreadcrumb>
        <CrudForm
            initialValues={initValues}
            onSubmit={props.onSubmit}
            formItems={formItems}
            saveButtonTitle="Reset password"
            formProps={layout}
            tailProps={tailLayout} />
    </Layout>;
}