import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CourseDto } from "../../../../api";
import { setCurrentBrandId } from "../../../../redux/organisationsSlice";
import CourseModalFormAddContainer from "../courseModalFormAddContainer/courseModalFormAddContainer";
import CoursesListItemContainer from "../coursesListItemContainer/coursesListItemContainer";

export default function CoursesList(props: CoursesListProps) {
  const { brandId } = useParams<{ brandId: string }>();
  const brandIdNum = Number.parseInt(brandId);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setCurrentBrandId(brandId));
  }, [brandId, dispatch]);

  const refreshCoursesList = async () => {
    setIsLoading(true);

    await props.refreshCoursesList();

    setIsLoading(false);
  }

  return <>
    <Spin spinning={isLoading}>
      <Row gutter={[24, 24]}>
        {props.courses.map((course, i) =>
        <Col span={4} key={i}>
        <CoursesListItemContainer onCourseItemUpdated={refreshCoursesList} model={course} brandId={brandIdNum} />
        </Col>
        )}
        <Col span={4}>
          <CourseModalFormAddContainer onFinish={refreshCoursesList} />
        </Col>
      </Row>
    </Spin>
  </>;
  }

interface CoursesListProps {
  courses: CourseDto[],
  refreshCoursesList: () => Promise<any>
}
