;
import {AchievementCategoryCreateDto, AchievementCategoryUpdateDto} from "../../../api";
import AchievementCategoryModalForm from "../achievementCategoryModalForm/achievementCategoryModalForm";

export default function AchievementCategoryModalFormAddContainer(props: AchievementCategoryModalFormAddContainerProps) {
    return <>
        <AchievementCategoryModalForm
            isModalVisible={props.addFormVisibility}
            setModalVisibility={props.onFinish}
            onSubmit={props.onSubmit}
            modalTitle={props.title}
            saveButtonTitle="Submit"
            initialValues={props.achievementCategoryToEdit!}
        />
    </>
}

interface AchievementCategoryModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void;
    onSubmit: (values: AchievementCategoryCreateDto | AchievementCategoryUpdateDto) => Promise<any>;
    addFormVisibility: boolean;
    title: string;
    achievementCategoryToEdit: AchievementCategoryUpdateDto | null;
}