import { configureStore } from "@reduxjs/toolkit";
import alertReducer from './alertSlice';
import brandAdminRolesReducer from "./brandAdminRolesSlice";
import coursesReducer from './coursesSlice';
import languageReducer from './languageSlice';
import organisationsReducer from './organisationsSlice';
import sectionsDndReducer from './sectionsDndSlice';
import sectionsReducer from './sectionsSlice';
import userReducer from "./userSlice";
import productLanguageReducer from "./productLanguageSlice";

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        brandAdminRole: brandAdminRolesReducer,
        courses: coursesReducer,
        language: languageReducer,
        organisations: organisationsReducer,
        sections: sectionsReducer,
        sectionsDnd: sectionsDndReducer,
        user: userReducer,
        productLanguage: productLanguageReducer
    }
});
