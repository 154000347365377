import { Button, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LanguageDto, OrganisationUserDto } from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import AllLanguagePicker from "../../components/allLanguagePicker/allLanguagePicker";
import { nameof } from "../../helpers/nameofHelper";
import { useState } from "react";
import _ from "lodash";

export default function UsersList(props: CrudTableWrapperProps<OrganisationUserDto>) {
  const columns: ColumnsType<OrganisationUserDto> = [{
    key: nameof<OrganisationUserDto>("name")
  }, {
    key: nameof<OrganisationUserDto>("email")
  }];

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  let data = props.data;

  let allLanguages = _.uniqWith(data, (first, second) => first.cultureCode?.toLowerCase() === second.cultureCode?.toLowerCase())
                        .filter(u => u.cultureCode)
                        .map(u => ({ locale: u.cultureCode }) as LanguageDto);

  if (selectedLanguage && props.data) {
    data = props.data.filter(u => u.cultureCode?.toLowerCase() === selectedLanguage.toLowerCase());
  }

  return <>
      <CrudTable
          data={data}
          columns={columns}
          customRowActions={record => <Button
            onClick={() => props.onEditClicked!(record.id!)}
          >View</Button>}
          topActions={
            <Space>
              <span>Culture code:</span>
              <AllLanguagePicker customLanguageList={allLanguages} allowEmptyValue={true} onSelect={locale => setSelectedLanguage(locale)} />
            </Space>}
      />
  </>;
}
