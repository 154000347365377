import { Form, Input } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import { humanize } from "../../helpers/stringHelpers";
import { CrudFormItemProps, CrudFormItemsWrapperProps } from "./crudFormProps";

function elementSupportsRef(element: React.ReactElement<any>) { // TODO: add support for class components
    return (element.type as any).$$typeof?.toString() === "Symbol(react.forward_ref)";
}

export const CrudFormItemsWrapper = forwardRef((props: CrudFormItemsWrapperProps, ref: React.ForwardedRef<any>) => {
    const [childrenRefs, setChildrenRefs] = useState<any>({});

    useImperativeHandle(ref, () => ({
      async onFormFinish() {
        for (const [, value] of Object.entries(childrenRefs)) {
            const currentAny = ((value as any)?.current as any);
            if (!currentAny?.onFormFinish) {
                continue;
            }

            const error = await currentAny!.onFormFinish();

            if (error) {
                return error;
            }            
        }
      }
    }));
    
    const getChildrenWithRefIfPossible = (formItem: CrudFormItemProps) => {
        let children = formItem.children;

        if (children && elementSupportsRef(children)) { 
            const props = { ...children.props };

            props.ref = childrenRefs[formItem.name];

            children = React.cloneElement(children, props);
        }

        return children;
    };

    const mapFormItems = (props: CrudFormItemProps[]) => {
        let addedChildrenRef = false;

        const mappedProps = props.map((p, i) => {
            if (!childrenRefs[p.name]) {
                childrenRefs[p.name] = React.createRef();
                addedChildrenRef = true;
            }

            const children = getChildrenWithRefIfPossible(p);

            return <Form.Item key={i} label={p.label || humanize(p.name)} name={p.name} rules={p.rules}>
                {children || <Input />}
            </Form.Item>
        });

        if (addedChildrenRef) {
            setChildrenRefs(childrenRefs);
        }

        return mappedProps;
    }

    return <>{mapFormItems(props.formItems)}</>;
});