import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, generatePath, useHistory } from "react-router-dom";
import {
    ProductApi,
    ProductAvailabilityLanguageApi,
    ProductAvailabilityLanguageDto,
    ProductDetailsDto,
    ProductDto
} from "../../api";
import AsyncSwitch from "../../components/asyncSwitch/asyncSwitch";
import BrandSider from "../../components/brandSider/brandSider";
import { CrudFormItemProps } from "../../components/crudForm/crudFormProps";
import CrudFormModal from "../../components/crudFormModal/crudFormModal";
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import { nameof } from "../../helpers/nameofHelper";
import { getCurrentBrandId, setCurrentBrandId } from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import { RoutingPaths } from "../routingContainer";
import ProductsList from "./productsList";
import styles from "./productsListContainer.module.scss";
import EntityTransfer from "../../components/entityTransfer/entityTransfer";
import {getProductLanguages} from "../../redux/productLanguageSlice";
import { getGlobalLanguage } from "../../redux/languageSlice";

export default function ProductsListContainer() {
    const { brandId } = useParams<{ brandId: string }>();
    const apiService = useInjection(ApiService);
    const brandIdFromState = useSelector(getCurrentBrandId);
    const history = useHistory();
    const productLanguages = useSelector(getProductLanguages);
    const defaultLanguage = useSelector(getGlobalLanguage);

    const [products, setProducts] = useState<ProductDto[] | null>();
    const [modalVisibility, setModalVisibility] = useState(false);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshProductsList();
    }, [brandIdFromState]);

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<ProductDto>("productId"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
        },
        {
            name: nameof<ProductDto>("workstationSlug")
        },
        {
            name: nameof<ProductDto>("isNew"),
            children: <AsyncSwitch />
        },
        {
            name: nameof<ProductDto>("isAchievable"),
            children: <AsyncSwitch />
        },
        {
            name: nameof<ProductDetailsDto>("productAvailabilityLanguagesIds"),
            label: "Available to:",
            children:
                <EntityTransfer
                    dataSource={productLanguages as []}
                    titles={["Available", "Assigned"]}
                    elementTitlePropertyName={nameof<ProductAvailabilityLanguageDto>("locale")}
                />
        }
    ];

    const refreshProductsList = async () => {
        try {
            const response = await apiService.getApi(ProductApi).apiProductGet({ headers: { Language: defaultLanguage } });
            setProducts(response.data);
        } catch (e: any) {
            console.log(e.response.data);
        }
    }

    const onSubmit = async (value: any) => {
        await apiService.getApi(ProductApi).apiProductPost({
            productId: value.productId,
            isNew: value.isNew,
            isAchievable: value.isAchievable,
            workstationSlug: value.workstationSlug,
            productAvailabilityLanguagesIds: value.productAvailabilityLanguagesIds
        });

        setModalVisibility(false);
        refreshProductsList();
    };

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(ProductApi).apiProductIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    // TODO: Add some warning?
                    console.log("Error occured while deleting data");
                } else {
                    refreshProductsList();
                }
            })
    }

    const onEditClicked = (id: number) => {
        history.push(generatePath(RoutingPaths.ProductEdit, {brandId: brandId, productId: id}));
    }

    return <>
        <Layout style={{ backgroundColor: "white" }}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Products
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.ProductsList} />
                    <Content className={styles.content}>
                        <ProductsList
                            data={products || null}
                            onAddClicked={() => setModalVisibility(true)}
                            onDeleteClicked={onDeleteClicked}
                            onEditClicked={onEditClicked}
                        />
                        <CrudFormModal
                            formItems={formItems}
                            onSubmit={onSubmit}
                            onModalCancelClicked={() => setModalVisibility(false)}
                            modalOkText={"Add"}
                            modalTitle={"New Product"}
                            isModalVisible={modalVisibility}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
