import {Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {BrandTermApi, BrandTermCreateDto, BrandTermDto, BrandTermUpdateDto} from "../../api";
import BrandSider from "../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import ApiService from "../../services/apiService";
import {RoutingPaths} from "../routingContainer";
import BrandTermModalFormAddContainer from "./brandTermModalFormAddContainer/brandTermModalAddContainer";
import BrandTermsList from "./brandTermsList";
import styles from "./brandTermsListContainer.module.scss";

export default function BrandTermsListContainer() {
    const apiService = useInjection(ApiService);
    const brandIdFromState = useSelector(getCurrentBrandId);
    const [brandTerms, setBrandTerms] = useState<BrandTermDto[] | null>();
    const [isAddFormVisible, setAddFormVisible] = useState(false);
    const [brandTermToEdit, setBrandTermToEdit] = useState<BrandTermUpdateDto | null>(null);
    const [brandTermModalFormContainerTitle, setBrandTermModalFormContainerTitle] = useState<string>();
    const [idBrandTermToEdit, setIdBrandTermToEdit] = useState<number | null>(null);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshBrandTermsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshBrandTermsList = async () => {
        try {
            const brandTerms = await apiService.getApi(BrandTermApi).apiBrandTermGet();
            setBrandTerms(brandTerms.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = async () => {
        setBrandTermModalFormContainerTitle("Add new brand term");
        setBrandTermToEdit(null);
        setIdBrandTermToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = async (id: number) => {
        setBrandTermModalFormContainerTitle(`Edit brand term with id: ${id}`);

        await apiService.getApi(BrandTermApi).apiBrandTermIdGet(id)
            .then(response => {
                setBrandTermToEdit(response.data as BrandTermUpdateDto);
            });

        setIdBrandTermToEdit(id);
        setAddFormVisible(true);
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(BrandTermApi).apiBrandTermIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log("Error occured while deleting brand term");
                } else {
                    refreshBrandTermsList()
                }
            })
    }

    const onSubmit = async (values: BrandTermCreateDto | BrandTermUpdateDto) => {
        try {
            if (brandTermToEdit === null) {
                await apiService.getApi(BrandTermApi).apiBrandTermPost(values);
            } else if (idBrandTermToEdit !== null) {
                await apiService.getApi(BrandTermApi).apiBrandTermIdPut(idBrandTermToEdit, values)
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false);
        await refreshBrandTermsList();
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Brand terms
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.BrandTermsList}/>
                    <Content className={styles.content}>
                        <BrandTermsList
                            data={brandTerms || null}
                            onAddClicked={onAddClicked}
                            onEditClicked={onEditClicked}
                            onDeleteClicked={onDeleteClicked}
                        />
                        <BrandTermModalFormAddContainer
                            onFinish={() => setAddFormVisible(false)}
                            onSubmit={onSubmit}
                            addFormVisibility={isAddFormVisible}
                            title={brandTermModalFormContainerTitle!}
                            brandTermToEdit={brandTermToEdit}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}