import { Select } from "antd";
import { useSelector } from "react-redux";
import { LanguageDto } from "../../api";
import { getAllLanguages } from "../../redux/languageSlice";

const { Option } = Select;

export type Language = string;

export default function LanguagePicker(props: LanguagePickerProps) {
    const allLanguages = useSelector(getAllLanguages);

    let languages = props.customLanguageList ? [...props.customLanguageList] : [...allLanguages];

    if (props.allowEmptyValue) {
        languages.unshift({
            locale: null
        });
    }

    return <Select
        bordered={props.bordered ?? true}
        disabled={props.loading || props.disabled}
        loading={props.loading}
        dropdownMatchSelectWidth={false}
        defaultValue={languages[0]?.locale || "All"}
        onSelect={props.onSelect}
        >
        {languages.map(l => <Option key={l.locale!} value={l.locale!}>{l.locale || "All"}</Option>)}
    </Select>;
}

interface LanguagePickerProps {
    onSelect: (value: string) => void,
    disabled?: boolean,
    loading?: boolean,
    bordered?: boolean,
    allowEmptyValue?: boolean,
    customLanguageList?: LanguageDto[]
}
