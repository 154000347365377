import React from "react";
import {BrandTermCreateDto, BrandTermUpdateDto } from "../../../api";
import BrandTermModalForm from "../brandTermModalForm/brandTermModalForm";

export default function BrandTermModalFormAddContainer(props: BrandTermModalFormAddContainerProps) {
    return <>
        <BrandTermModalForm
            isModalVisible={props.addFormVisibility}
            setModalVisibility={props.onFinish}
            onSubmit={props.onSubmit}
            modalTitle={props.title}
            saveButtonTitle="Submit"
            initialValues={props.brandTermToEdit!}
        />
    </>
}

interface BrandTermModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void,
    onSubmit: (values: BrandTermCreateDto | BrandTermUpdateDto) => Promise<any>,
    addFormVisibility: boolean,
    title: string,
    brandTermToEdit: BrandTermUpdateDto | null
}