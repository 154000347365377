import { Select } from "antd";
import SkeletonInput from "antd/lib/skeleton/Input";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllLanguages, getFilteredLanguages, getGlobalLanguage, setFilteredLanguages, setGlobalLanguage} from "../../redux/languageSlice";
import { getCurrentBrandId } from "../../redux/organisationsSlice";
import { getUser } from "../../redux/userSlice";
import styles from './languageSelector.module.scss';

const { Option } = Select;

export type Language = string;

export default function LanguageSelector(props: LanguageSelectorProps) {
    const defaultLanguage = useSelector(getGlobalLanguage);
    const allLanguages = useSelector(getAllLanguages);
    const filteredLanguages = useSelector(getFilteredLanguages);
    const user = useSelector(getUser);
    const brandId = useSelector(getCurrentBrandId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allLanguages || !brandId || !user) {
            return;
        }

        const availableLocalesIds = user.organisationUsers?.find(u => u.organisationId == brandId)?.availableLocalesIds;

        if (availableLocalesIds) {
            let filteredLanguages = allLanguages.filter(l => availableLocalesIds.some(a => a == l.id));
            dispatch(setFilteredLanguages(filteredLanguages));

            if (!filteredLanguages.some(l => l.locale == defaultLanguage)) {
                dispatch(setGlobalLanguage(filteredLanguages[0]?.locale));
            }  
        }      
    }, [user, brandId, defaultLanguage, allLanguages]);

    if (!filteredLanguages || (brandId && !user)) {
        return <Select
            className={styles.languageSelector}
            disabled={true}
            loading={true} />;
    }

    return <Select
        className={styles.languageSelector}
        key={defaultLanguage}
        bordered={props.bordered ?? true}
        disabled={props.loading || props.disabled}
        loading={props.loading}
        defaultValue={defaultLanguage || filteredLanguages[0]?.locale || ""}
        onSelect={props.onSelect}
        >
        {filteredLanguages.map(l => <Option key={l.locale!} value={l.locale!}>{l.locale}</Option>)}
    </Select>;
}

interface LanguageSelectorProps {
    onSelect: (value: string) => void,
    disabled?: boolean,
    loading?: boolean,
    bordered?: boolean
}
