import React from "react";
import { CalendarCreateDto, CalendarUpdateDto } from "../../../api";
import { CrudFormItemProps } from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import { nameof } from "../../../helpers/nameofHelper";
import { DatePicker } from "antd";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";

const formItems: CrudFormItemProps[] = [
    {
        name: nameof<CalendarCreateDto>("title"),
        children: <LocalizableInput/>
    },
    {
        name: nameof<CalendarCreateDto>("description"),
        children: <LocalizableInput/>
    },
    {
        name: nameof<CalendarCreateDto>("date"),
        children: <DatePicker showTime />,
    },
    {
        name: nameof<CalendarCreateDto>("ctaUrl"),
        children: <LocalizableInput/>
    },
    {
        name: nameof<CalendarCreateDto>("ctaTitle"),
        children: <LocalizableInput/>
    },
    {
        name: nameof<CalendarCreateDto>("ctaOpensNewTab"),
        label: "New tab",
        children: <AsyncSwitch />
    }
];

export default function CalendarModalForm(props: CalendarModalProps) {
    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface CalendarModalProps {
    initialValues?: CalendarCreateDto | CalendarUpdateDto,
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: CalendarCreateDto | CalendarUpdateDto) => Promise<any>,
    modalTitle: string,
    saveButtonTitle: string,
}
