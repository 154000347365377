import {DatePicker, Select} from "antd";
import {EventCreateDto, EventUpdateDto, ParentEventDto} from "../../../api";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import LocalizableReadonly from "../../../components/localizableReadonly/localizableReadonly";
import {nameof} from "../../../helpers/nameofHelper";

const {Option} = Select;

export default function EventModalForm(props: EventModalProps) {

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<EventCreateDto>('name'),
            children: <LocalizableInput/>
        },
        {
            name: nameof<EventCreateDto>('startDate'),
            children: <DatePicker/>,
            rules: [
                {
                    required: true, message: 'Start date required'
                }
            ]
        },
        {
            name: nameof<EventCreateDto>('endDate'),
            children: <DatePicker/>,
            rules: [
                {
                    required: true, message: 'End date required'
                }
            ]
        },
        {
            name: nameof<EventCreateDto>('parentEventId'),
            label: 'Parent event',
            children: <Select loading={!props.parentEvents}>
                {props.parentEvents?.map(pe =>
                    <Option key={pe.id!} value={pe.id!}>
                        <LocalizableReadonly userInputDisabled={true} value={pe.name || undefined} />
                    </Option>
                )}
            </Select>,
            rules: [
                {
                    required: true,
                    message: "Parent event has to be assigned"
                }
            ]
        }, {
            name: nameof<EventCreateDto>('pixelStreamUrl')
        }, {
            name: nameof<EventCreateDto>('mpVideo'),
            children: <LocalizableInput/>
        }
    ]

    return <CrudFormModal
        width={600}
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface EventModalProps {
    initialValues?: EventCreateDto | EventUpdateDto;
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: EventCreateDto | EventUpdateDto) => Promise<any>;
    modalTitle: string;
    saveButtonTitle: string;
    parentEvents: ParentEventDto[] | null;
}