import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ApiService from "../../services/apiService";
import {TranslationApi, TranslationDto, TranslationCreateDto, TranslationUpdateDto} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentBrandId, setCurrentBrandId} from "../../redux/organisationsSlice";
import TranslationsList from "./translationsList";
import TranslationModalFormAddContainer from "./translationModalFormAddContainer/translationModalFormAddContainer";
import {Layout} from "antd";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {Content} from "antd/lib/layout/layout";
import styles from "./translationsListContainer.module.scss";
import { RoutingPaths } from "../routingContainer";
import BrandSider from "../../components/brandSider/brandSider";

export default function TranslationsListContainer() {
    const apiService = useInjection(ApiService);
    const [translations, setTranslations] = useState<TranslationDto[] | null>();
    const [isAddFormVisible, setAddFormVisible] = useState(false);
    const [translationModalFormContainerTitle, setTranslationModalFormContainerTitle] = useState<string>();
    const [translationToEdit, setTranslationToEdit] = useState<TranslationUpdateDto | null>(null);
    const [idTranslationToEdit, setIdTranslationToEdit] = useState<number | null>(null);
    const brandIdFromState = useSelector(getCurrentBrandId);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshTranslationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshTranslationsList = async () => {
        try {
            const translations = await apiService.getApi(TranslationApi).apiTranslationGet();
            setTranslations(translations.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    const onAddClicked = () => {
        setTranslationModalFormContainerTitle("Add new translation");
        setTranslationToEdit(null);
        setIdTranslationToEdit(null);
        setAddFormVisible(true);
    }

    const onEditClicked = async (id: number) => {
        setTranslationModalFormContainerTitle(`Edit translation with id: ${id}`);

        await apiService.getApi(TranslationApi).apiTranslationIdGet(id)
            .then(resp => {
                setTranslationToEdit(resp.data as TranslationUpdateDto);
            });

        setIdTranslationToEdit(id);
        setAddFormVisible(true);
    }

    const onDeleteClicked = async (id: number) => {
        await apiService.getApi(TranslationApi).apiTranslationIdDelete(id)
            .then(response => {
                if (response.request.status !== 200) {
                    console.log("Error occured while deleting data"); //add some warning?
                } else {
                    refreshTranslationsList();
                }
            })
    }

    const onSubmit = async (values: TranslationCreateDto | TranslationUpdateDto) => {
        try {
            if (translationToEdit === null) {
                await apiService.getApi(TranslationApi).apiTranslationPost(values);
            } else if (idTranslationToEdit !== null) {
                await apiService.getApi(TranslationApi).apiTranslationIdPut(idTranslationToEdit, values)
            }
        } catch (e) {
            return e.response.data;
        }

        setAddFormVisible(false)
        refreshTranslationsList();
    }

    return <>
        <Layout style={{ backgroundColor: "white" }}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Manage translations
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.TranslationList} />
                    <Content className={styles.content}>
                        <TranslationsList
                            data={translations || null}
                            onAddClicked={onAddClicked}
                            onEditClicked={onEditClicked}
                            onDeleteClicked={onDeleteClicked}
                        />
                        <TranslationModalFormAddContainer
                            onFinish={() => setAddFormVisible(false)}
                            onSubmit={onSubmit}
                            addFormVisibility={isAddFormVisible}
                            title={translationModalFormContainerTitle || ""}
                            translationToEdit={translationToEdit}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
