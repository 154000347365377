import { ModuleCreateDto } from "../../../../api";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../../components/localizableInput/localizableInput";
import { nameof } from "../../../../helpers/nameofHelper";
import LocalizableFileUploader from "../../../../components/localizableFileUploader/localizableFileUploader";
import { Input } from "antd";

const formItems: CrudFormItemProps[] = [
  {
    name: nameof<ModuleCreateDto>("name"),
    rules: [
      { required: true, message: 'This field is required' }
    ],
    children: <LocalizableInput />
  },
  {
    name: nameof<ModuleCreateDto>("image"),
    label: "Image",
    children: <LocalizableFileUploader fileUploaderProps={{useOrganisationFileApi: true}}/>
  },
  {
    name: nameof<ModuleCreateDto>("slug"),
    label: "Slug",
    children: <Input />
  }
];

export default function ModuleModalForm(props: ModuleModalProps) {
  return <CrudFormModal
    width={800}
    formItems={formItems}
    onSubmit={props.onSubmit}
    onModalCancelClicked={() => props.setModalVisibility(false)}
    initialValues={props.initialValues}
    modalOkText={props.saveButtonTitle}
    modalTitle={props.modalTitle}
    isModalVisible={props.isModalVisible}
  />;
}

interface ModuleModalProps {
  initialValues?: ModuleCreateDto,
  isModalVisible: boolean;
  setModalVisibility: (isVisible: boolean) => any;
  onSubmit: (value: ModuleCreateDto) => Promise<any>,
  modalTitle: string,
  saveButtonTitle: string
}
