import ReportsView from "./reportsView";
import React, { useState } from "react";
import { CalendarApi, CalendarUpdateDto, MissingLanguageDto } from "../../api";
import { useInjection } from "inversify-react";
import ApiService from "../../services/apiService";
import CalendarModalFormAddContainer from "../calendars/calendarModalFormAddContainer/calendarModalFormAddContainer";

export default function CalendarReportViewContainer(props: CalendarReportViewContainerProps) {
    const [isAddCalendarFormVisible, setAddCalendarFormVisible] = useState(false);
    const [idCalendarToEdit, setIdCalendarToEdit] = useState<number | null>(null);
    const [CalendarModalFormContainerTitle, setCalendarModalFormContainerTitle] = useState<string>();
    const [CalendarToEdit, setCalendarToEdit] = useState<CalendarUpdateDto | null>(null);

    const apiService = useInjection(ApiService);

    const onSubmit = async (values: any) => {
        await apiService.getApi(CalendarApi).apiCalendarIdPut(idCalendarToEdit!, values);
        setAddCalendarFormVisible(false);
        props.onReportsRefreshClicked();
    }

    const onEditClicked = async (entityId: number, type: any) => {
        setCalendarModalFormContainerTitle(`Edit Calendar with id: ${entityId}`);
        setIdCalendarToEdit(entityId);

        const resp = await apiService.getApi(CalendarApi).apiCalendarIdGet(entityId)
        setCalendarToEdit(resp.data as CalendarUpdateDto);

        setAddCalendarFormVisible(true);
    }

    return <>
        <CalendarModalFormAddContainer
            onFinish={() => setAddCalendarFormVisible(false)}
            onSubmit={onSubmit}
            addFormVisibility={isAddCalendarFormVisible}
            title={CalendarModalFormContainerTitle || ""}
            calendarToEdit={CalendarToEdit}
        />

        <ReportsView
            reportDetails={props.reportDetails}
            onReportsRefreshClicked={props.onReportsRefreshClicked}
            onEditClicked={onEditClicked}
        />
    </>
}

interface CalendarReportViewContainerProps {
    reportDetails: MissingLanguageDto[][],
    onReportsRefreshClicked: () => any,
}