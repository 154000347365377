import React, { ReactNode } from "react";
import styles from "../elementPickerItem.module.scss";

export default function SplittedElementPickerItemTile(props: SplittedElementPickerItemTileProps) {

    return <>
        <div className={styles.container}>
            <div className={styles.iconWrapper}>
                {props.icon}
            </div>
        </div>
    </>;
}

export interface SplittedElementPickerItemTileProps {
    icon: ReactNode;
}