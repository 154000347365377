import React from "react";
import {TranslationCreateDto, TranslationUpdateDto} from "../../../api";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import {nameof} from "../../../helpers/nameofHelper";

const formItems: CrudFormItemProps[] = [
    {
        name: nameof<TranslationCreateDto>("key"),
        rules: [
            {required: true, message: 'This field is required'}
        ],
    },
    {
        name: nameof<TranslationCreateDto>("value"),
        children: <LocalizableInput/>
    }
];

export default function TranslationModalForm(props: TranslationModalProps) {

    return <CrudFormModal
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface TranslationModalProps {
    initialValues?: TranslationCreateDto | TranslationUpdateDto,
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: TranslationCreateDto | TranslationUpdateDto) => Promise<any>,
    modalTitle: string,
    saveButtonTitle: string,
}