import { Select } from "antd";
import moment from "moment";
import { AchievementCategoryDto, AchievementDetailsDto, AchievementUpdateDto } from "../../../api";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";
import CrudForm from "../../../components/crudForm/crudForm";
import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import SingleFileUploader from "../../../components/fileUploader/singleFileUploader";
import LocalizableInput from "../../../components/localizableInput/localizableInput";
import LocalizableReadonly from "../../../components/localizableReadonly/localizableReadonly";
import Mapper from "../../../helpers/mapper";
import {nameof} from "../../../helpers/nameofHelper";

const layout = {
    labelCol: {xxl:{span:3},xl:{span:4},lg:{span:5},md:{span:7}},
    wrapperCol: {span: 12},
};

const tailLayout = {
    wrapperCol: {offset: 1},
};

const {Option} = Select;

export default function AchievementForm(props: AchievementFormProps) {
    const initValues = {
        "name": props.achievementToEdit!.name,
        "description": props.achievementToEdit!.description,
        "eventType": props.achievementToEdit!.eventType,
        "rewardPoints": props.achievementToEdit!.rewardPoints,
        "achievementCategoryId": props.achievementToEdit?.achievementCategoryId,
        "occurrencesRequired": props.achievementToEdit?.occurrencesRequired,
        "badgeFileId": props.achievementToEdit?.badgeFileId,
        "badgeFile": props.achievementToEdit?.badgeFile,
        "isDistinct": props.achievementToEdit?.isDistinct,
        "isVisible": props.achievementToEdit ? props.achievementToEdit.isVisible : true
    }
    
    const formItems: CrudFormItemProps[] = [
        {
            label: "Name",
            name: nameof<AchievementDetailsDto>('name'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Name has to be assigned"
                }
            ]
        },
        {
            label: "Description",
            name: nameof<AchievementDetailsDto>('description'),
            children: <LocalizableInput/>,
            rules: [
                {
                    required: true,
                    message: "Description has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementDetailsDto>('eventType'),
            rules: [
                {
                    required: true,
                    message: "Event Type has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementDetailsDto>('rewardPoints'),
            rules: [
                {
                    required: true,
                    message: "Reward Points has to be assigned"
                }
            ]
        }, {
            name: nameof<AchievementDetailsDto>('occurrencesRequired'),
            rules: [
                {
                    required: true,
                    message: "Occurences Required has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementDetailsDto>("badgeFileId"),
            label: "Badge Image",
            children: <SingleFileUploader
                accept="image/jpeg,image/png"
                sizeLimitMb={2}
                defaultFile = {
                    initValues.badgeFile
                        ? Mapper.FileInfoDto_UploadFile(initValues.badgeFile)
                        : undefined
                }
                useOrganisationFileApi={true}
                isFilePublic={true}
            />,
            rules: [
                {
                    required: true,
                    message: "Badge Image has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementDetailsDto>('achievementCategoryId'),
            label: 'Achievement Category',
            children: <Select loading={!props.achievementCategories}>
                {props.achievementCategories?.map(pe =>
                    <Option key={pe.id!} value={pe.id!}>
                        <LocalizableReadonly userInputDisabled={true} value={pe.name || undefined} />
                    </Option>
                )}
            </Select>,
            rules: [
                {
                    required: true,
                    message: "Achievement category has to be assigned"
                }
            ]
        },
        {
            name: nameof<AchievementDetailsDto>('isDistinct'),
            label: 'Distinct',
            children: <AsyncSwitch />
        },
        {
            name: nameof<AchievementDetailsDto>('isVisible'),
            label: 'Visible',
            children: <AsyncSwitch />
        }
    ];

    return <CrudForm
        formItems={formItems}
        formProps={layout}
        tailProps={tailLayout}
        saveButtonTitle={"Submit"}
        initialValues={initValues}
        onSubmit={props.onSubmit}
    />;
}

interface AchievementFormProps {
    onSubmit: (name: AchievementDetailsDto) => Promise<any>,
    achievementToEdit: AchievementUpdateDto | null,
    achievementCategories: AchievementCategoryDto[] | null
}
