import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {AchievementDto, AdminRole, BrandAdminRoleDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import { nameof } from "../../helpers/nameofHelper";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {useSelector} from "react-redux";

export default function AchievementsList(props: CrudTableWrapperProps<AchievementDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []
    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<AchievementDto> = [{
        key: nameof<AchievementDto>("name"),
        render: (names) => <LocalizableReadonly value={names} />
    }, {
        key: nameof<AchievementDto>("eventType"),
        render: (eventType: string) => {
            return <>{eventType}</>
        }
    }, {
        key: nameof<AchievementDto>("occurrencesRequired"),
        render: (occurrencesRequired: string) => {
            return <>{occurrencesRequired}</>
        }
    }, {
        key: nameof<AchievementDto>("rewardPoints"),
        render: (rewardPoints: string) => {
            return <>{rewardPoints}</>
        }
    }];

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle="+ Add new achievement"
        />
    </>
}
