import { ColumnsType } from "antd/lib/table";
import React from "react";
import {AdminRole, BrandAdminRoleDto, BrandTermDto, LocalizableStringDto} from "../../api";
import CrudTable, { CrudTableWrapperProps } from "../../components/crudTable/crudTable";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import { nameof } from "../../helpers/nameofHelper";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {useSelector} from "react-redux";

const columns: ColumnsType<BrandTermDto> = [{
    key: nameof<BrandTermDto>('name'),
    dataIndex: nameof<BrandTermDto>('name'),
    title: "Name",
    render: (names: Array<LocalizableStringDto> | null) => {
        if (!names) {
            return null;
        }
        return <>
            <LocalizableReadonly
                value={names}
            />
        </>
    }
}]

export default function BrandTermsList(props: CrudTableWrapperProps<BrandTermDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle={"+ Add new brand term"}
        />
    </>
}