import { UploadFile } from "antd/lib/upload/interface";
import { generatePath } from "react-router-dom";
import { FileInfoDto, OrganisationDto } from "../api";
import { CrudSiderItemProps } from "../pages/organisations/components/brandsMenuSider/CrudSiderItemProps";
import { RoutingPaths } from "../pages/routingContainer";

export default class Mapper {

    static OrganisationDto_CrudSiderItemProps(
        item: OrganisationDto
    ): CrudSiderItemProps {
        return {
            key: generatePath(RoutingPaths.OrganisationEdit, { id: item.id! }),
            title: item.name,
            iconLetter: item.name[0],
            iconLetterColor: item.primaryColor || ''
        }
    }

    static FileInfoDto_UploadFile(
        item: FileInfoDto
    ): UploadFile {
        return {
            uid: item.id!.toString(),
            name: item.name || '',
            originFileObj: (item as any).originFileObj   // workaround for uploading FileInfoDto
        }
    }

    static UploadFile_FileInfoDto(
        item: UploadFile
    ): FileInfoDto {
        return {
            id: +item.uid,
            name: item.name,
            originFileObj: item.originFileObj   // workaround for uploading FileInfoDto
        } as FileInfoDto;
    }
}
