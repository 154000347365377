import {CrudFormItemProps} from "../../../components/crudForm/crudFormProps";
import CrudForm from "../../../components/crudForm/crudForm";
import {nameof} from "../../../helpers/nameofHelper";
import AsyncSwitch from "../../../components/asyncSwitch/asyncSwitch";
import {Input, Select} from "antd";
import { ExternalAuthorizationApi, KenticoRegisterDto } from "../../../api";
import ApiService from "../../../services/apiService";
import { useInjection } from "inversify-react";
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from "react";

const {Option} = Select;

export default function KenticoRegistrationForm(props: KenticoRegistrationFormProps) {

    const [captchaToken, setCaptchaToken] = useState<string | null>();
    const apiService = useInjection(ApiService);
    
    const onSubmit = async (value: KenticoRegisterDto) => {
        value.organisationId = 1;

        value.captchaToken = captchaToken!; // missing or wrong captcha token should trigger server side error so not checking it here at all

        try {
            await apiService.getApi(ExternalAuthorizationApi).apiExternalAuthorizationRegisterPost(value);
        } catch (e) {
            return e.response.data;
        }
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 4 },
    };

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<KenticoRegisterDto>("userType"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
            children: <Select>
                <Option value="1">JSS</Option>
                <Option value="2">Customer</Option>
                <Option value="3">Business</Option>
                <Option value="4">End User</Option>
                <Option value="5">Staff</Option>
                <Option value="6">Store</Option>
            </Select>
        },
        {
            name: nameof<KenticoRegisterDto>("isProspect"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
            children: <AsyncSwitch/>
        },
        {
            name: nameof<KenticoRegisterDto>("title"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
            children: <Select>
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Mrs</Option>
                <Option value="Ms">Ms</Option>
                <Option value="Miss">Miss</Option>
                <Option value="Unspecified">Unspecified</Option>
            </Select>
        },
        {
            name: nameof<KenticoRegisterDto>("firstName"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("lastName"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("emailAddress"),
            rules: [
                {required: true, message: 'This field is required'},
                {type: 'email', message: 'Email format is incorrect'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("dialingCode"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("phoneNumber"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("languageCode")
        },
        {
            name: nameof<KenticoRegisterDto>("countryCode")
        },
        {
            name: nameof<KenticoRegisterDto>("trade")
        },
        {
            name: nameof<KenticoRegisterDto>("postcode")
        },
        {
            name: nameof<KenticoRegisterDto>("numberOfEmployees")
        },
        {
            name: nameof<KenticoRegisterDto>("spendOnTools")
        },
        {
            name: nameof<KenticoRegisterDto>("company")
        },
        {
            name: nameof<KenticoRegisterDto>("jobTitle"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("contactName"),
            rules: [
                {required: true, message: 'This field is required'}
            ]
        },
        {
            name: nameof<KenticoRegisterDto>("hasJssRepresentative")
        },
        {
            name: nameof<KenticoRegisterDto>("password"),
            rules: [
                {required: true, message: 'This field is required'},
            ],
            children: <Input.Password/>
        },
        {
            name: nameof<KenticoRegisterDto>("confirmPassword"),
            rules: [
                {required: true, message: 'Please confirm your password'},
                ({getFieldValue}) => ({
                    validator(_, value) {
                        if (!value || getFieldValue(nameof<KenticoRegisterDto>('password')) === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("Fields do not match!"));
                    }
                })
            ],
            children: <Input.Password/>
        },
        {
            name: nameof<KenticoRegisterDto>("acceptedMarketing"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
            children: <AsyncSwitch/>
        },
        {
            name: nameof<KenticoRegisterDto>("acceptedHubMarketing"),
            rules: [
                {required: true, message: 'This field is required'}
            ],
            children: <AsyncSwitch/>
        },
    ];

    return <CrudForm
        formItems={formItems}
        onSubmit={onSubmit}
        formProps={layout}
        tailProps={tailLayout}
        extraActions={
        <ReCAPTCHA
            sitekey="6LdjPIgeAAAAANPrviBTrxKZlZInw4JxXv80YFDK"
            onChange={value => setCaptchaToken(value)}
        />}
        saveButtonTitle="Register"
    />
}

interface KenticoRegistrationFormProps {
}
