import { createSlice } from "@reduxjs/toolkit";
import { ProductAvailabilityLanguageDto } from "../api";

const initialState: {
    productLanguages: ProductAvailabilityLanguageDto[] | null,
} = {
    productLanguages: null,
};

const productAvailabilityLanguageSlice = createSlice({
    name: "productAvailabilityLanguageSlice",
    initialState,
    reducers: {
        setProductLanguages: (state, action) => {
            state.productLanguages = action.payload;
        },
    }
});

export const { setProductLanguages } = productAvailabilityLanguageSlice.actions;

export const getProductLanguages: (x: any) => ProductAvailabilityLanguageDto[] = (state: any) =>
    state.productLanguage.productLanguages;

export default productAvailabilityLanguageSlice.reducer;
