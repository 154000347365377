import { DragEndEvent } from "@dnd-kit/core";
import { injectable, Container } from "inversify";
import { arrayMove } from "react-sortable-hoc";
import "reflect-metadata";
import { OrderDto } from "../../../api";
import { setOrderedSectionItems, setOrderedSections } from "../../../redux/sectionsDndSlice";
import { store } from "../../../redux/store";
import { DndSectionItemType } from "./picker-items/DndSectionItemType";
import { PickerDndObject } from "./picker-items/PickerDndObject";
import { SectionDndObject } from "./picker-items/SectionDndObject";
import { SectionItemDndObject } from "./picker-items/SectionItemDndObject";
import { UnifiedDndObject } from "./picker-items/UnifiedDndObject";
import SectionDndServiceApiOperations from "./sectionDndServiceApiOperations";

@injectable()
export default class SectionDndService {
    private readonly _apiOperations: SectionDndServiceApiOperations;
    constructor(
    ) {
        const container = new Container(); // because, proposed way didn't worked => @inject(ApiService) apiService: ApiService
        // this._apiService = container.resolve(ApiService);
        this._apiOperations = container.resolve(SectionDndServiceApiOperations);
    }

    public getSectionSortableId(): string {
        return 'section-sortable';
    }

    public getSectionItemsSortableId(sectionId: number): string {
        return `section-${sectionId}-items-sortable`;
    }

    public async handleOnDragEnd(event: DragEndEvent) {
        let activeObj = JSON.parse(event.active.id) as UnifiedDndObject;

        switch (activeObj.typeDisclaimer) {
            case DndSectionItemType.Section:
                await this.handleSectionDrag(event, activeObj as SectionDndObject);
                break;
            case DndSectionItemType.SectionItem:
                await this.handleSectionItemDragEnd(event, activeObj as SectionItemDndObject);
                break;
            case DndSectionItemType.PickerElement:
                await this.handlePickerElementDragEnd(event, activeObj as PickerDndObject);
                break;
            default:
                throw Error(`Can't handle given disclaimer: ${activeObj.typeDisclaimer}`);
        }
    }

    private async handlePickerElementDragEnd(
        event: DragEndEvent,
        activeObj: PickerDndObject
    ) {
        const overObj = event.over && JSON.parse(event.over!.id) as UnifiedDndObject;

        let order: number | undefined;
        let sectionId: number;
        if (overObj?.typeDisclaimer === DndSectionItemType.SectionItem) {
            let sectionItems = store.getState().sectionsDnd.orderedSectionItems![overObj.sectionId!];

            let overItemWithOrder: number | undefined;

            if (overObj!.sectionItemId == 0)
            {
                const maxOrder = Math.max(...sectionItems.map(e => e.order!));

                if (maxOrder === -Infinity) {
                    overItemWithOrder = 0;
                } else {
                    overItemWithOrder = maxOrder + 1;
                }
            } else {
                // TODO - sectionItems is not reloading properly!!!
                overItemWithOrder = sectionItems.find(x => x.id === overObj?.sectionItemId)?.order;
            }

            sectionId = overObj.sectionId!;
            order = overItemWithOrder;
        }
        else if (overObj?.typeDisclaimer === DndSectionItemType.Section) {
            sectionId = overObj.sectionId!;
            order = undefined;
        } else {
            return;
        }

        await this._apiOperations.createItemByType(
            sectionId, 
            order, 
            activeObj.pickerElementType);
    }

    private async handleSectionDrag(
        event: DragEndEvent,
        activeObj: SectionDndObject
    ) {
        const orderedSecitons = store.getState().sectionsDnd.orderedSections;
        const overObj = event.over && JSON.parse(event.over!.id) as UnifiedDndObject;

        if (activeObj.sectionId !== overObj?.sectionId) {
            const oldIndex = orderedSecitons.findIndex(x => x.id === +activeObj.sectionId!);
            const newIndex = orderedSecitons.findIndex(x => x.id === +overObj?.sectionId!);
            const reorderedItems = arrayMove(orderedSecitons, oldIndex, newIndex);
            store.dispatch(setOrderedSections(reorderedItems));

            const sectionOrderDtos: OrderDto[] = reorderedItems.map((x, index) => {
                return {
                    id: x.id!,
                    order: index
                } as OrderDto;
            });

            this._apiOperations.reorderSection(sectionOrderDtos);
        }
    }

    private async handleSectionItemDragEnd(
        event: DragEndEvent,
        activeObj: SectionItemDndObject
    ) {
        const orderedSecitons = store.getState().sectionsDnd.orderedSectionItems![activeObj.sectionId];
        const overObj = event.over && JSON.parse(event.over!.id) as UnifiedDndObject;

        if (activeObj.sectionItemId !== overObj?.sectionItemId) {
            const oldIndex = orderedSecitons.findIndex(x => x.id === +activeObj.sectionItemId);
            const newIndex = orderedSecitons.findIndex(x => x.id === +overObj!.sectionItemId!);

            const reorderedItems = arrayMove(orderedSecitons, oldIndex, newIndex);
            store.dispatch(setOrderedSectionItems({
                sectionId: activeObj.sectionId,
                items: reorderedItems
            }));

            const orderDtos: OrderDto[] = reorderedItems.map((x, index) => {
                return {
                    id: x.id!,
                    order: index
                } as OrderDto;
            });

            await this._apiOperations.reorderSectionItem(activeObj.sectionId, orderDtos);
        }
    }
}
