import {useInjection} from "inversify-react";
import React, {useEffect, useState} from "react";
import ApiService from "../../services/apiService";
import { TranslationDto, VimeoVideoApi } from "../../api";
import {useSelector} from "react-redux";
import {getCurrentBrandId} from "../../redux/organisationsSlice";
import { Layout } from "antd";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {Content} from "antd/lib/layout/layout";
import styles from "./videosListContainer.module.scss";
import { RoutingPaths } from "../routingContainer";
import BrandSider from "../../components/brandSider/brandSider";
import VideosList from "./videosList";

export default function VideosListContainer() {
    const apiService = useInjection(ApiService);
    const [videos, setVideos] = useState<TranslationDto[] | null>();
    const brandIdFromState = useSelector(getCurrentBrandId);

    useEffect(() => {
        if (!brandIdFromState) {
            return;
        }
        refreshVideosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandIdFromState]);

    const refreshVideosList = async () => {
        try {
            const videos = await apiService.getApi(VimeoVideoApi).apiVimeoVideoGet();
            setVideos(videos.data);
        } catch (e) {
            console.log(e.response.data);
        }
    }

    return <>
        <Layout style={{ backgroundColor: "white" }}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Videos
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.VideosList} />
                    <Content className={styles.content}>
                        <VideosList
                            data={videos || null}
                        />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
