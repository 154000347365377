import { EllipsisOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Collapse, Dropdown, Menu, Tooltip } from "antd";
import { ReactNode, useState } from "react";
import {AdminRole, BrandAdminRoleDto, LocalizableStringDto, SectionDto} from "../../../../api";
import LocalizableReadonly from "../../../../components/localizableReadonly/localizableReadonly";
import Section from "../section/section";
import styles from "./sectionPanel.module.scss";
import {brandAdminRolesForOrgUser, userHasRole} from "../../../../helpers/brandAdminRoleHelper";
import {useSelector} from "react-redux";
import {getAllBrandAdminRoles} from "../../../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../../../redux/organisationsSlice";

const { Panel } = Collapse;

export default function SectionPanel(props: SectionPanelProps) {
    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onDropdownButtonClick = (event: any) => {
    event.stopPropagation();
  }

  const onDeleteClick = (item: SectionDto, event: any) => {
    props.onDeleteClicked(item.id!);
  }

  const onEditClicked = (item: SectionDto, event: any) => {
      props.onEditClicked(item.id!);
  }

  const dropdownMenu = (item: SectionDto) => (
    <Menu>
        <Menu.Item onClick={(event) => onEditClicked(item, event)}>Edit</Menu.Item>
        {userHasRole(AdminRole.ContentLead, brandAdminRoles) && <Menu.Item onClick={(event) => onDeleteClick(item, event)} danger> Delete</Menu.Item>}
    </Menu>
  );

  const panelItemActions = (item: SectionDto): ReactNode => {
    return <>
      <Dropdown
        overlay={dropdownMenu(item)}
        trigger={['click']}
        placement="bottomRight"
      >
        <Button type="text" size="small" className={styles.panelButton} onClick={onDropdownButtonClick}>
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    </>
  }

  return (
      <Collapse
          className="section-panel-collapse"
          expandIconPosition="left"
          expandIcon={({isActive}) => !isExpanded?
              props.dragHandleIcon:
              <Tooltip title="Drag and drop possible on non-expanded sections">
                  <RightOutlined
                      rotate={isActive? 90: 0}
                  />
              </Tooltip>
          }
          bordered={true}
          onChange={() => setIsExpanded(!isExpanded)}
      >
          <Panel
              className="section-panel-panel"
              header={<LocalizableReadonly value={props.name}/>}
              key={props.id || 0}
              extra={panelItemActions(props.sectionData)}
          >
              <Section data={props.sectionData}/>
          </Panel>
      </Collapse>
  );
}

interface SectionPanelProps {
  id: string,
  name: LocalizableStringDto[],
  sectionData: SectionDto,
  dragHandleIcon: ReactNode,

  onEditClicked: (id: number) => Promise<any>,
  onDeleteClicked: (id: number) => Promise<any>,
  onVisbilityClicked: (id: number, isVisible: boolean) => Promise<any>
}
