
import { SectionItemShorthandType } from "../../../components/section/sectionItemShorthandType";
import { useEffect } from "react";
import { orderableSortAsc } from "../../../../../helpers/orderableSort";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useInjection } from "inversify-react";
import SectionDndService from "../../sectionDndService";
import { useDispatch, useSelector } from "react-redux";
import { getOrderedSectionItems, setOrderedSectionItems } from "../../../../../redux/sectionsDndSlice";
import { SectionItemDndObject } from "../../picker-items/SectionItemDndObject";
import SectionItemSwitch from "../../../components/sectionItems/sectionItemSwitch";
import SortableSectionItemWrapper from "../sortableWrappers/sortableItemSectionItemWrapper";

export default function SectionItemList(props: SectionItemListProps) {

  const dispatch = useDispatch();
  const orderedSecitonItems = useSelector(getOrderedSectionItems);

  const sectionDndService = useInjection(SectionDndService);

  useEffect(() => {
    const items = props.items?.sort((a, b) => orderableSortAsc(a, b)) || [];
    dispatch(setOrderedSectionItems({
      sectionId: props.sectionId,
      items: items
    }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  return <>
    {orderedSecitonItems && orderedSecitonItems[props.sectionId]
      ? <SortableContext
          key={sectionDndService.getSectionItemsSortableId(props.sectionId)}
          items={orderedSecitonItems[props.sectionId].map(x => SectionItemDndObject.create(props.sectionId, x.id!))}
          strategy={verticalListSortingStrategy}
          id={sectionDndService.getSectionItemsSortableId(props.sectionId)}
        >
          {orderedSecitonItems[props.sectionId].map((item) =>
            <SortableSectionItemWrapper
              isHidden={false}
              id={SectionItemDndObject.create(props.sectionId, item.id!)}
              key={item.id}
            >
              <SectionItemSwitch item={item} onSectionUpdate={props.onSectionUpdate}/>
            </SortableSectionItemWrapper>
          )}
          {props.items?.length ? null :
            <span>Empty section, no items found</span>
          }
          <SortableSectionItemWrapper
            id={`{"typeDisclaimer":"item","sectionId":${props.sectionId},"sectionItemId":0}`}
            isHidden={true}
          > </SortableSectionItemWrapper>
        </SortableContext>
      : ''
    }
  </>;
}

export interface SectionItemListProps {
  sectionId: number,
  items: SectionItemShorthandType[],
  onSectionUpdate: () => void
}
