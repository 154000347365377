import { DeleteTwoTone } from "@ant-design/icons";
import { message, Popconfirm } from "antd";
import { useInjection } from "inversify-react";
import { useEffect, useRef, useState } from "react";
import { FileInfoDtoLocalizableEntityDto, SectionFileItemApi, SectionFileItemDto } from "../../../../../api";
import AsyncButton from "../../../../../components/asyncButton/asyncButton";
import LocalizableFileUploader from "../../../../../components/localizableFileUploader/localizableFileUploader";
import ApiService from "../../../../../services/apiService";
import styles from "./sectionFileItem.module.scss";

type FileUploaderHandle = React.ElementRef<typeof LocalizableFileUploader>;

export default function SectionFileItem(props: SectionFileItemProps) {

  const [isDirty, setIsDirty] = useState(false);
  const [files, setFiles] = useState(props.data.files ?? []);
  const filesRef = useRef<FileInfoDtoLocalizableEntityDto[]>(files);
  const fileUploaderRef = useRef<FileUploaderHandle>();
  const apiService = useInjection(ApiService);

  useEffect(() => {
    const newFiles = props.data.files ?? [];
    setFiles(newFiles);
    filesRef.current = newFiles;
  }, [props.data.files]);

  const onChange = (files: FileInfoDtoLocalizableEntityDto[]) => {
    setIsDirty(true);
    filesRef.current = files;
  };

  const onSave = async () => {
    let error = await fileUploaderRef.current.onFormFinish();

    if (!error) {
      const sectionFileItem = props.data; 
      error = await apiService.getApi(SectionFileItemApi).apiSectionFileItemIdPut(sectionFileItem.id!, {
        sectionId: sectionFileItem.sectionId,
        order: sectionFileItem.order,
        sectionItemType: sectionFileItem.sectionItemType,
        files: filesRef.current
      });
    }
  }

  const onDeleteSubmit = async () => {
    try {
      fileUploaderRef.current.onFormFinish();
      await apiService.getApi(SectionFileItemApi).apiSectionFileItemIdDelete(props.data.id!);
      message.success("Deleted.");
      props.onSectionUpdate();
    } catch (e) {
      console.log(e.response.data);
      message.error("Something went wrong.");
    }
  };

  return <div className={styles.container}>
    <LocalizableFileUploader ref={fileUploaderRef} value={files} onChange={onChange} fileUploaderProps={{ useOrganisationFileApi: true }} />
    {isDirty ? <AsyncButton className={styles.actionButton} type="primary" onClick={onSave}>Save</AsyncButton> : null}
    <Popconfirm title="Are you sure?" onConfirm={onDeleteSubmit} onCancel={e => e?.stopPropagation()}
      className={styles.deleteButton}>
      <DeleteTwoTone className={styles.actionButton} twoToneColor="#ff4d4f" />
    </Popconfirm>
  </div>;
}

export interface SectionFileItemProps {
  data: SectionFileItemDto,
  onSectionUpdate: () => void
}
