import { FilterOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import Fuse from "fuse.js";
import { useState } from "react";
import styles from "./tableFuzzySearch.module.scss";

export default function TableFuzzySearch<T>(props: TableFuzzySearchProps<T>) {
    const [matchesFound, setMatchesFound] = useState<number | null>(null);

    const keys = props!.columns.flatMap(x => {
        let key = (x as ColumnType<T>).dataIndex as string
        return [key, `${key}.value`]; // cover Localizable String case
    });

    const fuseOptions = {
        threshold: 0.4,
        keys: keys
    };

    const onChange = (value: string) => {
        if (value) {
            const fuse = new Fuse(props.data as readonly T[], fuseOptions);
            const searchResult = fuse.search(value);

            setMatchesFound(searchResult.length);
            props.onSearch(searchResult.flatMap(sr => sr.item));
        } else {
            setMatchesFound(null);
            props.onSearchClear();
        }
    };

    return <>
        <Form>
            <Form.Item
                label="Search:"
                validateStatus="validating"
                help={matchesFound == null 
                        ? " " 
                        : `${matchesFound} match(es)`}
            >
                <Input
                    className={styles.searchInput}
                    placeholder="Type to find an approximate match"
                    prefix={<FilterOutlined/>}
                    allowClear={true}
                    onChange={(event) => onChange(event.target.value)}
                />
            </Form.Item>
        </Form>
    </>;
}

interface TableFuzzySearchProps<T> {
    data: T[] | null,
    columns: ColumnsType<T>,

    onSearch: (selectedData: T[]) => any,
    onSearchClear: () => any
}
