import React, { ReactNode } from "react";
import { CrudFormItemProps } from "../crudForm/crudFormProps";
import CrudForm from "../crudForm/crudForm";

export default function CrudFormSmall<TSubmit, TInitial>(props: CrudFormSmallProps<TSubmit, TInitial>) {

    return <CrudForm
            formProps={{layout: "vertical", labelCol: {}, wrapperCol: {}, ...props.formProps}}
            formItems={props.formItems}
            onSubmit={props.onSubmit}
            saveButtonTitle={props.saveButtonTitle}
            underInputActions={props.extraUnderInputAction}
            extraActions={props.extraActions}
            extraActionsPosition="left"
            tailProps={{wrapperCol: {}}}
            initialValues={props.initialValues}
        />;
}
 

export interface CrudFormSmallProps<TSubmit, TInitial> {
    extraActions?: ReactNode,
    saveButtonTitle?: string,
    formItems: CrudFormItemProps[],
    onSubmit: (value: TSubmit) => Promise<any>,
    formProps?: any,
    tailProps?: any,
    initialValues?: TInitial,
    extraUnderInputAction?: ReactNode
}