import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useInjection } from "inversify-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionDto } from "../../../../../api";
import { orderableSortAsc } from "../../../../../helpers/orderableSort";
import { getOrderedSections, setOrderedSections } from "../../../../../redux/sectionsDndSlice";
import { SectionDndObject } from "../../picker-items/SectionDndObject";
import SectionDndService from "../../sectionDndService";
import SortablePanelItemElement from "../sortableWrappers/sortableSectionPanelWrapper";

export default function SectionList(props: SectionListProps) {

  const sectionDndService = useInjection(SectionDndService);
  const orderedSecitons = useSelector(getOrderedSections);
  const dispatch = useDispatch();

  useEffect(() => {
    const orderedItems = props.data?.sort((a, b) => orderableSortAsc(a, b));
    dispatch(setOrderedSections(orderedItems || []));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return <>
    {!orderedSecitons
      ? <>Loading...</>
      : <>
          <SortableContext
            items={orderedSecitons.map(x => SectionDndObject.create(x.id!))}
            strategy={verticalListSortingStrategy}
            id={sectionDndService.getSectionSortableId()}
          >
            {orderedSecitons.map((item) =>
                <SortablePanelItemElement
                  id={SectionDndObject.create(item.id!)}
                  name={item.title!}
                  key={item.id!.toString()}
                  section={item}
                  onEditClicked={props.onEditClicked}
                  onDeleteClicked={props.onDeleteClicked}
                  onVisbilityClicked={props.onVisbilityClicked}
                >
                </SortablePanelItemElement>
              )}
          </SortableContext>
        </>
    }
  </>;
}

interface SectionListProps {
  data: SectionDto[] | null,
  onEditClicked: (id: number) => Promise<any>,
  onDeleteClicked: (id: number) => Promise<any>,
  onVisbilityClicked: (id: number, isVisible: boolean) => Promise<any>
}
