import React from "react";
import { CourseCreateDto } from "../../../../api";
import AsyncSwitch from "../../../../components/asyncSwitch/asyncSwitch";
import ColorPicker from "../../../../components/colorPicker/colorPicker";
import { CrudFormItemProps } from "../../../../components/crudForm/crudFormProps";
import CrudFormModal from "../../../../components/crudFormModal/crudFormModal";
import LocalizableInput from "../../../../components/localizableInput/localizableInput";
import { nameof } from "../../../../helpers/nameofHelper";
import LocalizableFileUploader from "../../../../components/localizableFileUploader/localizableFileUploader";
import { Input } from "antd";

export default function CourseModalForm(props: CourseModalProps) {

    const formItems: CrudFormItemProps[] = [
        {
            name: nameof<CourseCreateDto>("name"),
            rules: [
                { required: true, message: 'This field is required' }
            ],
            children: <LocalizableInput />
        },
        {
            name: nameof<CourseCreateDto>("color"),
            children: <ColorPicker />
        },
        {
          name: nameof<CourseCreateDto>("image"),
            label: "Image",
            children: <LocalizableFileUploader fileUploaderProps={{
                useOrganisationFileApi: true,
            }}/>
        },
        {
            name: nameof<CourseCreateDto>("isVisible"),
            children: <AsyncSwitch />
        },
        {
            name: nameof<CourseCreateDto>("slug"),
            children: <Input />
        },
    ];

    return <CrudFormModal
        width={800}
        formItems={formItems}
        onSubmit={props.onSubmit}
        onModalCancelClicked={() => props.setModalVisibility(false)}
        initialValues={props.initialValues}
        modalOkText={props.saveButtonTitle}
        modalTitle={props.modalTitle}
        isModalVisible={props.isModalVisible}
    />;
}

interface CourseModalProps {
    initialValues?: CourseCreateDto,
    isModalVisible: boolean;
    setModalVisibility: (isVisible: boolean) => any;
    onSubmit: (value: CourseCreateDto) => Promise<any>,
    modalTitle: string,
    saveButtonTitle: string
}