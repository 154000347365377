import {UploadFile} from "antd/lib/upload/interface";
import React, {forwardRef, useImperativeHandle, useRef} from "react";
import FileUploader from "./fileUploader";

type FileUploaderHandle = React.ElementRef<typeof FileUploader>;

function getSingleElementArrayOrEmpty<T>(element?: T): T[] {
    return element ? [element] : [];
}

const SingleFileUploader = forwardRef((props: SingleFileUploaderProps, ref: React.ForwardedRef<any>) => {

    const fileListRef = useRef(getSingleElementArrayOrEmpty(props.defaultFile));
    const fileUploaderRef = useRef<FileUploaderHandle>();

    useImperativeHandle(ref, () => ({
        async onFormFinish() {
            return await fileUploaderRef.current.onFormFinish();
        }
    }));

    const onChange = (uploadedFileIds: string[], file: UploadFile<any>[]) => {
        props.onChange?.(uploadedFileIds[0], file[0]);
    };

    const defaultFileList = props.defaultFile !== fileListRef.current[0]
        ? (fileListRef.current = getSingleElementArrayOrEmpty(props.defaultFile))
        : fileListRef.current;
    const value = props.value ? [props.value] : undefined;

    return <FileUploader
        ref={fileUploaderRef}
        defaultFileList={defaultFileList}
        onChange={onChange}
        value={value}
        accept={props.accept}
        sizeLimitMb={props.sizeLimitMb}
        maxCount={1}
        useOrganisationFileApi={props.useOrganisationFileApi}
        isFilePublic={props.isFilePublic}
    />
});

export interface SingleFileUploaderProps {
    defaultFile?: UploadFile<any>;
    onChange?: (uploadedFileId?: string, file?: UploadFile<any>) => any;
    value?: number;
    accept?: string;
    sizeLimitMb?: number,
    useOrganisationFileApi?: boolean,
    isFilePublic?: boolean,
}

export default SingleFileUploader;