import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Header } from "antd/lib/layout/layout";
import Text from 'antd/lib/typography/Text';
import { User } from "oidc-react";
import React from 'react';
import {useSelector } from "react-redux";
import {  NavLink } from 'react-router-dom';
import { setGlobalLanguage } from '../../redux/languageSlice';
import { getCurrentBrandName } from "../../redux/organisationsSlice";
import { store } from '../../redux/store';
import AsyncButton from "../asyncButton/asyncButton";
import LanguageSelector from '../languageSelector/languageSelector';
import styles from './cypherCmsHeader.module.scss';

interface CypherCmsHeaderProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

export default function CypherCmsHeader(props: CypherCmsHeaderProps) {
    const brandName = useSelector(getCurrentBrandName);

    const onCurrentLanguageChanged = (value: string) => {
        store.dispatch(setGlobalLanguage(value));
    }

    if (props.userData) {
        return <Header className={styles.elHeader}>
            <Row>
                <Col className={styles.headerLogo}>
                    <NavLink to="/"><span className={styles.headerTitle}>CypherCMS</span></NavLink>
                </Col>
                <div style={{ flex: 1 }}/>
                <Col>
                    {/* TODO - HIDE THIS ON ROUTES DESIGNED FOR GLOBAL ADMINISTRATOR */}
                    { brandName === "" ? null :
                        <Text>Current brand: {brandName}</Text>
                    }
                    <AsyncButton type="text" onClick={props.onLogoutPressed} icon={<LogoutOutlined />}>Log out</AsyncButton>
                </Col>
                <Col>
                    <LanguageSelector
                        onSelect={onCurrentLanguageChanged}
                    />
                </Col>
            </Row>
        </Header>;
    }

    return <Header className={styles.elHeader}>
        <Row>
            <Col className={styles.headerLogo}>
                <a href={process.env.REACT_APP_URL}><span className={styles.headerTitle}>CypherCMS</span></a>
            </Col>
            <div style={{ flex: 1 }}/>
            <Col>
                <AsyncButton type="text" onClick={props.onLoginPressed} icon={<LoginOutlined />}>Login</AsyncButton>
            </Col>
        </Row>
    </Header>;
}
