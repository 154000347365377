import { Skeleton } from "antd";
import { useInjection } from 'inversify-react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SortEnd } from 'react-sortable-hoc';
import { CourseApi, ModuleApi, ModuleCreateDto, ModuleDto, OrderDto } from '../../../../api';
import { reorder } from '../../../../helpers/arrayHelpers';
import { setCoursesDetails } from "../../../../redux/coursesSlice";
import { setCurrentBrandId } from "../../../../redux/organisationsSlice";
import ApiService from '../../../../services/apiService';
import ModuleSider, { ModuleSortDto } from "../moduleSider/moduleSider";
import ModuleModalForm from './../moduleModalForm/moduleModalForm';
import ModuleModalFormAddContainer from './../moduleModalFormAddContainer/moduleModalFormAddContainer';
import styles from './moduleSiderContainer.module.scss';

export default function ModuleSiderContainer(props: ModuleSiderContainerProps) {
  let { brandId, courseId } = useParams<{ brandId: string, courseId: string }>();
  const courseIdNum = Number.parseInt(courseId);
  const apiService = useInjection(ApiService);
  const dispatch = useDispatch();

  const [sortData, setSortData] = useState<ModuleSortDto[] | undefined>(undefined);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [editModel, setEditModel] = useState<ModuleDto>();

  const refreshModulesList = async () => {
    var result = await apiService.getApi(CourseApi).apiCourseIdGet(courseIdNum);
    dispatch(setCoursesDetails(result.data));

    setSortData(result.data.modules!
      .map(module => ({ module: module, index: module.order } as ModuleSortDto))
      .sort((a, b) => a.module.order! - b.module.order!));
  }

  useEffect(() => {
    dispatch(setCurrentBrandId(brandId));
    refreshModulesList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, dispatch]);

  const onItemEdit = (item: ModuleSortDto) => {
    setEditModel(item.module);
    setModalVisibility(true);
  };

  const onItemDelete = async (id: number) => {
    await apiService.getApi(ModuleApi).apiModuleIdDelete(id);
    await refreshModulesList();
  };

  const onSort = async (e: SortEnd) => {
    let reorderedModules = reorder(sortData!, e.oldIndex, e.newIndex)
      .map((e, i) => ({ id: e.module.id, order: i + 1 } as OrderDto));

    var result = await apiService.getApi(CourseApi)
      .apiCourseIdModulesOrderPost(courseIdNum, reorderedModules);

    setSortData(result.data.modules!
      .map(module => ({ module: module, index: module.order } as ModuleSortDto))
      .sort((a, b) => a.module.order! - b.module.order!));
  };

  const editModule = async (model: ModuleCreateDto) => {
    model.courseId = courseIdNum;

    await apiService.getApi(ModuleApi).apiModuleIdPut(editModel!.id!, model);
    await refreshModulesList();
    setModalVisibility(false);
  };

  return <>
    <ModuleModalForm
      initialValues={editModel}
      isModalVisible={isModalVisible}
      setModalVisibility={setModalVisibility}
      onSubmit={editModule}
      modalTitle="Edit Module"
      saveButtonTitle="Submit"
    />

    <div className={styles.modulesTitle}>Modules</div>

    {sortData
    ? <ModuleSider
        onItemEdit={onItemEdit}
        onItemDelete={onItemDelete}
        onSort={onSort}
        dataSource={sortData}
      />
    : <Skeleton />}

    <ModuleModalFormAddContainer
      isModalVisible={props.isModalVisible}
      setModalVisible={props.setModalVisible}
      courseId={courseIdNum}
      onFinish={refreshModulesList} />
  </>;
}

interface ModuleSiderContainerProps {
  isModalVisible: boolean,
  setModalVisible: (isVisible: boolean) => void;
}
