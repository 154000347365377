import { useSortable } from "@dnd-kit/sortable";
import { ReactNode } from "react";
import { CSS } from '@dnd-kit/utilities';
import { PauseOutlined } from "@ant-design/icons";
import SectionPanel from "../../../components/sectionPanel/sectionPanel";
import { LocalizableStringDto, SectionDto } from "../../../../../api";

export default function SortablePanelItemElement(props: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  return (
    <div
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition!
      }}
      ref={setNodeRef}
      {...attributes}
    >
      <SectionPanel
        id={props.section.id!.toString()}
        name={props.name}
        sectionData={props.section}
        dragHandleIcon={<PauseOutlined rotate={90} {...listeners}/>}

        onEditClicked={props.onEditClicked}
        onDeleteClicked={props.onDeleteClicked}
        onVisbilityClicked={props.onVisbilityClicked}
      />
    </div>
  );
}

interface SortableItemProps {
  id: string,
  name: LocalizableStringDto[],
  children: ReactNode
  section: SectionDto,

  onEditClicked: (id: number) => Promise<any>,
  onDeleteClicked: (id: number) => Promise<any>,
  onVisbilityClicked: (id: number, isVisible: boolean) => Promise<any>
}
