import {UploadFile} from "antd/lib/upload/interface";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {FileInfoDtoLocalizableEntityDto, LanguageDto} from "../../api";
import LocalizableHelper from "../../helpers/localizableHelper";
import Mapper from "../../helpers/mapper";
import {getFilteredLanguages, getGlobalLanguage} from "../../redux/languageSlice";
import SingleFileUploader, {SingleFileUploaderProps} from "../fileUploader/singleFileUploader";
import LanguageSelector, {Language} from "../languageSelector/languageSelector";
import styles from "./localizableFileUploader.module.scss";
import {Switch} from "antd";

type FileUploaderHandle = React.ElementRef<typeof SingleFileUploader>;

const LocalizableFileUploader = forwardRef((props: LocalizableFileUploaderProps, ref: React.ForwardedRef<any>) => {
    const defaultLanguage = useSelector(getGlobalLanguage);
    const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
    const [currentValue, setCurrentValue] = useState<FileInfoDtoLocalizableEntityDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const fileUploaderRefs = useRef<{ [key: string]: FileUploaderHandle }>({});
    const filteredLanguages = useSelector(getFilteredLanguages);

    useImperativeHandle(ref, () => ({
        async onFormFinish() {
            setIsLoading(true);

            const results = await Promise.all(Object.keys(fileUploaderRefs.current).map(k => {
                const ref = fileUploaderRefs.current[k];

                return ref.onFormFinish();
            }));

            setIsLoading(false);

            return results.filter(r => r !== undefined)[0];
        }
    }));

    useEffect(() => {
        const mappedValue = props.value?.map(v => ({...v, value: Mapper.FileInfoDto_UploadFile(v.value!)}));
        setCurrentValue(mappedValue || []);
    }, [props.value]);

    const onInputChange = (language: Language, uploadedFileId?: string, uploadedFile?: UploadFile<any>) => {
        const localizableEntry = LocalizableHelper.getEntryForLanguage(language, currentValue);
        const value = uploadedFile;

        if (!localizableEntry) {
            currentValue.push({
                locale: currentLanguage,
                value
            });
        } else {
            if (!value) {
                currentValue.splice(currentValue.indexOf(localizableEntry), 1);
            } else {
                localizableEntry.value = value;
            }
        }

        setCurrentValue(currentValue);
        props.onChange?.(
            currentValue?.map<FileInfoDtoLocalizableEntityDto>(v => ({
                locale: v.locale,
                value: Mapper.UploadFile_FileInfoDto(v.value as any)
            })));
    }

    return <>
        <div className={styles.container}>
            <div className={styles.languagePicker}>
                <LanguageSelector loading={isLoading} onSelect={setCurrentLanguage}/>
            </div>

            {filteredLanguages.map((l, i) =>
                <div style={l.locale === currentLanguage ? {} : {display: "none"}}>
                    <SingleFileUploader
                        {...props.fileUploaderProps}
                        key={l.locale}
                        ref={(ref) => fileUploaderRefs.current[l.locale!] = ref}
                        defaultFile={currentValue.find(v => v.locale === l.locale)?.value as any}
                        onChange={(id, file) => onInputChange(l.locale!, id, file)}
                        useOrganisationFileApi={true}
                        isFilePublic={props.areFilesPublic}
                    />
                </div>
            )}
        </div>
    </>
});

interface LocalizableFileUploaderProps {
    value?: FileInfoDtoLocalizableEntityDto[],
    onChange?: (value: FileInfoDtoLocalizableEntityDto[]) => void,
    fileUploaderProps: SingleFileUploaderProps,
    areFilesPublic?: boolean
}

export default LocalizableFileUploader;