import { LocalizableStringDto } from "../../api";
import { Language } from "../languageSelector/languageSelector";
import LocalizableInput from "../localizableInput/localizableInput";
import RichtextEditor from "../richtextEditor/richtextEditor";

export default function LocalizableRichtextEditor(props: LocalizableRichtextEditorProps) {
    const richtextEditorRenderer = (languageSelector: JSX.Element, onInputChange: (value: string) => any, value: string) => {
        return <div>
            {languageSelector}
            <RichtextEditor onChange={onInputChange} value={value} />
        </div>;
    }

    return <LocalizableInput
        {...props}
        customRenderer={richtextEditorRenderer}
    />
}

interface LocalizableRichtextEditorProps {
    value?: LocalizableStringDto[],
    onChange?: (value: LocalizableStringDto[]) => void,
    onLanguageChange?: (value: Language) => void
}