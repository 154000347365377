import { message } from "antd";
import { injectable, Container } from "inversify";
import "reflect-metadata";
import { ModuleApi, SectionApi, SectionFileItemApi, SectionFileItemCreateDto, SectionItemType, OrderDto, SectionScormItemApi, SectionScormItemCreateDto, SectionTextItemApi, SectionTextItemCreateDto, SectionVideoItemApi, SectionVideoItemCreateDto } from "../../../api";
import { setSectionDetails } from "../../../redux/sectionsSlice";
import { store } from "../../../redux/store";
import ApiService from "../../../services/apiService";

@injectable()
export default class SectionDndServiceApiOperations {
    _apiService: ApiService;

    constructor(
    ) {
        const container = new Container(); // because, proposed way didn't worked => @inject(ApiService) apiService: ApiService
        this._apiService = container.resolve(ApiService);
    }

    public async reorderSection(orderDtos: OrderDto[]) {
        const moduleId = store.getState().sectionsDnd.currentModuleId;
        await this._apiService.getApi(ModuleApi).apiModuleIdSectionsOrderPost(
            moduleId!,
            orderDtos
        );
    }

    public async reorderSectionItem(sectionId: number, orderDtos: OrderDto[]) {
        await this._apiService.getApi(SectionApi).apiSectionIdItemsOrderPost(
            sectionId,
            orderDtos
        );

        await this._apiService.getApi(SectionApi).apiSectionIdGet(sectionId).then(response => {
            store.dispatch(setSectionDetails(response.data));
        });
    }

    public async createItemByType(
        sectionId: number, 
        order: number | undefined, 
        pickerElementType: SectionItemType
    ) {
        switch (pickerElementType) {
            case SectionItemType.Text:
                await this.createTextItem(sectionId, order);
                break;
            case SectionItemType.File:
                await this.createFileItem(sectionId, order);
                break;
            case SectionItemType.Video:
                await this.createVideoItem(sectionId, order);
                break;
            case SectionItemType.Scorm:
                await this.createSCORMItem(sectionId, order);
                break;
            default:
                throw Error(`No service for type ${pickerElementType}`)
        }

        await this._apiService.getApi(SectionApi).apiSectionIdGet(sectionId)
            .then(response => {
                store.dispatch(setSectionDetails(response.data));
                message.success(`Item added to section ${sectionId}`);
            });
    }

    private async createTextItem(sectionId: number, order?: number) {
        const createDto: SectionTextItemCreateDto = {
            sectionId: sectionId,
            order: order
        }
        await this._apiService.getApi(SectionTextItemApi).apiSectionTextItemPost(createDto);
    }

    private async createFileItem(sectionId: number, order?: number) {
        const createDto: SectionFileItemCreateDto = {
            sectionId: sectionId,
            order: order,
            files: []
        }
        await this._apiService.getApi(SectionFileItemApi).apiSectionFileItemPost(createDto);
    }

    private async createVideoItem(sectionId: number, order?: number) {
        const createDto: SectionVideoItemCreateDto = {
            sectionId: sectionId,
            order: order
        }
        await this._apiService.getApi(SectionVideoItemApi).apiSectionVideoItemPost(createDto);
    }

    private async createSCORMItem(sectionId: number, order?: number) {
        const createDto: SectionScormItemCreateDto = {
            sectionId: sectionId,
            order: order
        }
        await this._apiService.getApi(SectionScormItemApi).apiSectionScormItemPost(createDto);
    }
}
