
import { closestCorners, DndContext, DragCancelEvent, DragEndEvent, DragOverEvent, DragStartEvent, rectIntersection } from "@dnd-kit/core";
import { useInjection } from "inversify-react";
import { ReactNode, useState } from "react";
import SectionDndService from "../../sectionDndService";
import { UnifiedDndObject } from "../../picker-items/UnifiedDndObject";
import DragOverlaySwitch from "../drag-overlays/dragOverlaySwitch";
import { customCollisionDetectionStrategy } from "./customCollisionDetectionStrategy";

export default function DndWrapper(props: dndWrapperProps) {
  const sectionDndService = useInjection(SectionDndService);
  const [activeId, setActiveId] = useState<UnifiedDndObject | null>(null);

  function handleDragStart(event: DragStartEvent) {
    console.log("DndWrapper handleDragStart");
    setActiveId(JSON.parse(event?.active?.id) as UnifiedDndObject);
  }

  function handleDragEnd(event: DragEndEvent) {
    setActiveId(null);
    sectionDndService.handleOnDragEnd(event);
  }

  return <>
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={customCollisionDetectionStrategy}
    >
      {props.children}

      {activeId &&
        <DragOverlaySwitch item={activeId} />
      }

    </DndContext>
  </>
}

export interface dndWrapperProps {
  children: ReactNode
}
