import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CourseDetailsDto} from "../api";

export type CoursesDetailsDictionary = { [id: string]: CourseDetailsDto };

const initialState: { 
    coursesDetails: CoursesDetailsDictionary | null,
} = {
    coursesDetails: null,
};

const coursesSlice = createSlice({
    name: 'coursesSlice',
    initialState,
    reducers: {
        setCoursesDetails: (
            state, 
            action: PayloadAction<CourseDetailsDto>
        ) => {
            let detailsDict = state.coursesDetails || {};
            detailsDict[+action.payload.id!] = action.payload;

            state.coursesDetails = detailsDict;
        },
    }
});

// these methods only modify the state
export const {
    setCoursesDetails,
} = coursesSlice.actions;

// these methods only read the state
export const getCoursesDetails = (state: any) => state.courses.coursesDetails as CoursesDetailsDictionary;

export default coursesSlice.reducer;
