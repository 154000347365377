import { PlusOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { SectionDto } from "../../api";
import AsyncButton from "../../components/asyncButton/asyncButton";
import DndWrapper from "./section-drag-and-drop/components/dndWrapper/dndWrapper";
import ElementPicker from "./components/elementPicker/elementPicker";
import SectionList from "./section-drag-and-drop/components/sectionList/sectionList";
import styles from './moduleEdit.module.scss'


export default function ModuleEdit(props: ModuleEditProps) {
  return <>
    <DndWrapper>
      <Layout className={styles.workspace}>
        <Content>
          <SectionList
            data={props.data}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={props.onDeleteClicked}
            onVisbilityClicked={props.onVisbilityClicked}
          />
          <div className={styles.addButtonWrapper}>
            <AsyncButton
              onClick={props.onAddClicked}
              icon={<PlusOutlined />}
              size="large"
            >Add Section</AsyncButton>
          </div>
        </Content>
      </Layout>
      <Sider
        theme="light"
        trigger={null}
        className={styles.sider}
        width={200}
      >
        <ElementPicker />
      </Sider>
    </DndWrapper>
  </>;
}

export interface ModuleEditProps {
  data: SectionDto[] | null,
  onAddClicked: () => Promise<any>,
  onEditClicked: (id: number) => Promise<any>,
  onDeleteClicked: (id: number) => Promise<any>,
  onVisbilityClicked: (id: number, isVisible: boolean) => Promise<any>
}
