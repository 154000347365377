import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import {EventCreateDto, EventUpdateDto, ParentEventApi, ParentEventDto} from "../../../api";
import ApiService from "../../../services/apiService";
import EventModalForm from "../eventModalForm/eventModalForm";

export default function EventModalFormAddContainer(props: EventModalFormAddContainerProps) {

    const apiService = useInjection(ApiService);
    const [parentEvents, setParentEvents] = useState<ParentEventDto[] | null>(null);
    
    useEffect(() => {
        apiService.getApi(ParentEventApi).apiParentEventGet()
            .then(response => {
                setParentEvents(response.data);
            });
    }, [])

    return <>
        <EventModalForm
            isModalVisible={props.addFormVisibility}
            setModalVisibility={props.onFinish}
            onSubmit={props.onSubmit}
            modalTitle={props.title}
            saveButtonTitle="Submit"
            initialValues={props.eventToEdit!}
            parentEvents={parentEvents}
        />
    </>
}

interface EventModalFormAddContainerProps {
    onFinish: (refreshList: boolean) => void;
    onSubmit: (values: EventCreateDto | EventUpdateDto) => Promise<any>;
    addFormVisibility: boolean;
    title: string;
    eventToEdit: EventUpdateDto | null;
}