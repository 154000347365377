import {AchievementGainedDto, LocalizableStringDto} from "../../../../api";
import CrudTable, {CrudTableWrapperProps} from "../../../../components/crudTable/crudTable";
import {ColumnsType} from "antd/lib/table/Table";
import {nameof} from "../../../../helpers/nameofHelper";
import {Table} from "antd";
import LocalizableReadonly from "../../../../components/localizableReadonly/localizableReadonly";
import moment from "moment";

export default function AchievementsList(props: achievementsListProps) {
    const columns: ColumnsType<AchievementGainedDto> = [{
        key: nameof<AchievementGainedDto>("name"),
        dataIndex: nameof<AchievementGainedDto>("name"),
        title: "Name",
        render: (names: Array<LocalizableStringDto> | null) => {
            if (!names) {
                return null;
            }
            return <>
                <LocalizableReadonly
                    value={names}
                />
            </>
        }
    }, {
        key: nameof<AchievementGainedDto>("categoryName"),
        dataIndex: nameof<AchievementGainedDto>("categoryName"),
        title: "Category",
        render: (categories: Array<LocalizableStringDto> | null) => {
            if (!categories) {
                return null;
            }
            return <>
                <LocalizableReadonly
                    value={categories}
                />
            </>
        }
    }, {
        key: nameof<AchievementGainedDto>("rewardPoints"),
        dataIndex: nameof<AchievementGainedDto>("rewardPoints"),
        title: "Points Earned"
    }, {
        key: nameof<AchievementGainedDto>("earnedDate"),
        dataIndex: nameof<AchievementGainedDto>("earnedDate"),
        title: "Date Achieved",
        render: (date: string) => {
            return <>
                {moment(date).format("YYYY-MM-DD HH:mm:ss")}
            </>
        }
    }];

    return <>
        <Table
            columns={columns}
            dataSource={props.data}
        />
    </>
}

interface achievementsListProps {
    data: AchievementGainedDto[]
}