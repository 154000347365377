import {Layout, message, Tabs} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    BrandTermApi,
    EventApi,
    EventDetailsDto, ParentEventApi, ParentEventDto, ProductApi,
    VimeoLiveEventApi, VimeoLiveEventDto
} from "../../../api";
import BrandSider from "../../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import ApiService from "../../../services/apiService";
import {RoutingPaths} from "../../routingContainer";
import EventForm from "../eventModalForm/eventForm";
import styles from "./eventEditFormContainer.module.scss";
import EventLivestreamsListContainer from "../../eventLivestreams/eventLivestreamListContainer";

const {TabPane} = Tabs;

export default function EventEditFormContainer() {
    const {eventId} = useParams<{ eventId: string }>();
    const apiService = useInjection(ApiService);
    const [event, setEvent] = useState<EventDetailsDto>();

    const [vimeoLiveEvents, setVimeoLiveEvents] = useState<Array<VimeoLiveEventDto> | null>(null);
    const [parentEvents, setParentEvents] = useState<ParentEventDto[] | null>(null);

    const [preparedTerms, setPreparedTerms] = useState<{ key: number, title: string; }[] | null>(null);
    const [assignedTerms, setAssignedTerms] = useState<{ key: number, title: string; }[] | null>(null);

    const [preparedEventProducts, setPreparedEventProducts] = useState<{ key: number, title: string }[] | null>(null);
    const [assignedEventProducts, setAssignedEventProducts] = useState<{ key: number, title: string }[] | null>(null);

    useEffect(() => {
        apiService.getApi(ParentEventApi).apiParentEventGet()
            .then(response => {
                setParentEvents(response.data);
            });

        apiService.getApi(VimeoLiveEventApi).apiVimeoLiveEventGet()
            .then(response => {
                setVimeoLiveEvents(response.data);
            });

        apiService.getApi(BrandTermApi).apiBrandTermGet()
            .then(response => {
                const brandTermsFromApi: { key: number, title: string }[] = [];
                response.data.forEach(t => {
                    brandTermsFromApi.push({
                        key: t.id!,
                        title: t.name![0].value ?? ""
                    });
                });
                setPreparedTerms(brandTermsFromApi)
            });

        apiService.getApi(ProductApi).apiProductGet()
            .then(response => {
                const productsFromApi: { key: number, title: string }[] = [];
                response.data.forEach(p => {
                    productsFromApi.push({
                        key: p.id!,
                        title: p.productCode!
                    });
                });
                setPreparedEventProducts(productsFromApi);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refreshEventDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    const onSubmit = async (value: EventDetailsDto) => {
        let eventToUpdate = value;
        eventToUpdate.brandTermsIds = eventToUpdate.brandTermsIds ?? assignedTerms?.map(t => t.key);
        eventToUpdate.eventProductsIds = eventToUpdate.eventProductsIds ?? assignedEventProducts?.map(p => p.key);

        await apiService.getApi(EventApi).apiEventIdPut(+eventId, eventToUpdate)
            .then(response => {
                if (response.request.status !== 200) {
                    message.error('Error occured while editing event');
                } else {
                    message.success('Event has been successfully edited');
                }
            })

        refreshEventDetails();
    }

    const refreshEventDetails = async () => {
        try {
            const eventDetails = await apiService.getApi(EventApi).apiEventIdGet(+eventId);
            setEvent(eventDetails.data);

            const assignedBrandTermsFromApi: { key: number, title: string; }[] = [];
            eventDetails.data.brandTerms!.forEach(t => {
                assignedBrandTermsFromApi.push({
                    key: t.id!,
                    title: t.name![0].value ?? ""
                });
            });
            setAssignedTerms(assignedBrandTermsFromApi);

            const assignedProductsFromApi: { key: number, title: string; }[] = [];
            eventDetails.data.eventProducts!.forEach(ep => {
                assignedProductsFromApi.push({
                    key: ep.id!,
                    title: ep.productCode ?? ""
                });
            });
            setAssignedEventProducts(assignedProductsFromApi);

        } catch (e) {
            message.error('Error occured while fetching event details');
        }
    }

    const onTransferEventTermChange = (targetKeys: any[]) => {
        const eventToUpdate = event!;
        eventToUpdate.brandTermsIds = targetKeys;
        onSubmit(eventToUpdate);
    }

    const onTransferEventProductChange = (targetKeys: any[]) => {
        const eventToUpdate = event!;
        eventToUpdate.eventProductsIds = targetKeys;
        onSubmit(eventToUpdate);
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Edit event with id: {eventId}
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.EventsList}/>
                    <Content className={styles.content}>
                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                Event details
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>
                        <br/>
                        {event !== undefined ?
                            <EventForm
                                parentEvents={parentEvents}
                                eventToEdit={event || null}
                                onSubmit={onSubmit}
                            /> : null}
                        {/*<EventTitlesListContainer*/}
                        {/*    eventTitles={event?.titles || null}*/}
                        {/*    refreshEventDetails={refreshEventDetails}*/}
                        {/*/>*/}

                        <EventLivestreamsListContainer
                                        eventId={eventId}
                                        vimeoLiveEvents={vimeoLiveEvents}
                                    />
                        {/* <Tabs defaultActiveKey="1" type="card">
                            <TabPane tab="Livestreams" key="1">
                                <div>
                                    <EventLivestreamsListContainer
                                        eventId={eventId}
                                        vimeoLiveEvents={vimeoLiveEvents}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Workstations" key="2">
                                <WorkstationsListContainer
                                    eventId={eventId}
                                />
                            </TabPane>
                            <TabPane tab="Terms" key="3">
                                {preparedTerms !== null && assignedTerms !== null ?
                                    <div>
                                        <CypherCmsBreadcrumb>
                                            <CypherCmsBreadcrumbItem>
                                                Event Terms
                                            </CypherCmsBreadcrumbItem>
                                        </CypherCmsBreadcrumb>
                                        <br/>
                                        <span className={styles.headerSubtitle}>
                                            Pick from the existing terms in the system, to build the terms for your event
                                        </span>
                                        <EntityTransfer
                                            className={styles.entityTransfer}
                                            onChange={onTransferEventTermChange}
                                            titles={["Available", "Assigned"]}
                                            value={assignedTerms as []}
                                            dataSource={preparedTerms as []}
                                        />
                                    </div> : null}
                            </TabPane>
                            <TabPane tab="Products" key="4">
                                {preparedEventProducts !== null && assignedEventProducts !== null ?
                                    <div>
                                        <CypherCmsBreadcrumb>
                                            <CypherCmsBreadcrumbItem>
                                                Event Products
                                            </CypherCmsBreadcrumbItem>
                                        </CypherCmsBreadcrumb>
                                        <br/>
                                        <span className={styles.headerSubtitle}>
                                            Pick from the existing products in the system, to build the products for your event
                                        </span>
                                        <EntityTransfer
                                            className={styles.entityTransfer}
                                            dataSource={preparedEventProducts as []}
                                            onChange={onTransferEventProductChange}
                                            value={assignedEventProducts as []}
                                            titles={["Available", "Assigned"]}
                                        />
                                    </div> : null}
                            </TabPane>
                        </Tabs> */}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
