import { EllipsisOutlined, PauseOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table } from "antd";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import {AdminRole, BrandAdminRoleDto, ModuleDto} from '../../../../api';
import { RoutingPaths } from "../../../routingContainer";
import {brandAdminRolesForOrgUser, userHasRole} from "../../../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../../../redux/organisationsSlice";
import {useSelector} from "react-redux";

export default function ModuleSider(props: IModuleSiderProps) {

  const orgUser = useSelector(getCurrentOrganisationUser)
  const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

  let brandAdminRoles : BrandAdminRoleDto[] = []
  if (typeof orgUser !== 'undefined') {
    brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
  }

  const history = useHistory();
  const { brandId } = useParams<{ brandId: string }>();

  const menu = (sortItem: ModuleSortDto) => (
    <Menu>
      <Menu.Item key={`${sortItem.module.id}-edit`} onClick={e => props.onItemEdit(sortItem)}>
        Edit
      </Menu.Item>
      {userHasRole(AdminRole.ContentLead, brandAdminRoles) && <Menu.Item danger key={`${sortItem.module.id}-delete`} onClick={e => props.onItemDelete(sortItem.module.id!)}>
        Delete
      </Menu.Item>}
    </Menu>
  );

  const DragHandle = SortableHandle(() => <PauseOutlined rotate={90} style={{ cursor: 'grab', color: '#999' }} />)
  const SortableItem = SortableElement((props:any) => <tr {...props}></tr>);
  const SortableList = SortableContainer((props:any) => <tbody {...props} />);

  const columns = [
    {
      title: "handle",
      render: () => <DragHandle />
    },
    {
      title: "itemtitle",
      dataIndex: 'name',
      render: (text: any, record: ModuleSortDto) => <>{record.module.name![0].value}</>
    },
    {
      title: "edit",
      render: (text: any, record: ModuleSortDto) =>
        <Dropdown overlay={menu(record)} trigger={["click"]} >
          <EllipsisOutlined onClick={e => e.stopPropagation()} />
        </Dropdown>
    }
  ];

  const DraggableContainer = (containerProps: any) => (
    <SortableList
      useDragHandle
      onSortEnd={props.onSort}
      disableAutoscroll
      helperClass="row-dragging"
      {...containerProps}
    />
  );

  const DraggableBodyRow = (rowProps: any) => {
    const index = props.dataSource.findIndex((x:any) => x.index === rowProps['data-row-key']);
    return <SortableItem index={index} {...rowProps} />;
  };

  const onRowClick = (record: ModuleSortDto, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        history.push(
          generatePath(
            RoutingPaths.CourseModuleEdit, 
            { 
              brandId: brandId,
              courseId: record.module.courseId!,
              moduleId: record.module.id!
            }
          )
        );
      }
    }
  }

  return <Table
    className="modules-sider"
    pagination={false}
    showHeader={false}
    dataSource={props.dataSource}
    columns={columns}
    rowKey="index"
    onRow={onRowClick}
    components={{
      body: {
        wrapper: DraggableContainer,
        row: DraggableBodyRow,
      },
    }}
  />;
}

interface IModuleSiderProps {
  onItemEdit: (item: ModuleSortDto) => void,
  onItemDelete: (id: number) => void,
  onSort: (sortData: SortEnd) => void,
  dataSource: ModuleSortDto[]
}

export interface ModuleSortDto {
  module: ModuleDto,
  index: number
}
