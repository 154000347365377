
import { UnifiedDndObject } from "../../picker-items/UnifiedDndObject";
import { DndSectionItemType } from "../../picker-items/DndSectionItemType";
import { DragOverlay } from "@dnd-kit/core";
import ElementPickerDragOverlay from "./elementPickerDragOverlay/elementPickerDragOverlay";
import SectionDragOverlay from "./sectionDragOverlay/sectionDragOverlay";
import { PickerDndObject } from "../../picker-items/PickerDndObject";
import { SectionDndObject } from "../../picker-items/SectionDndObject";

export default function DragOverlaySwitch(props: DragOverlaySwitchProps) {

  const render = (item: UnifiedDndObject) => {
    switch (item.typeDisclaimer) {
      case DndSectionItemType.PickerElement:
        return <ElementPickerDragOverlay item={item as PickerDndObject}/>;
      case DndSectionItemType.Section:
        return <SectionDragOverlay item={item as SectionDndObject}/>;
      case DndSectionItemType.SectionItem:
        return null;
      default:
        throw Error(`No drag'n'drop dragOverlay for type ${item.typeDisclaimer} `);
    }
  }

  return <DragOverlay>
    {render(props.item)}
  </DragOverlay>;
}

export interface DragOverlaySwitchProps {
  item: UnifiedDndObject
}
