import Layout, { Content } from 'antd/lib/layout/layout';
import { StartForgotPasswordDto } from '../../../api';
import CrudForm from '../../../components/crudForm/crudForm';
import { CrudFormItemProps, CrudFormWrapperProps } from '../../../components/crudForm/crudFormProps';
import CypherCmsBreadcrumb, { CypherCmsBreadcrumbItem } from '../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb';
import { nameof } from '../../../helpers/nameofHelper';
import styles from '../authentication.module.scss';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 4 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 4 },
};

const formItems: CrudFormItemProps[] = [{
    name: nameof<StartForgotPasswordDto>("email"),
    rules: [{ required: true, message: 'This field is required' }, { type: "email", message: "Email format is incorrect" }]
}];

export default function ForgotPassword(props: CrudFormWrapperProps<StartForgotPasswordDto>) {
    return <Layout>
        <CypherCmsBreadcrumb>
            <CypherCmsBreadcrumbItem>
                Forgot password
            </CypherCmsBreadcrumbItem>
        </CypherCmsBreadcrumb>
        <Content className={styles.content}>
            <CrudForm
                onSubmit={props.onSubmit}
                formItems={formItems}
                saveButtonTitle="Reset password"
                formProps={layout}
                tailProps={tailLayout} />
        </Content>
    </Layout>;
}