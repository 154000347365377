import {Layout, Tabs} from "antd";
import {Content} from "antd/lib/layout/layout";
import CypherCmsBreadcrumb, {
    CypherCmsBreadcrumbItem
} from "../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import {useParams} from "react-router-dom";
import {useInjection} from "inversify-react";
import ApiService from "../../../services/apiService";
import {useEffect, useState} from "react";
import {
    AchievementGainedDto, OrganisationUserApi, OrganisationUserDetailsDto,
} from "../../../api";
import UserStatsForm from "./userStatsForm";
import BrandSider from "../../../components/brandSider/brandSider";
import {RoutingPaths} from "../../routingContainer";
import styles from "./userStatsFormContainer.module.scss";
import AchievementsList from "./achievements/achievementsList";

const {TabPane} = Tabs;

export default function UserStatsFormContainer() {
    const {userId} = useParams<{ userId: string }>();
    const apiService = useInjection(ApiService);
    const [user, setUser] = useState<OrganisationUserDetailsDto>()
    const [achievements, setAchievements] = useState<AchievementGainedDto[]>()

    useEffect(() => {
        apiService.getApi(OrganisationUserApi).apiOrganisationUserIdGet(+userId)
            .then(response => {
                setUser(response.data);
            });

        apiService.getApi(OrganisationUserApi).apiOrganisationUserAchievementsUserIdGet(+userId)
            .then(response => {
                setAchievements(response.data);
            })
    }, []);

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <CypherCmsBreadcrumb>
                <CypherCmsBreadcrumbItem>
                    User: {user?.name}
                </CypherCmsBreadcrumbItem>
            </CypherCmsBreadcrumb>
            <Content>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.UsersList}/>
                    <Content className={styles.content}>
                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                User Details
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>

                        {user !== undefined ?
                            <div className={styles.details}>
                                <UserStatsForm
                                    user={user!}
                                />
                            </div> : null}

                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                User Records
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>
                        <Tabs defaultActiveKey="1" type="card" className={styles.achievements}>
                            <TabPane tab="Achievements" key="1">
                                <AchievementsList
                                    data={achievements!}
                                />
                            </TabPane>
                        </Tabs>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
