import { OrganisationUserDto, OrganisationUserUpdateDto } from "../../../../api";
import { CrudTableWrapperProps } from "../../../../components/crudTable/crudTable";
import { Collapse, Empty } from 'antd';
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import OrganisationUserEditForm from "../organsiationUserEditForm/organisationUserEditForm"
import styles from "./organisationUsersList.module.scss";

const { Panel } = Collapse;

export default function OrganisationUsersEditableList(props: OrganisationUsersEditableListProps) {

    const expandIcon = (panelProps: any) => {
        return panelProps.isActive ? <DownOutlined /> : <EditOutlined />;
    }

    return <>
    {props.data.length > 0 ?
        <Collapse accordion 
            expandIconPosition="right"
            expandIcon={expandIcon}
            className={styles.collapseWrapper}
        >
            {
                props.data?.map((item) => 
                    <Panel 
                        header={item.name} 
                        key={item.id!}
                        className={styles.panel}
                    >
                        <OrganisationUserEditForm 
                            item={item}
                            onDeleteClicked={() => props.onDeleteClicked(item.id!)}
                            onResendInvitationClick={() => props.onResendInvitationClicked(item)}
                            onResetPasswordClick={() => props.onResetPasswordClick(item)}
                            onSubmit={props.onSubmit}
                        />
                    </Panel>
                )
            }
        </Collapse>
    : <Empty />}
    </>;
}

interface OrganisationUsersEditableListProps extends CrudTableWrapperProps<OrganisationUserDto> {
    onResendInvitationClicked: (record: OrganisationUserDto) => any,
    onResetPasswordClick: (record: OrganisationUserDto) => any,
    data: OrganisationUserDto[],
    onDeleteClicked: (id: number) => any,
    onSubmit: (value: OrganisationUserUpdateDto) => Promise<any>
}
