import CrudTable, {CrudTableWrapperProps} from "../../components/crudTable/crudTable";
import {ColumnsType} from "antd/lib/table";
import {nameof} from "../../helpers/nameofHelper";
import {AdminRole, BrandAdminRoleDto, EventLivestreamDto, LocalizableStringDto} from "../../api";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import moment from "moment";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {useSelector} from "react-redux";

export default function EventLivestreamList(props: CrudTableWrapperProps<EventLivestreamDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<EventLivestreamDto> = [{
        key: nameof<EventLivestreamDto>("title"),
        render: (titles: Array<LocalizableStringDto> | null) => {
            if (!titles) {
                return null;
            }
            return <>
                <LocalizableReadonly
                    value={titles}
                />
            </>
        }
    }, {
        key: nameof<EventLivestreamDto>("description"),
        render: (descriptions: Array<LocalizableStringDto> | null) => {
            if (!descriptions) {
                return null
            }
            return <>
                <LocalizableReadonly
                    value={descriptions}
                />
            </>
        }
    }, {
        key: nameof<EventLivestreamDto>("start"),
        title: "Start",
        render: (date: string) => {
            return <>
                {moment(date).format("YYYY-MM-DD HH:mm:ss")}
            </>
        }
    }, {
        key: nameof<EventLivestreamDto>("end"),
        title: "End",
        render: (date: string) => {
            return <>
                {moment(date).format("YYYY-MM-DD HH:mm:ss")}
            </>
        },
    }, {
        key: nameof<EventLivestreamDto>("languageSpoken"),
        render: (languages: Array<LocalizableStringDto> | null) => {
            if (!languages) {
                return null
            }
            return <>
                <LocalizableReadonly
                    value={languages}
                />
            </>
        }
    }, {
        key: nameof<EventLivestreamDto>("isVisible"),
        title: " ",
        render: (isVisible: boolean) => {
            return <>
                {isVisible ?
                    <EyeOutlined style={{fontSize: '20px'}}/>
                    : <EyeInvisibleOutlined style={{fontSize: '20px'}}/>}
            </>
        }
    }];

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle="+ Add new event livestream"
        />
    </>
}