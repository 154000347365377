import {Layout, message, Tabs} from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    AchievementApi,
    AchievementDetailsDto,
    AchievementCategoryApi,
    AchievementCategoryDto
} from "../../../api";
import BrandSider from "../../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import ApiService from "../../../services/apiService";
import {RoutingPaths} from "../../routingContainer";
import AchievementForm from "../achievementModalForm/achievementForm";
import styles from "./achievementEditFormContainer.module.scss";

export default function AchievementEditFormContainer() {
    const { achievementId } = useParams<{ achievementId: string }>();
    const apiService = useInjection(ApiService);
    const [achievement, setAchievement] = useState<AchievementDetailsDto>();
    const [achievementCategories, setAchievementCategories] = useState<AchievementCategoryDto[] | null>(null);

    useEffect(() => {
        apiService.getApi(AchievementCategoryApi).apiAchievementCategoryGet()
            .then(response => {
                setAchievementCategories(response.data);
            });
    }, []);

    useEffect(() => {
        refreshAchievementDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievementId]);

    const onSubmit = async (value: AchievementDetailsDto) => {
        let achievementToUpdate = value;

        await apiService.getApi(AchievementApi).apiAchievementIdPut(+achievementId, achievementToUpdate)
            .then(response => {
                if (response.request.status !== 200) {
                    message.error('Error occured while editing achievement');
                } else {
                    message.success('Achievement has been successfully edited');
                }
            })

        refreshAchievementDetails();
    }

    const refreshAchievementDetails = async () => {
        try {
            const achievementDetails = await apiService.getApi(AchievementApi).apiAchievementIdGet(+achievementId);
            setAchievement(achievementDetails.data);
        } catch (e) {
            message.error('Error occured while fetching achievement details');
        }
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Edit achievement with id: {achievementId}
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.AchievementsList}/>
                    <Content className={styles.content}>
                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                Achievement details
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>
                        <br/>
                        {achievement !== undefined ?
                            <AchievementForm
                                achievementCategories={achievementCategories}
                                achievementToEdit={achievement || null}
                                onSubmit={onSubmit}
                            /> : null}   
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
