import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import EmptyScreenInfo from "../../components/emptyScreenInfo/emptyScreenInfo";
import ModuleEditContainer from "../modules/moduleEditContainer";
import { RoutingPaths } from "../routingContainer";
import ModuleSiderContainer from "./components/moduleSiderContainer/moduleSiderContainer";
import ModuleTopBar from "./components/moduleTopBar/moduleTopBar";

export default function CourseEditRoutingContainer() {
  const location = useLocation();
  const [isModalVisible, setModalVisible] = useState(false);

  return <>
    <Layout style={{ backgroundColor: "white" }}>
      <Content>
        <ModuleTopBar />
        <Layout>
          <Layout.Sider theme="light">
            <ModuleSiderContainer
              setModalVisible={setModalVisible}
              isModalVisible={isModalVisible} />
          </Layout.Sider>
          <Content>
            <Switch>
              {/* key is required to force refresh after route change */}
              <Route
                path={RoutingPaths.CourseModuleEdit}
                key={location.pathname}
              >
                <ModuleEditContainer />
              </Route>
              <Route path="*">
                <EmptyScreenInfo
                  text="Please select module from sidemenu or create new one."
                  buttonText="Add New Module"
                  onButtonClick={() => { setModalVisible(true); }}
                />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Content>
    </Layout>
  </>;
}
