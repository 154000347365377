import {ColumnsType} from "antd/lib/table";
import {AdminRole, BrandAdminRoleDto, LocalizableStringDto, TranslationDto} from "../../api";
import CrudTable, {CrudTableWrapperProps} from "../../components/crudTable/crudTable";
import LocalizableReadonly from "../../components/localizableReadonly/localizableReadonly";
import {nameof} from "../../helpers/nameofHelper";
import {brandAdminRolesForOrgUser, userHasRole} from "../../helpers/brandAdminRoleHelper";
import {useSelector} from "react-redux";
import {getCurrentOrganisationUser} from "../../redux/organisationsSlice";
import {getAllBrandAdminRoles} from "../../redux/brandAdminRolesSlice";

export default function TranslationsList(props: CrudTableWrapperProps<TranslationDto>) {

    const orgUser = useSelector(getCurrentOrganisationUser)
    const allBrandAdminRoles : BrandAdminRoleDto[] = useSelector(getAllBrandAdminRoles) ?? []

    let brandAdminRoles : BrandAdminRoleDto[] = []
    if (typeof orgUser !== 'undefined') {
        brandAdminRoles = brandAdminRolesForOrgUser(orgUser, allBrandAdminRoles)
    }

    const columns: ColumnsType<TranslationDto> = [{
        key: nameof<TranslationDto>("key"),
        dataIndex: nameof<TranslationDto>("key"),
        title: "Key"
    }, {
        key: nameof<TranslationDto>("value"),
        dataIndex: nameof<TranslationDto>("value"),
        title: "Value",
        render: (translations: Array<LocalizableStringDto> | null) => {
            if (!translations) {
                return null;
            }
            return <>
                <LocalizableReadonly
                    value={translations}
                />
            </>
        }
    }];

    return <>
        <CrudTable
            data={props.data}
            onAddClicked={props.onAddClicked}
            onEditClicked={props.onEditClicked}
            onDeleteClicked={userHasRole(AdminRole.ContentLead, brandAdminRoles) ? props.onDeleteClicked : undefined}
            columns={columns}
            addButtonTitle={"+ Add new translation"}
        />
    </>
}
