import { Layout, message } from "antd";
import {Content} from "antd/lib/layout/layout";
import {useInjection} from "inversify-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import { ProductApi, ProductDetailsDto } from "../../../api";
import BrandSider from "../../../components/brandSider/brandSider";
import CypherCmsBreadcrumb, {CypherCmsBreadcrumbItem} from "../../../components/cypherCmsBreadcrumb/cypherCmsBreadcrumb";
import ApiService from "../../../services/apiService";
import {RoutingPaths} from "../../routingContainer";
import ProductForm from "./productForm";
import styles from "./productEditFormContainer.module.scss";
import { useSelector } from "react-redux";
import { getGlobalLanguage } from "../../../redux/languageSlice";

export default function ProductEditFormContainer() {
    const {productId} = useParams<{ productId: string }>();
    const apiService = useInjection(ApiService);
    const [product, setProduct] = useState<ProductDetailsDto>();
    const defaultLanguage = useSelector(getGlobalLanguage);

    useEffect(() => {
        refreshProductDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    const onSubmit = async (value: ProductDetailsDto) => {
        let productToUpdate = {
            workstationSlug: value.workstationSlug,
            isNew: value.isNew,
            isAchievable: value.isAchievable,
            productAvailabilityLanguagesIds: value.productAvailabilityLanguagesIds,
            productId: product?.productId
        };
        
        await apiService.getApi(ProductApi).apiProductIdPut(+productId, productToUpdate)
            .then(response => {
                if (response.request.status !== 200) {
                    message.error('Error occured while editing product');
                } else {
                    message.success('Product has been successfully edited');
                }
            })

        refreshProductDetails();
    }

    const refreshProductDetails = async () => {
        try {
            const productDetails = await apiService.getApi(ProductApi).apiProductIdGet(+productId, { headers: { Language: defaultLanguage } });
            setProduct(productDetails.data);
        } catch (e) {
            message.error('Error occured while fetching product details');
        }
    }

    return <>
        <Layout style={{backgroundColor: "white"}}>
            <Content>
                <CypherCmsBreadcrumb>
                    <CypherCmsBreadcrumbItem>
                        Edit product with id: {productId}
                    </CypherCmsBreadcrumbItem>
                </CypherCmsBreadcrumb>
                <Layout>
                    <BrandSider selectedItem={RoutingPaths.ProductsList}/>
                    <Content className={styles.content}>
                        <CypherCmsBreadcrumb>
                            <CypherCmsBreadcrumbItem>
                                Product details
                            </CypherCmsBreadcrumbItem>
                        </CypherCmsBreadcrumb>
                        <br/>
                        {product !== undefined ?
                            <ProductForm
                                productToEdit={product || null}
                                onSubmit={onSubmit}
                            /> : null}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    </>
}
