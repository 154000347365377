import { SectionItemType } from "../../../../api";
import { DndSectionItemType } from "./DndSectionItemType";
import { UnifiedDndObject } from "./UnifiedDndObject";


export class PickerDndObject implements UnifiedDndObject {
    typeDisclaimer: DndSectionItemType = DndSectionItemType.PickerElement;
    readonly pickerElementType: SectionItemType;

    constructor(pickerElementType: SectionItemType) {
        this.pickerElementType = pickerElementType;
    }
    static create(pickerElementId: SectionItemType) {
        return JSON.stringify(new PickerDndObject(pickerElementId));
    }
}
