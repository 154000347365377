import { ExclamationCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Modal, Menu, Button, Card, Dropdown } from "antd";
import { useState } from "react";
import { OrganisationUpdateDto, OrganisationDetailsDto, UpdateVimeoTokenDto } from "../../../../../../api";
import OrganisationForm from "../../../../components/organisationForm/organisationForm";
import OrganisationView from "../../../../components/organisationView/organisationView";

export default function BrandInformactionCard(props: BrandInformactionCardProps) {

    const { confirm } = Modal;

    const [isEditBrandModeEnabled, setIsEditBrandModeEnabled] = useState(false);

    const onEditSubmit = async (value: OrganisationUpdateDto & UpdateVimeoTokenDto) => {
        props.onEditSubmit(value).then(result => {
            setIsEditBrandModeEnabled(false);
        })
    }

    const onDeleteBrandClick = () => {
        confirm({
            title: 'Are you sure delete this brand?',
            icon: <ExclamationCircleOutlined />,
            content: 'Data will be lost',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                props.deleteBrand();
            },
            onCancel() {},
        });
    }

    const brandInformationCardMenu = (
        <Menu>
            {isEditBrandModeEnabled
                ? ''
                : <Menu.Item onClick={() => setIsEditBrandModeEnabled(true)}>
                    Edit
                </Menu.Item>
            }
            <Menu.Item>
            </Menu.Item>
            <Menu.Item onClick={onDeleteBrandClick} danger> Delete</Menu.Item>
        </Menu>
    );

    const brandInformationCancelEditButton = (
        <Button
            type="default"
            onClick={() => setIsEditBrandModeEnabled(false)}
        >Cancel</Button>
    )

    return <>
        <Card
            title="Brand information"
            bordered={true}
            extra={
                <Dropdown
                    overlay={brandInformationCardMenu}
                    trigger={['click']}
                    placement="bottomRight"
                    overlayStyle={{minWidth:"100px"}}
                >
                    <Button type="text">
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            }
        >
            {isEditBrandModeEnabled
                ? <OrganisationForm
                    onSubmit={onEditSubmit}
                    initialValues={props.organisation}
                    extraActions={brandInformationCancelEditButton}
                />
                : <OrganisationView
                    organisation={props.organisation}
                />
            }
        </Card>
    </>;
}


export interface BrandInformactionCardProps {
    organisation: OrganisationDetailsDto;
    deleteBrand: () => Promise<any>;
    onEditSubmit: (value: OrganisationUpdateDto & UpdateVimeoTokenDto) => Promise<any>;
}
