import { message, Skeleton } from "antd";
import { useInjection } from "inversify-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationUserApi, OrganisationApi, OrganisationUserApi, OrganisationUserDto, OrganisationUserRole, OrganisationUserUpdateDto } from "../../../../api";
import { getOrganisationsDetails, setOrganisationDetails } from "../../../../redux/organisationsSlice";
import ApiService from "../../../../services/apiService";
import OrganisationUsersEditableList from "./organisationUsersList";
import { useDispatch } from "react-redux";

export default function OrganisationUsersListContainer() {
    const dispatch = useDispatch();
    const apiService = useInjection(ApiService);
    const { id } = useParams<{ id: string }>();
    const organisationsDetails = useSelector(getOrganisationsDetails);

    useEffect(() => {
        apiService.getApi(OrganisationApi).apiOrganisationIdGet(+id).then(o => {
            dispatch(setOrganisationDetails(o.data));
        });
    }, [apiService, dispatch, id]);

    const onDeleteClicked = (organisationUserId: number) => {
        apiService.getApi(OrganisationUserApi).apiOrganisationUserIdDelete(organisationUserId).then(o => {
            apiService.getApi(OrganisationApi).apiOrganisationIdGet(+id).then(getResponse => {
                dispatch(setOrganisationDetails(getResponse.data));
                message.info("Organisation user deleted");
            }); 
        });
    }

    const onResendInvitationClicked = (record: OrganisationUserDto) => {
        apiService.getApi(ApplicationUserApi).apiApplicationUserResendInvitationPost({ email: record.email }).then(o => {
            message.info("The invitation has been reissued.");
        });
    }

    const onResetPasswordClicked = async (record: OrganisationUserDto) => {
        apiService.getApi(ApplicationUserApi).apiApplicationUserBeginForgotPasswordPost({email: record.email}).then(o => {
            message.info("Forgot password flow triggered.");
        });
    }

    const onSubmitClicked = async (value: OrganisationUserUpdateDto) => {
        console.log("Organisation user", value);

        const organisationUserApi = apiService.getApi(OrganisationUserApi);

        await organisationUserApi.apiOrganisationUserIdPut(value.id!, value);
        apiService.getApi(OrganisationApi).apiOrganisationIdGet(+id).then(getResponse => {
            dispatch(setOrganisationDetails(getResponse.data));
            message.success("Organisation user updated");
        }); 
    }

    return <>
        {organisationsDetails && organisationsDetails[+id]
            ? (<>
                <OrganisationUsersEditableList
                    data={organisationsDetails[+id].organisationUsers!.filter(u => u.role === OrganisationUserRole.BrandAdmin)}
                    onAddClicked={() => {}}
                    onEditClicked={() => {}}
                    onDeleteClicked={onDeleteClicked}
                    onResendInvitationClicked={onResendInvitationClicked} 
                    onResetPasswordClick={onResetPasswordClicked}
                    onSubmit={onSubmitClicked}
                />
            </>) 
            : <Skeleton title={false} paragraph={{ rows: 5 }} className="ant-card-body" />               
        }
    </>;
}
