/* tslint:disable */
/* eslint-disable */
/**
 * Apptimise.CypherCms.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AchievementCategoryCreateDto
 */
export interface AchievementCategoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryCreateDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementCategoryCreateDto
     */
    badgeFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface AchievementCategoryDetailsDto
 */
export interface AchievementCategoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDetailsDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementCategoryDetailsDto
     */
    badgeFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface AchievementCategoryDto
 */
export interface AchievementCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementCategoryDto
     */
    badgeFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface AchievementCategoryUpdateDto
 */
export interface AchievementCategoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCategoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCategoryUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCategoryUpdateDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementCategoryUpdateDto
     */
    badgeFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface AchievementCreateDto
 */
export interface AchievementCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementCreateDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    rewardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    occurrencesRequired?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementCreateDto
     */
    badgeFile?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof AchievementCreateDto
     */
    achievementCategoryId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementCreateDto
     */
    isDistinct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementCreateDto
     */
    isVisible?: boolean | null;
}
/**
 * 
 * @export
 * @interface AchievementDetailsDto
 */
export interface AchievementDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementDetailsDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    rewardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    occurrencesRequired?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementDetailsDto
     */
    badgeFile?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof AchievementDetailsDto
     */
    achievementCategoryId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementDetailsDto
     */
    isDistinct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementDetailsDto
     */
    isVisible?: boolean | null;
}
/**
 * 
 * @export
 * @interface AchievementDto
 */
export interface AchievementDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    rewardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    occurrencesRequired?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementDto
     */
    badgeFile?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof AchievementDto
     */
    achievementCategoryId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementDto
     */
    isDistinct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementDto
     */
    isVisible?: boolean | null;
}
/**
 * 
 * @export
 * @interface AchievementGainedDto
 */
export interface AchievementGainedDto {
    /**
     * 
     * @type {string}
     * @memberof AchievementGainedDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementGainedDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementGainedDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementGainedDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementGainedDto
     */
    achievementCategoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementGainedDto
     */
    achievementId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementGainedDto
     */
    earnedDate?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementGainedDto
     */
    categoryName?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementGainedDto
     */
    rewardPoints?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementGainedDto
     */
    isDistinct?: boolean;
}
/**
 * 
 * @export
 * @interface AchievementHistoryEventDto
 */
export interface AchievementHistoryEventDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementHistoryEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementHistoryEventDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementHistoryEventDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementHistoryEventDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementHistoryEventDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementHistoryEventDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementHistoryEventDto
     */
    key?: string | null;
}
/**
 * 
 * @export
 * @interface AchievementProgressDto
 */
export interface AchievementProgressDto {
    /**
     * 
     * @type {AchievementDto}
     * @memberof AchievementProgressDto
     */
    achievement?: AchievementDto;
    /**
     * 
     * @type {number}
     * @memberof AchievementProgressDto
     */
    occurences?: number;
}
/**
 * 
 * @export
 * @interface AchievementUpdateDto
 */
export interface AchievementUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AchievementUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AchievementUpdateDto
     */
    eventType?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof AchievementUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    rewardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    occurrencesRequired?: number;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    badgeFileId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof AchievementUpdateDto
     */
    badgeFile?: FileInfoDto;
    /**
     * 
     * @type {number}
     * @memberof AchievementUpdateDto
     */
    achievementCategoryId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementUpdateDto
     */
    isDistinct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AchievementUpdateDto
     */
    isVisible?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AdminRole {
    ContentLead = 'ContentLead',
    EventEditor = 'EventEditor',
    LivestreamViewer = 'LivestreamViewer',
    ProductViewer = 'ProductViewer',
    TranslationEditor = 'TranslationEditor',
    TermsEditor = 'TermsEditor',
    ElearningEditor = 'ElearningEditor',
    VideoViewer = 'VideoViewer',
    ReportsViewer = 'ReportsViewer',
    AchievementsEditor = 'AchievementsEditor',
    CalendarManager = 'CalendarManager'
}

/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {Array<OrganisationUserDto>}
     * @memberof ApplicationUserDetailsDto
     */
    organisationUsers?: Array<OrganisationUserDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    cultureCode?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    isKenticoUser?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    achievementScore?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    cultureCode?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    withInvitation?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserResendInvitationDto
 */
export interface ApplicationUserResendInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserResendInvitationDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin'
}

/**
 * 
 * @export
 * @interface BrandAdminRoleDetailsDto
 */
export interface BrandAdminRoleDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandAdminRoleDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandAdminRoleDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {AdminRole}
     * @memberof BrandAdminRoleDetailsDto
     */
    role?: AdminRole;
    /**
     * 
     * @type {Array<OrganisationUserDto>}
     * @memberof BrandAdminRoleDetailsDto
     */
    organisationUsers?: Array<OrganisationUserDto> | null;
}
/**
 * 
 * @export
 * @interface BrandAdminRoleDto
 */
export interface BrandAdminRoleDto {
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandAdminRoleDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandAdminRoleDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandAdminRoleDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {AdminRole}
     * @memberof BrandAdminRoleDto
     */
    role?: AdminRole;
}
/**
 * 
 * @export
 * @interface BrandTermCreateDto
 */
export interface BrandTermCreateDto {
    /**
     * 
     * @type {number}
     * @memberof BrandTermCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandTermCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandTermCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermCreateDto
     */
    content?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface BrandTermDetailsDto
 */
export interface BrandTermDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof BrandTermDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandTermDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandTermDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermDetailsDto
     */
    content?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<EventDto>}
     * @memberof BrandTermDetailsDto
     */
    events?: Array<EventDto> | null;
}
/**
 * 
 * @export
 * @interface BrandTermDto
 */
export interface BrandTermDto {
    /**
     * 
     * @type {number}
     * @memberof BrandTermDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandTermDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandTermDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermDto
     */
    content?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface BrandTermUpdateDto
 */
export interface BrandTermUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof BrandTermUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandTermUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandTermUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandTermUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof BrandTermUpdateDto
     */
    content?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface CalendarCreateDto
 */
export interface CalendarCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarCreateDto
     */
    date?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarCreateDto
     */
    ctaUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarCreateDto
     */
    ctaTitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarCreateDto
     */
    ctaOpensNewTab?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarDetailsDto
 */
export interface CalendarDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDetailsDto
     */
    ctaUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDetailsDto
     */
    ctaTitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarDetailsDto
     */
    ctaOpensNewTab?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarDto
 */
export interface CalendarDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarDto
     */
    date?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDto
     */
    ctaUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarDto
     */
    ctaTitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarDto
     */
    ctaOpensNewTab?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarUpdateDto
 */
export interface CalendarUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarUpdateDto
     */
    ctaUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CalendarUpdateDto
     */
    ctaTitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarUpdateDto
     */
    ctaOpensNewTab?: boolean;
}
/**
 * 
 * @export
 * @interface ContactCreateDto
 */
export interface ContactCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ContactCreateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactCreateDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactCreateDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactCreateDto
     */
    culture?: string | null;
}
/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {number}
     * @memberof ContactDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    uid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    updateDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    postCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    www?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    mobile?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactDto
     */
    rating?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactDto
     */
    score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    tags?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    optInDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    status?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ContactDto
     */
    custom?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface CourseCreateDto
 */
export interface CourseCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CourseCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CourseCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CourseCreateDto
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseCreateDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof CourseCreateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @interface CourseDetailsDto
 */
export interface CourseDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CourseDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CourseDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDetailsDto
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDetailsDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof CourseDetailsDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<ModuleDto>}
     * @memberof CourseDetailsDto
     */
    modules?: Array<ModuleDto> | null;
}
/**
 * 
 * @export
 * @interface CourseDto
 */
export interface CourseDto {
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CourseDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof CourseDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @interface CourseUpdateDto
 */
export interface CourseUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CourseUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CourseUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof CourseUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdateDto
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CourseUpdateDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof CourseUpdateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @interface DynamicRowCreateDto
 */
export interface DynamicRowCreateDto {
    /**
     * 
     * @type {string}
     * @memberof DynamicRowCreateDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicRowCreateDto
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface DynamicRowDto
 */
export interface DynamicRowDto {
    /**
     * 
     * @type {string}
     * @memberof DynamicRowDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicRowDto
     */
    primary_key?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DynamicRowDto
     */
    contact_id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DynamicRowDto
     */
    data?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface EmbedDto
 */
export interface EmbedDto {
    /**
     * 
     * @type {string}
     * @memberof EmbedDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmbedDto
     */
    html?: string | null;
}
/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface EventCreateDto
 */
export interface EventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventCreateDto
     */
    mpVideo?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventCreateDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCreateDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventCreateDto
     */
    parentEventId: number;
    /**
     * 
     * @type {ParentEventDto}
     * @memberof EventCreateDto
     */
    parentEvent?: ParentEventDto;
}
/**
 * 
 * @export
 * @interface EventDetailsDto
 */
export interface EventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventDetailsDto
     */
    mpVideo?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDetailsDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDetailsDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetailsDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDetailsDto
     */
    parentEventId: number;
    /**
     * 
     * @type {ParentEventDto}
     * @memberof EventDetailsDto
     */
    parentEvent?: ParentEventDto;
    /**
     * 
     * @type {Array<WorkStationDto>}
     * @memberof EventDetailsDto
     */
    workStations?: Array<WorkStationDto> | null;
    /**
     * 
     * @type {Array<EventLivestreamDetailsDto>}
     * @memberof EventDetailsDto
     */
    eventLivestreams?: Array<EventLivestreamDetailsDto> | null;
    /**
     * 
     * @type {Array<BrandTermDto>}
     * @memberof EventDetailsDto
     */
    brandTerms?: Array<BrandTermDto> | null;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof EventDetailsDto
     */
    eventProducts?: Array<ProductDto> | null;
}
/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventDto
     */
    mpVideo?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    parentEventId: number;
    /**
     * 
     * @type {ParentEventDto}
     * @memberof EventDto
     */
    parentEvent?: ParentEventDto;
}
/**
 * 
 * @export
 * @interface EventLivestreamCreateDto
 */
export interface EventLivestreamCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamCreateDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamCreateDto
     */
    end?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamCreateDto
     */
    languageSpoken?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventLivestreamCreateDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof EventLivestreamCreateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamCreateDto
     */
    vimeoLiveEventId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamCreateDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventLivestreamDetailsDto
 */
export interface EventLivestreamDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDetailsDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDetailsDto
     */
    end?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDetailsDto
     */
    languageSpoken?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventLivestreamDetailsDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof EventLivestreamDetailsDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDetailsDto
     */
    vimeoLiveEventId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDetailsDto
     */
    eventId?: number;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof EventLivestreamDetailsDto
     */
    eventLivestreamFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface EventLivestreamDto
 */
export interface EventLivestreamDto {
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamDto
     */
    end?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamDto
     */
    languageSpoken?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventLivestreamDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof EventLivestreamDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDto
     */
    vimeoLiveEventId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventLivestreamUpdateDto
 */
export interface EventLivestreamUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamUpdateDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EventLivestreamUpdateDto
     */
    end?: string;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventLivestreamUpdateDto
     */
    languageSpoken?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventLivestreamUpdateDto
     */
    isVisible?: boolean;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof EventLivestreamUpdateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamUpdateDto
     */
    vimeoLiveEventId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventLivestreamUpdateDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventTitleCreateDto
 */
export interface EventTitleCreateDto {
    /**
     * 
     * @type {number}
     * @memberof EventTitleCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTitleCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleCreateDto
     */
    subtitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleCreateDto
     */
    content?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleCreateDto
     */
    image?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleCreateDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventTitleDetailsDto
 */
export interface EventTitleDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof EventTitleDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDetailsDto
     */
    subtitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDetailsDto
     */
    content?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDetailsDto
     */
    image?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDetailsDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventTitleDto
 */
export interface EventTitleDto {
    /**
     * 
     * @type {number}
     * @memberof EventTitleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDto
     */
    subtitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDto
     */
    content?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleDto
     */
    image?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventTitleUpdateDto
 */
export interface EventTitleUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EventTitleUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventTitleUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventTitleUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleUpdateDto
     */
    subtitle?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleUpdateDto
     */
    content?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventTitleUpdateDto
     */
    image?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof EventTitleUpdateDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface EventUpdateDto
 */
export interface EventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof EventUpdateDto
     */
    mpVideo?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventUpdateDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventUpdateDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EventUpdateDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventUpdateDto
     */
    parentEventId: number;
    /**
     * 
     * @type {ParentEventDto}
     * @memberof EventUpdateDto
     */
    parentEvent?: ParentEventDto;
}
/**
 * 
 * @export
 * @interface FileInfoDto
 */
export interface FileInfoDto {
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoDto
     */
    name?: string | null;
    /**
     * 
     * @type {FileType}
     * @memberof FileInfoDto
     */
    fileType?: FileType;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfoDto
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface FileInfoDtoLocalizableEntityDto
 */
export interface FileInfoDtoLocalizableEntityDto {
    /**
     * 
     * @type {string}
     * @memberof FileInfoDtoLocalizableEntityDto
     */
    locale?: string | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof FileInfoDtoLocalizableEntityDto
     */
    value?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface FileInfoUpdateDto
 */
export interface FileInfoUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDto
     */
    blobStorageName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfoUpdateDto
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface FileInfoUpdateDtoLocalizableEntityDto
 */
export interface FileInfoUpdateDtoLocalizableEntityDto {
    /**
     * 
     * @type {string}
     * @memberof FileInfoUpdateDtoLocalizableEntityDto
     */
    locale?: string | null;
    /**
     * 
     * @type {FileInfoUpdateDto}
     * @memberof FileInfoUpdateDtoLocalizableEntityDto
     */
    value?: FileInfoUpdateDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FileType {
    Unknown = 'Unknown',
    Image = 'Image',
    Document = 'Document'
}

/**
 * 
 * @export
 * @interface KenticoForgottenPassword
 */
export interface KenticoForgottenPassword {
    /**
     * 
     * @type {string}
     * @memberof KenticoForgottenPassword
     */
    emailAddress?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoGetUserInfoDto
 */
export interface KenticoGetUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof KenticoGetUserInfoDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoLogoutDto
 */
export interface KenticoLogoutDto {
    /**
     * 
     * @type {string}
     * @memberof KenticoLogoutDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoRegisterDto
 */
export interface KenticoRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof KenticoRegisterDto
     */
    userType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KenticoRegisterDto
     */
    isProspect?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    dialingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    languageCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    trade?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KenticoRegisterDto
     */
    interests?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    numberOfEmployees?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    spendOnTools?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    hasJssRepresentative?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KenticoRegisterDto
     */
    acceptedMarketing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KenticoRegisterDto
     */
    acceptedHubMarketing?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KenticoRegisterDto
     */
    organisationId: number;
    /**
     * 
     * @type {string}
     * @memberof KenticoRegisterDto
     */
    captchaToken: string;
}
/**
 * 
 * @export
 * @interface KenticoResetPassword
 */
export interface KenticoResetPassword {
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPassword
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPassword
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPassword
     */
    resetToken?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoResetPasswordWithToken
 */
export interface KenticoResetPasswordWithToken {
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPasswordWithToken
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPasswordWithToken
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoResetPasswordWithToken
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoWorkstation
 */
export interface KenticoWorkstation {
    /**
     * 
     * @type {number}
     * @memberof KenticoWorkstation
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof KenticoWorkstation
     */
    cultureCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoWorkstation
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoWorkstation
     */
    workstation?: string | null;
}
/**
 * 
 * @export
 * @interface KenticoWorkstationBase
 */
export interface KenticoWorkstationBase {
    /**
     * 
     * @type {number}
     * @memberof KenticoWorkstationBase
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof KenticoWorkstationBase
     */
    cultureCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KenticoWorkstationBase
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface LanguageCreateDto
 */
export interface LanguageCreateDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LanguageCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageCreateDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface LanguageDetailsDto
 */
export interface LanguageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LanguageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageDetailsDto
     */
    locale?: string | null;
    /**
     * 
     * @type {Array<OrganisationUserDto>}
     * @memberof LanguageDetailsDto
     */
    organisationUsers?: Array<OrganisationUserDto> | null;
}
/**
 * 
 * @export
 * @interface LanguageDto
 */
export interface LanguageDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface LanguageUpdateDto
 */
export interface LanguageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LanguageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageUpdateDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface LiveDto
 */
export interface LiveDto {
    /**
     * 
     * @type {number}
     * @memberof LiveDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LiveDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LiveDto
     */
    rtmpsLink?: string | null;
}
/**
 * 
 * @export
 * @interface LocalizableStringDto
 */
export interface LocalizableStringDto {
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocalizableStringDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface MissingLanguageDto
 */
export interface MissingLanguageDto {
    /**
     * 
     * @type {number}
     * @memberof MissingLanguageDto
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof MissingLanguageDto
     */
    entityType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissingLanguageDto
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissingLanguageDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissingLanguageDto
     */
    data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissingLanguageDto
     */
    missingLocales?: string | null;
}
/**
 * 
 * @export
 * @interface ModuleCreateDto
 */
export interface ModuleCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ModuleCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleCreateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleCreateDto
     */
    courseId?: number;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof ModuleCreateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @interface ModuleDetailsDto
 */
export interface ModuleDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ModuleDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDetailsDto
     */
    courseId?: number;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof ModuleDetailsDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {Array<SectionDto>}
     * @memberof ModuleDetailsDto
     */
    sections?: Array<SectionDto> | null;
}
/**
 * 
 * @export
 * @interface ModuleDto
 */
export interface ModuleDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ModuleDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    slug?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    courseId?: number;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof ModuleDto
     */
    image?: Array<FileInfoDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @interface ModuleUpdateDto
 */
export interface ModuleUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ModuleUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ModuleUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleUpdateDto
     */
    courseId?: number;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof ModuleUpdateDto
     */
    image?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface OrganisationCreateDto
 */
export interface OrganisationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    secondaryColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    catalogFileId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    tetriaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    vimeoUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    vimeoToken?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationDetailsDto
 */
export interface OrganisationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    secondaryColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    catalogFileId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    tetriaryColor?: string | null;
    /**
     * 
     * @type {Array<OrganisationUserDto>}
     * @memberof OrganisationDetailsDto
     */
    organisationUsers?: Array<OrganisationUserDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    vimeoUserId?: string | null;
    /**
     * 
     * @type {FileInfoDto}
     * @memberof OrganisationDetailsDto
     */
    catalogFile?: FileInfoDto;
}
/**
 * 
 * @export
 * @interface OrganisationDto
 */
export interface OrganisationDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    secondaryColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    catalogFileId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    tetriaryColor?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationUpdateDto
 */
export interface OrganisationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    primaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    secondaryColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    catalogFileId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    tetriaryColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    vimeoUserId?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserCreateDto
 */
export interface OrganisationUserCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {OrganisationUserRole}
     * @memberof OrganisationUserCreateDto
     */
    role?: OrganisationUserRole;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationUserCreateDto
     */
    isActivated?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserCreateDto
     */
    availableLocalesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserCreateDto
     */
    brandAdminRolesIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    cultureCode?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserDetailsDto
 */
export interface OrganisationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {OrganisationUserRole}
     * @memberof OrganisationUserDetailsDto
     */
    role?: OrganisationUserRole;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationUserDetailsDto
     */
    isActivated?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserDetailsDto
     */
    availableLocalesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserDetailsDto
     */
    brandAdminRolesIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    cultureCode?: string | null;
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof OrganisationUserDetailsDto
     */
    availableLocales?: Array<LanguageDto> | null;
    /**
     * 
     * @type {Array<BrandAdminRoleDto>}
     * @memberof OrganisationUserDetailsDto
     */
    brandAdminRoles?: Array<BrandAdminRoleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    achievementScore?: number;
}
/**
 * 
 * @export
 * @interface OrganisationUserDto
 */
export interface OrganisationUserDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {OrganisationUserRole}
     * @memberof OrganisationUserDto
     */
    role?: OrganisationUserRole;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationUserDto
     */
    isActivated?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserDto
     */
    availableLocalesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserDto
     */
    brandAdminRolesIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    cultureCode?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserInvitationDto
 */
export interface OrganisationUserInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserInvitationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserInvitationDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserInvitationDto
     */
    organisationId?: number;
    /**
     * 
     * @type {OrganisationUserRole}
     * @memberof OrganisationUserInvitationDto
     */
    role?: OrganisationUserRole;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserInvitationDto
     */
    availableLocalesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserInvitationDto
     */
    brandAdminRolesIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganisationUserRole {
    BrandAdmin = 'BrandAdmin',
    Jss = 'JSS',
    Customer = 'Customer',
    Business = 'Business',
    EndUser = 'EndUser',
    Staff = 'Staff',
    Store = 'Store'
}

/**
 * 
 * @export
 * @interface OrganisationUserUpdateDto
 */
export interface OrganisationUserUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {OrganisationUserRole}
     * @memberof OrganisationUserUpdateDto
     */
    role?: OrganisationUserRole;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationUserUpdateDto
     */
    isActivated?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserUpdateDto
     */
    availableLocalesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrganisationUserUpdateDto
     */
    brandAdminRolesIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    cultureCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface ParentEventCreateDto
 */
export interface ParentEventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ParentEventCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentEventCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParentEventCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<EventTitleDto>}
     * @memberof ParentEventCreateDto
     */
    titles?: Array<EventTitleDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventCreateDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ParentEventCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventCreateDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventCreateDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventCreateDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentEventCreateDto
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ParentEventDetailsDto
 */
export interface ParentEventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ParentEventDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentEventDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParentEventDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<EventTitleDto>}
     * @memberof ParentEventDetailsDto
     */
    titles?: Array<EventTitleDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDetailsDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ParentEventDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventDetailsDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventDetailsDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDetailsDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDetailsDto
     */
    endDate?: string;
    /**
     * 
     * @type {Array<WorkStationDto>}
     * @memberof ParentEventDetailsDto
     */
    workStations?: Array<WorkStationDto> | null;
    /**
     * 
     * @type {Array<EventLivestreamDetailsDto>}
     * @memberof ParentEventDetailsDto
     */
    eventLivestreams?: Array<EventLivestreamDetailsDto> | null;
    /**
     * 
     * @type {Array<BrandTermDto>}
     * @memberof ParentEventDetailsDto
     */
    brandTerms?: Array<BrandTermDto> | null;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ParentEventDetailsDto
     */
    eventProducts?: Array<ProductDto> | null;
}
/**
 * 
 * @export
 * @interface ParentEventDto
 */
export interface ParentEventDto {
    /**
     * 
     * @type {number}
     * @memberof ParentEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentEventDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParentEventDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<EventTitleDto>}
     * @memberof ParentEventDto
     */
    titles?: Array<EventTitleDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ParentEventDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentEventDto
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ParentEventUpdateDto
 */
export interface ParentEventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ParentEventUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ParentEventUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ParentEventUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParentEventUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<EventTitleDto>}
     * @memberof ParentEventUpdateDto
     */
    titles?: Array<EventTitleDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventUpdateDto
     */
    pixelStreamUrl?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ParentEventUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventUpdateDto
     */
    brandTermsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParentEventUpdateDto
     */
    eventProductsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ParentEventUpdateDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentEventUpdateDto
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAvailabilityLanguageCreateDto
 */
export interface ProductAvailabilityLanguageCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageCreateDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAvailabilityLanguageDetailsDto
 */
export interface ProductAvailabilityLanguageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    locale?: string | null;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ProductAvailabilityLanguageDetailsDto
     */
    products?: Array<ProductDto> | null;
}
/**
 * 
 * @export
 * @interface ProductAvailabilityLanguageDto
 */
export interface ProductAvailabilityLanguageDto {
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAvailabilityLanguageUpdateDto
 */
export interface ProductAvailabilityLanguageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAvailabilityLanguageUpdateDto
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCreateDto
 */
export interface ProductCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    productCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    workstationSlug?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateDto
     */
    isNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateDto
     */
    isAchievable?: boolean;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductCreateDto
     */
    data?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    productAvailabilityLanguagesIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductDetailsDto
 */
export interface ProductDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    productCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    workstationSlug?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    isNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    isAchievable?: boolean;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductDetailsDto
     */
    data?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    productAvailabilityLanguagesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EventDto>}
     * @memberof ProductDetailsDto
     */
    events?: Array<EventDto> | null;
    /**
     * 
     * @type {Array<ProductAvailabilityLanguageDto>}
     * @memberof ProductDetailsDto
     */
    productAvailabilityLanguages?: Array<ProductAvailabilityLanguageDto> | null;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    workstationSlug?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isAchievable?: boolean;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductDto
     */
    data?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    productAvailabilityLanguagesIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductUpdateDto
 */
export interface ProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    productCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    workstationSlug?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateDto
     */
    isNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateDto
     */
    isAchievable?: boolean;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof ProductUpdateDto
     */
    data?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    productAvailabilityLanguagesIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ReCaptchaDto
 */
export interface ReCaptchaDto {
    /**
     * 
     * @type {number}
     * @memberof ReCaptchaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReCaptchaDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ReCaptchaDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ReCaptchaDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReCaptchaDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReCaptchaDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterB2BDto
 */
export interface RegisterB2BDto {
    /**
     * 
     * @type {number}
     * @memberof RegisterB2BDto
     */
    organisationId: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterB2BDto
     */
    userType?: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    numberOfEmployees?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    trade?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterB2BDto
     */
    acceptedMarketing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterB2BDto
     */
    hasSalesman?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    dialingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    dealer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2BDto
     */
    cultureCode?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterB2CDto
 */
export interface RegisterB2CDto {
    /**
     * 
     * @type {number}
     * @memberof RegisterB2CDto
     */
    organisationId: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2CDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2CDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2CDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterB2CDto
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface SectionCreateDto
 */
export interface SectionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionCreateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SectionCreateDto
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionCreateDto
     */
    moduleId: number;
}
/**
 * 
 * @export
 * @interface SectionDetailsDto
 */
export interface SectionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionDetailsDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    moduleId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SectionDetailsDto
     */
    sectionItemsOrder?: Array<number> | null;
    /**
     * 
     * @type {Array<SectionTextItemDetailsDto>}
     * @memberof SectionDetailsDto
     */
    sectionTextItems?: Array<SectionTextItemDetailsDto> | null;
    /**
     * 
     * @type {Array<SectionFileItemDetailsDto>}
     * @memberof SectionDetailsDto
     */
    sectionFileItems?: Array<SectionFileItemDetailsDto> | null;
    /**
     * 
     * @type {Array<SectionVideoItemDetailsDto>}
     * @memberof SectionDetailsDto
     */
    sectionVideoItems?: Array<SectionVideoItemDetailsDto> | null;
    /**
     * 
     * @type {Array<SectionScormItemDetailsDto>}
     * @memberof SectionDetailsDto
     */
    sectionScormItems?: Array<SectionScormItemDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface SectionDto
 */
export interface SectionDto {
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDto
     */
    moduleId: number;
}
/**
 * 
 * @export
 * @interface SectionFileItemCreateDto
 */
export interface SectionFileItemCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemCreateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemCreateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof SectionFileItemCreateDto
     */
    files?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionFileItemCreateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionFileItemDetailsDto
 */
export interface SectionFileItemDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDetailsDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof SectionFileItemDetailsDto
     */
    files?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionFileItemDetailsDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionFileItemDto
 */
export interface SectionFileItemDto {
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoDtoLocalizableEntityDto>}
     * @memberof SectionFileItemDto
     */
    files?: Array<FileInfoDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionFileItemDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionFileItemUpdateDto
 */
export interface SectionFileItemUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemUpdateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionFileItemUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFileItemUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<FileInfoUpdateDtoLocalizableEntityDto>}
     * @memberof SectionFileItemUpdateDto
     */
    files?: Array<FileInfoUpdateDtoLocalizableEntityDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionFileItemUpdateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SectionItemType {
    Video = 'Video',
    Text = 'Text',
    Scorm = 'Scorm',
    File = 'File'
}

/**
 * 
 * @export
 * @interface SectionScormItemCreateDto
 */
export interface SectionScormItemCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemCreateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemCreateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionScormItemCreateDto
     */
    scorm?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionScormItemCreateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionScormItemDetailsDto
 */
export interface SectionScormItemDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDetailsDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionScormItemDetailsDto
     */
    scorm?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionScormItemDetailsDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionScormItemDto
 */
export interface SectionScormItemDto {
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionScormItemDto
     */
    scorm?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionScormItemDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionScormItemUpdateDto
 */
export interface SectionScormItemUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemUpdateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionScormItemUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionScormItemUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionScormItemUpdateDto
     */
    scorm?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionScormItemUpdateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionTextItemCreateDto
 */
export interface SectionTextItemCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemCreateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemCreateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionTextItemCreateDto
     */
    text?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionTextItemCreateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionTextItemDetailsDto
 */
export interface SectionTextItemDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDetailsDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionTextItemDetailsDto
     */
    text?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionTextItemDetailsDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionTextItemDto
 */
export interface SectionTextItemDto {
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionTextItemDto
     */
    text?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionTextItemDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionTextItemUpdateDto
 */
export interface SectionTextItemUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemUpdateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionTextItemUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionTextItemUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionTextItemUpdateDto
     */
    text?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionTextItemUpdateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionUpdateDto
 */
export interface SectionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionUpdateDto
     */
    title?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionUpdateDto
     */
    moduleId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SectionUpdateDto
     */
    sectionItemsOrder?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SectionVideoItemCreateDto
 */
export interface SectionVideoItemCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemCreateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemCreateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionVideoItemCreateDto
     */
    videoUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionVideoItemCreateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionVideoItemDetailsDto
 */
export interface SectionVideoItemDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDetailsDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDetailsDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionVideoItemDetailsDto
     */
    videoUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionVideoItemDetailsDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionVideoItemDto
 */
export interface SectionVideoItemDto {
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionVideoItemDto
     */
    videoUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionVideoItemDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SectionVideoItemUpdateDto
 */
export interface SectionVideoItemUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemUpdateDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionVideoItemUpdateDto
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionVideoItemUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof SectionVideoItemUpdateDto
     */
    videoUrl?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {SectionItemType}
     * @memberof SectionVideoItemUpdateDto
     */
    sectionItemType?: SectionItemType;
}
/**
 * 
 * @export
 * @interface SendTriggerCampaignToContactsDto
 */
export interface SendTriggerCampaignToContactsDto {
    /**
     * 
     * @type {string}
     * @memberof SendTriggerCampaignToContactsDto
     */
    campaign_id?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendTriggerCampaignToContactsDto
     */
    contact_ids?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SendTriggerCampaignToDynamicRowsDto
 */
export interface SendTriggerCampaignToDynamicRowsDto {
    /**
     * 
     * @type {string}
     * @memberof SendTriggerCampaignToDynamicRowsDto
     */
    campaign_id?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendTriggerCampaignToDynamicRowsDto
     */
    dynamic_row_ids?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SetCurrentOrganisationDto
 */
export interface SetCurrentOrganisationDto {
    /**
     * 
     * @type {number}
     * @memberof SetCurrentOrganisationDto
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface SocialEmbedCreateDto
 */
export interface SocialEmbedCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedCreateDto
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface SocialEmbedDetailsDto
 */
export interface SocialEmbedDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDetailsDto
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface SocialEmbedDto
 */
export interface SocialEmbedDto {
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedDto
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface SocialEmbedUpdateDto
 */
export interface SocialEmbedUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SocialEmbedUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocialEmbedUpdateDto
     */
    imageUrl?: string | null;
}
/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface StreamableClipDto
 */
export interface StreamableClipDto {
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreamableClipDto
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof StreamableClipDto
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    modifiedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof StreamableClipDto
     */
    releaseTime?: string;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof StreamableClipDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof StreamableClipDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {LiveDto}
     * @memberof StreamableClipDto
     */
    live?: LiveDto;
}
/**
 * 
 * @export
 * @interface TagDto
 */
export interface TagDto {
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface TranslationCreateDto
 */
export interface TranslationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof TranslationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TranslationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationCreateDto
     */
    key?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TranslationCreateDto
     */
    value?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface TranslationDetailsDto
 */
export interface TranslationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TranslationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TranslationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationDetailsDto
     */
    key?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TranslationDetailsDto
     */
    value?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface TranslationDto
 */
export interface TranslationDto {
    /**
     * 
     * @type {number}
     * @memberof TranslationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TranslationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    key?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TranslationDto
     */
    value?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface TranslationUpdateDto
 */
export interface TranslationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TranslationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TranslationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TranslationUpdateDto
     */
    key?: string | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof TranslationUpdateDto
     */
    value?: Array<LocalizableStringDto> | null;
}
/**
 * 
 * @export
 * @interface UpdateVimeoTokenDto
 */
export interface UpdateVimeoTokenDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateVimeoTokenDto
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateVimeoTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface VideoDownloadDto
 */
export interface VideoDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof VideoDownloadDto
     */
    quality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoDownloadDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VideoDownloadDto
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDownloadDto
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoDownloadDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoDownloadDto
     */
    expires?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoDownloadDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoDownloadDto
     */
    publicName?: string | null;
}
/**
 * 
 * @export
 * @interface VideoFileDto
 */
export interface VideoFileDto {
    /**
     * 
     * @type {string}
     * @memberof VideoFileDto
     */
    quality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoFileDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VideoFileDto
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileDto
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoFileDto
     */
    link?: string | null;
}
/**
 * 
 * @export
 * @interface VimeoLiveEventCreateDto
 */
export interface VimeoLiveEventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    uri?: string | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof VimeoLiveEventCreateDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    streamKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventCreateDto
     */
    streamPassword?: string | null;
    /**
     * 
     * @type {StreamableClipDto}
     * @memberof VimeoLiveEventCreateDto
     */
    streamableClip?: StreamableClipDto;
}
/**
 * 
 * @export
 * @interface VimeoLiveEventDetailsDto
 */
export interface VimeoLiveEventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    uri?: string | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof VimeoLiveEventDetailsDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    streamKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDetailsDto
     */
    streamPassword?: string | null;
    /**
     * 
     * @type {StreamableClipDto}
     * @memberof VimeoLiveEventDetailsDto
     */
    streamableClip?: StreamableClipDto;
    /**
     * 
     * @type {Array<EventLivestreamDto>}
     * @memberof VimeoLiveEventDetailsDto
     */
    eventLivestreams?: Array<EventLivestreamDto> | null;
}
/**
 * 
 * @export
 * @interface VimeoLiveEventDto
 */
export interface VimeoLiveEventDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    uri?: string | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof VimeoLiveEventDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    streamKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventDto
     */
    streamPassword?: string | null;
    /**
     * 
     * @type {StreamableClipDto}
     * @memberof VimeoLiveEventDto
     */
    streamableClip?: StreamableClipDto;
}
/**
 * 
 * @export
 * @interface VimeoLiveEventUpdateDto
 */
export interface VimeoLiveEventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoLiveEventUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    uri?: string | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof VimeoLiveEventUpdateDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    streamKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoLiveEventUpdateDto
     */
    streamPassword?: string | null;
    /**
     * 
     * @type {StreamableClipDto}
     * @memberof VimeoLiveEventUpdateDto
     */
    streamableClip?: StreamableClipDto;
}
/**
 * 
 * @export
 * @interface VimeoVideoCreateDto
 */
export interface VimeoVideoCreateDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoCreateDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof VimeoVideoCreateDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<VideoFileDto>}
     * @memberof VimeoVideoCreateDto
     */
    files?: Array<VideoFileDto> | null;
}
/**
 * 
 * @export
 * @interface VimeoVideoDetailsDto
 */
export interface VimeoVideoDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDetailsDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof VimeoVideoDetailsDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<VideoFileDto>}
     * @memberof VimeoVideoDetailsDto
     */
    files?: Array<VideoFileDto> | null;
    /**
     * 
     * @type {Array<VideoDownloadDto>}
     * @memberof VimeoVideoDetailsDto
     */
    download?: Array<VideoDownloadDto> | null;
    /**
     * 
     * @type {EmbedDto}
     * @memberof VimeoVideoDetailsDto
     */
    embed?: EmbedDto;
    /**
     * 
     * @type {Array<WorkStationDto>}
     * @memberof VimeoVideoDetailsDto
     */
    workStations?: Array<WorkStationDto> | null;
}
/**
 * 
 * @export
 * @interface VimeoVideoDto
 */
export interface VimeoVideoDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof VimeoVideoDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<VideoFileDto>}
     * @memberof VimeoVideoDto
     */
    files?: Array<VideoFileDto> | null;
}
/**
 * 
 * @export
 * @interface VimeoVideoUpdateDto
 */
export interface VimeoVideoUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideoUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoUpdateDto
     */
    uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof VimeoVideoUpdateDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {Array<VideoFileDto>}
     * @memberof VimeoVideoUpdateDto
     */
    files?: Array<VideoFileDto> | null;
}
/**
 * 
 * @export
 * @interface WiredPlusResponseDto
 */
export interface WiredPlusResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WiredPlusResponseDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WiredPlusResponseDto
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WiredPlusResponseDto
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface WorkStationCreateDto
 */
export interface WorkStationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof WorkStationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkStationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationCreateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationCreateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<VimeoVideoDto>}
     * @memberof WorkStationCreateDto
     */
    videos?: Array<VimeoVideoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkStationCreateDto
     */
    vimeoPrefix?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationCreateDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface WorkStationDetailsDto
 */
export interface WorkStationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof WorkStationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationDetailsDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationDetailsDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<VimeoVideoDto>}
     * @memberof WorkStationDetailsDto
     */
    videos?: Array<VimeoVideoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDetailsDto
     */
    vimeoPrefix?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDetailsDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface WorkStationDto
 */
export interface WorkStationDto {
    /**
     * 
     * @type {number}
     * @memberof WorkStationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<VimeoVideoDto>}
     * @memberof WorkStationDto
     */
    videos?: Array<VimeoVideoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkStationDto
     */
    vimeoPrefix?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationDto
     */
    eventId?: number;
}
/**
 * 
 * @export
 * @interface WorkStationUpdateDto
 */
export interface WorkStationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof WorkStationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkStationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkStationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationUpdateDto
     */
    name?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<LocalizableStringDto>}
     * @memberof WorkStationUpdateDto
     */
    description?: Array<LocalizableStringDto> | null;
    /**
     * 
     * @type {Array<VimeoVideoDto>}
     * @memberof WorkStationUpdateDto
     */
    videos?: Array<VimeoVideoDto> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkStationUpdateDto
     */
    vimeoPrefix?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkStationUpdateDto
     */
    eventId?: number;
}

/**
 * AchievementApi - axios parameter creator
 * @export
 */
export const AchievementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AchievementHistoryEventDto} [achievementHistoryEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementEventPost: async (achievementHistoryEventDto?: AchievementHistoryEventDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Achievement/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementHistoryEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Achievement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementIdDelete', 'id', id)
            const localVarPath = `/api/Achievement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementIdGet', 'id', id)
            const localVarPath = `/api/Achievement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdPatch: async (id: number, achievementUpdateDto?: AchievementUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementIdPatch', 'id', id)
            const localVarPath = `/api/Achievement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdPut: async (id: number, achievementUpdateDto?: AchievementUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementIdPut', 'id', id)
            const localVarPath = `/api/Achievement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AchievementCreateDto} [achievementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementPost: async (achievementCreateDto?: AchievementCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Achievement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchievementApi - functional programming interface
 * @export
 */
export const AchievementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchievementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AchievementHistoryEventDto} [achievementHistoryEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementEventPost(achievementHistoryEventDto?: AchievementHistoryEventDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementEventPost(achievementHistoryEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchievementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementIdPatch(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementIdPatch(id, achievementUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementIdPut(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementIdPut(id, achievementUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AchievementCreateDto} [achievementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementPost(achievementCreateDto?: AchievementCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementPost(achievementCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AchievementApi - factory interface
 * @export
 */
export const AchievementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchievementApiFp(configuration)
    return {
        /**
         * 
         * @param {AchievementHistoryEventDto} [achievementHistoryEventDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementEventPost(achievementHistoryEventDto?: AchievementHistoryEventDto, options?: any): AxiosPromise<AchievementDto> {
            return localVarFp.apiAchievementEventPost(achievementHistoryEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementGet(options?: any): AxiosPromise<Array<AchievementDto>> {
            return localVarFp.apiAchievementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAchievementIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdGet(id: number, options?: any): AxiosPromise<AchievementDetailsDto> {
            return localVarFp.apiAchievementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdPatch(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any): AxiosPromise<AchievementDetailsDto> {
            return localVarFp.apiAchievementIdPatch(id, achievementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementUpdateDto} [achievementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementIdPut(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any): AxiosPromise<AchievementDetailsDto> {
            return localVarFp.apiAchievementIdPut(id, achievementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AchievementCreateDto} [achievementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementPost(achievementCreateDto?: AchievementCreateDto, options?: any): AxiosPromise<AchievementDetailsDto> {
            return localVarFp.apiAchievementPost(achievementCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchievementApi - object-oriented interface
 * @export
 * @class AchievementApi
 * @extends {BaseAPI}
 */
export class AchievementApi extends BaseAPI {
    /**
     * 
     * @param {AchievementHistoryEventDto} [achievementHistoryEventDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementEventPost(achievementHistoryEventDto?: AchievementHistoryEventDto, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementEventPost(achievementHistoryEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementGet(options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementIdDelete(id: number, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementIdGet(id: number, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AchievementUpdateDto} [achievementUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementIdPatch(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementIdPatch(id, achievementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AchievementUpdateDto} [achievementUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementIdPut(id: number, achievementUpdateDto?: AchievementUpdateDto, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementIdPut(id, achievementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AchievementCreateDto} [achievementCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementApi
     */
    public apiAchievementPost(achievementCreateDto?: AchievementCreateDto, options?: any) {
        return AchievementApiFp(this.configuration).apiAchievementPost(achievementCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AchievementCategoryApi - axios parameter creator
 * @export
 */
export const AchievementCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AchievementCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementCategoryIdDelete', 'id', id)
            const localVarPath = `/api/AchievementCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementCategoryIdGet', 'id', id)
            const localVarPath = `/api/AchievementCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdPatch: async (id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementCategoryIdPatch', 'id', id)
            const localVarPath = `/api/AchievementCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementCategoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdPut: async (id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAchievementCategoryIdPut', 'id', id)
            const localVarPath = `/api/AchievementCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementCategoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AchievementCategoryCreateDto} [achievementCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryPost: async (achievementCategoryCreateDto?: AchievementCategoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AchievementCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(achievementCategoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AchievementCategoryApi - functional programming interface
 * @export
 */
export const AchievementCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AchievementCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchievementCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryIdPatch(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryIdPatch(id, achievementCategoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryIdPut(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryIdPut(id, achievementCategoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AchievementCategoryCreateDto} [achievementCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAchievementCategoryPost(achievementCategoryCreateDto?: AchievementCategoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AchievementCategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAchievementCategoryPost(achievementCategoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AchievementCategoryApi - factory interface
 * @export
 */
export const AchievementCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AchievementCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryGet(options?: any): AxiosPromise<Array<AchievementCategoryDto>> {
            return localVarFp.apiAchievementCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAchievementCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdGet(id: number, options?: any): AxiosPromise<AchievementCategoryDetailsDto> {
            return localVarFp.apiAchievementCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdPatch(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any): AxiosPromise<AchievementCategoryDetailsDto> {
            return localVarFp.apiAchievementCategoryIdPatch(id, achievementCategoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryIdPut(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any): AxiosPromise<AchievementCategoryDetailsDto> {
            return localVarFp.apiAchievementCategoryIdPut(id, achievementCategoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AchievementCategoryCreateDto} [achievementCategoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAchievementCategoryPost(achievementCategoryCreateDto?: AchievementCategoryCreateDto, options?: any): AxiosPromise<AchievementCategoryDetailsDto> {
            return localVarFp.apiAchievementCategoryPost(achievementCategoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AchievementCategoryApi - object-oriented interface
 * @export
 * @class AchievementCategoryApi
 * @extends {BaseAPI}
 */
export class AchievementCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryGet(options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryIdDelete(id: number, options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryIdGet(id: number, options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryIdPatch(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryIdPatch(id, achievementCategoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AchievementCategoryUpdateDto} [achievementCategoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryIdPut(id: number, achievementCategoryUpdateDto?: AchievementCategoryUpdateDto, options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryIdPut(id, achievementCategoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AchievementCategoryCreateDto} [achievementCategoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AchievementCategoryApi
     */
    public apiAchievementCategoryPost(achievementCategoryCreateDto?: AchievementCategoryCreateDto, options?: any) {
        return AchievementCategoryApiFp(this.configuration).apiAchievementCategoryPost(achievementCategoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserResendInvitationDto} [applicationUserResendInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendInvitationPost: async (applicationUserResendInvitationDto?: ApplicationUserResendInvitationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/resendInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserResendInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserResendInvitationDto} [applicationUserResendInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto?: ApplicationUserResendInvitationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserResendInvitationDto} [applicationUserResendInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto?: ApplicationUserResendInvitationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserResendInvitationDto} [applicationUserResendInvitationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto?: ApplicationUserResendInvitationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserResendInvitationPost(applicationUserResendInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authorization/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authorization/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterB2BDto} [registerB2BDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationRegisterB2bPost: async (registerB2BDto?: RegisterB2BDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authorization/register/b2b`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerB2BDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterB2CDto} [registerB2CDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationRegisterB2cPost: async (registerB2CDto?: RegisterB2CDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authorization/register/b2c`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerB2CDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterB2BDto} [registerB2BDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorizationRegisterB2bPost(registerB2BDto?: RegisterB2BDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorizationRegisterB2bPost(registerB2BDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterB2CDto} [registerB2CDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorizationRegisterB2cPost(registerB2CDto?: RegisterB2CDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorizationRegisterB2cPost(registerB2CDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterB2BDto} [registerB2BDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationRegisterB2bPost(registerB2BDto?: RegisterB2BDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorizationRegisterB2bPost(registerB2BDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterB2CDto} [registerB2CDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorizationRegisterB2cPost(registerB2CDto?: RegisterB2CDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorizationRegisterB2cPost(registerB2CDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any) {
        return AuthorizationApiFp(this.configuration).apiAuthorizationBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any) {
        return AuthorizationApiFp(this.configuration).apiAuthorizationEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterB2BDto} [registerB2BDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiAuthorizationRegisterB2bPost(registerB2BDto?: RegisterB2BDto, options?: any) {
        return AuthorizationApiFp(this.configuration).apiAuthorizationRegisterB2bPost(registerB2BDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterB2CDto} [registerB2CDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiAuthorizationRegisterB2cPost(registerB2CDto?: RegisterB2CDto, options?: any) {
        return AuthorizationApiFp(this.configuration).apiAuthorizationRegisterB2cPost(registerB2CDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandAdminRoleApi - axios parameter creator
 * @export
 */
export const BrandAdminRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandAdminRoleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandAdminRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandAdminRoleIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBrandAdminRoleIdGet', 'id', id)
            const localVarPath = `/api/BrandAdminRole/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandAdminRoleApi - functional programming interface
 * @export
 */
export const BrandAdminRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandAdminRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandAdminRoleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandAdminRoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandAdminRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandAdminRoleIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandAdminRoleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandAdminRoleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandAdminRoleApi - factory interface
 * @export
 */
export const BrandAdminRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandAdminRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandAdminRoleGet(options?: any): AxiosPromise<Array<BrandAdminRoleDto>> {
            return localVarFp.apiBrandAdminRoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandAdminRoleIdGet(id: number, options?: any): AxiosPromise<BrandAdminRoleDetailsDto> {
            return localVarFp.apiBrandAdminRoleIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandAdminRoleApi - object-oriented interface
 * @export
 * @class BrandAdminRoleApi
 * @extends {BaseAPI}
 */
export class BrandAdminRoleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandAdminRoleApi
     */
    public apiBrandAdminRoleGet(options?: any) {
        return BrandAdminRoleApiFp(this.configuration).apiBrandAdminRoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandAdminRoleApi
     */
    public apiBrandAdminRoleIdGet(id: number, options?: any) {
        return BrandAdminRoleApiFp(this.configuration).apiBrandAdminRoleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandTermApi - axios parameter creator
 * @export
 */
export const BrandTermApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandTerm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBrandTermIdDelete', 'id', id)
            const localVarPath = `/api/BrandTerm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBrandTermIdGet', 'id', id)
            const localVarPath = `/api/BrandTerm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdPatch: async (id: number, brandTermUpdateDto?: BrandTermUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBrandTermIdPatch', 'id', id)
            const localVarPath = `/api/BrandTerm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandTermUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdPut: async (id: number, brandTermUpdateDto?: BrandTermUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBrandTermIdPut', 'id', id)
            const localVarPath = `/api/BrandTerm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandTermUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrandTermCreateDto} [brandTermCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermPost: async (brandTermCreateDto?: BrandTermCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandTerm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandTermCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandTermApi - functional programming interface
 * @export
 */
export const BrandTermApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandTermApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandTermDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandTermDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermIdPatch(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandTermDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermIdPatch(id, brandTermUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermIdPut(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandTermDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermIdPut(id, brandTermUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrandTermCreateDto} [brandTermCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandTermPost(brandTermCreateDto?: BrandTermCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandTermDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandTermPost(brandTermCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandTermApi - factory interface
 * @export
 */
export const BrandTermApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandTermApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermGet(options?: any): AxiosPromise<Array<BrandTermDto>> {
            return localVarFp.apiBrandTermGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandTermIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdGet(id: number, options?: any): AxiosPromise<BrandTermDetailsDto> {
            return localVarFp.apiBrandTermIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdPatch(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any): AxiosPromise<BrandTermDetailsDto> {
            return localVarFp.apiBrandTermIdPatch(id, brandTermUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermIdPut(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any): AxiosPromise<BrandTermDetailsDto> {
            return localVarFp.apiBrandTermIdPut(id, brandTermUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrandTermCreateDto} [brandTermCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandTermPost(brandTermCreateDto?: BrandTermCreateDto, options?: any): AxiosPromise<BrandTermDetailsDto> {
            return localVarFp.apiBrandTermPost(brandTermCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandTermApi - object-oriented interface
 * @export
 * @class BrandTermApi
 * @extends {BaseAPI}
 */
export class BrandTermApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermGet(options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermIdDelete(id: number, options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermIdGet(id: number, options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermIdPatch(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermIdPatch(id, brandTermUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BrandTermUpdateDto} [brandTermUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermIdPut(id: number, brandTermUpdateDto?: BrandTermUpdateDto, options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermIdPut(id, brandTermUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrandTermCreateDto} [brandTermCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandTermApi
     */
    public apiBrandTermPost(brandTermCreateDto?: BrandTermCreateDto, options?: any) {
        return BrandTermApiFp(this.configuration).apiBrandTermPost(brandTermCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarIdDelete', 'id', id)
            const localVarPath = `/api/Calendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarIdGet', 'id', id)
            const localVarPath = `/api/Calendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdPatch: async (id: number, calendarUpdateDto?: CalendarUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarIdPatch', 'id', id)
            const localVarPath = `/api/Calendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdPut: async (id: number, calendarUpdateDto?: CalendarUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarIdPut', 'id', id)
            const localVarPath = `/api/Calendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarCreateDto} [calendarCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarPost: async (calendarCreateDto?: CalendarCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarIdPatch(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarIdPatch(id, calendarUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarIdPut(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarIdPut(id, calendarUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalendarCreateDto} [calendarCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarPost(calendarCreateDto?: CalendarCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarPost(calendarCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarGet(options?: any): AxiosPromise<Array<CalendarDto>> {
            return localVarFp.apiCalendarGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCalendarIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdGet(id: number, options?: any): AxiosPromise<CalendarDetailsDto> {
            return localVarFp.apiCalendarIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdPatch(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any): AxiosPromise<CalendarDetailsDto> {
            return localVarFp.apiCalendarIdPatch(id, calendarUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarUpdateDto} [calendarUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarIdPut(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any): AxiosPromise<CalendarDetailsDto> {
            return localVarFp.apiCalendarIdPut(id, calendarUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalendarCreateDto} [calendarCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarPost(calendarCreateDto?: CalendarCreateDto, options?: any): AxiosPromise<CalendarDetailsDto> {
            return localVarFp.apiCalendarPost(calendarCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarGet(options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarIdDelete(id: number, options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarIdGet(id: number, options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CalendarUpdateDto} [calendarUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarIdPatch(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarIdPatch(id, calendarUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CalendarUpdateDto} [calendarUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarIdPut(id: number, calendarUpdateDto?: CalendarUpdateDto, options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarIdPut(id, calendarUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarCreateDto} [calendarCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public apiCalendarPost(calendarCreateDto?: CalendarCreateDto, options?: any) {
        return CalendarApiFp(this.configuration).apiCalendarPost(calendarCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCourseIdDelete', 'id', id)
            const localVarPath = `/api/Course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCourseIdGet', 'id', id)
            const localVarPath = `/api/Course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdModulesOrderPost: async (id: number, orderDto?: Array<OrderDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCourseIdModulesOrderPost', 'id', id)
            const localVarPath = `/api/Course/{id}/modulesOrder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdPatch: async (id: number, courseUpdateDto?: CourseUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCourseIdPatch', 'id', id)
            const localVarPath = `/api/Course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdPut: async (id: number, courseUpdateDto?: CourseUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCourseIdPut', 'id', id)
            const localVarPath = `/api/Course/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CourseCreateDto} [courseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursePost: async (courseCreateDto?: CourseCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseIdModulesOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseIdModulesOrderPost(id, orderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseIdPatch(id: number, courseUpdateDto?: CourseUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseIdPatch(id, courseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCourseIdPut(id: number, courseUpdateDto?: CourseUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCourseIdPut(id, courseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CourseCreateDto} [courseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoursePost(courseCreateDto?: CourseCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoursePost(courseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGet(options?: any): AxiosPromise<Array<CourseDto>> {
            return localVarFp.apiCourseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCourseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdGet(id: number, options?: any): AxiosPromise<CourseDetailsDto> {
            return localVarFp.apiCourseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdModulesOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): AxiosPromise<CourseDetailsDto> {
            return localVarFp.apiCourseIdModulesOrderPost(id, orderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdPatch(id: number, courseUpdateDto?: CourseUpdateDto, options?: any): AxiosPromise<CourseDetailsDto> {
            return localVarFp.apiCourseIdPatch(id, courseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CourseUpdateDto} [courseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseIdPut(id: number, courseUpdateDto?: CourseUpdateDto, options?: any): AxiosPromise<CourseDetailsDto> {
            return localVarFp.apiCourseIdPut(id, courseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CourseCreateDto} [courseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursePost(courseCreateDto?: CourseCreateDto, options?: any): AxiosPromise<CourseDetailsDto> {
            return localVarFp.apiCoursePost(courseCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseGet(options?: any) {
        return CourseApiFp(this.configuration).apiCourseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseIdDelete(id: number, options?: any) {
        return CourseApiFp(this.configuration).apiCourseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseIdGet(id: number, options?: any) {
        return CourseApiFp(this.configuration).apiCourseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<OrderDto>} [orderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseIdModulesOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any) {
        return CourseApiFp(this.configuration).apiCourseIdModulesOrderPost(id, orderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CourseUpdateDto} [courseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseIdPatch(id: number, courseUpdateDto?: CourseUpdateDto, options?: any) {
        return CourseApiFp(this.configuration).apiCourseIdPatch(id, courseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CourseUpdateDto} [courseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCourseIdPut(id: number, courseUpdateDto?: CourseUpdateDto, options?: any) {
        return CourseApiFp(this.configuration).apiCourseIdPut(id, courseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseCreateDto} [courseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public apiCoursePost(courseCreateDto?: CourseCreateDto, options?: any) {
        return CourseApiFp(this.configuration).apiCoursePost(courseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmbedApi - axios parameter creator
 * @export
 */
export const EmbedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmbedSlugGet: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('apiEmbedSlugGet', 'slug', slug)
            const localVarPath = `/api/Embed/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbedApi - functional programming interface
 * @export
 */
export const EmbedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmbedSlugGet(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmbedSlugGet(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbedApi - factory interface
 * @export
 */
export const EmbedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmbedSlugGet(slug: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmbedSlugGet(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbedApi - object-oriented interface
 * @export
 * @class EmbedApi
 * @extends {BaseAPI}
 */
export class EmbedApi extends BaseAPI {
    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbedApi
     */
    public apiEmbedSlugGet(slug: string, options?: any) {
        return EmbedApiFp(this.configuration).apiEmbedSlugGet(slug, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdDelete', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdGet', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPatch: async (id: number, eventUpdateDto?: EventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdPatch', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPut: async (id: number, eventUpdateDto?: EventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventIdPut', 'id', id)
            const localVarPath = `/api/Event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventPost: async (eventCreateDto?: EventCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdPatch(id, eventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventIdPut(id, eventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventPost(eventCreateDto?: EventCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventPost(eventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventGet(options?: any): AxiosPromise<Array<EventDto>> {
            return localVarFp.apiEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdGet(id: number, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdPatch(id, eventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventUpdateDto} [eventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventIdPut(id, eventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventCreateDto} [eventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventPost(eventCreateDto?: EventCreateDto, options?: any): AxiosPromise<EventDetailsDto> {
            return localVarFp.apiEventPost(eventCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventGet(options?: any) {
        return EventApiFp(this.configuration).apiEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdDelete(id: number, options?: any) {
        return EventApiFp(this.configuration).apiEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdGet(id: number, options?: any) {
        return EventApiFp(this.configuration).apiEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventUpdateDto} [eventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdPatch(id: number, eventUpdateDto?: EventUpdateDto, options?: any) {
        return EventApiFp(this.configuration).apiEventIdPatch(id, eventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventUpdateDto} [eventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventIdPut(id: number, eventUpdateDto?: EventUpdateDto, options?: any) {
        return EventApiFp(this.configuration).apiEventIdPut(id, eventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventCreateDto} [eventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public apiEventPost(eventCreateDto?: EventCreateDto, options?: any) {
        return EventApiFp(this.configuration).apiEventPost(eventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventLivestreamApi - axios parameter creator
 * @export
 */
export const EventLivestreamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EventLivestream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventLivestreamIdDelete', 'id', id)
            const localVarPath = `/api/EventLivestream/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventLivestreamIdGet', 'id', id)
            const localVarPath = `/api/EventLivestream/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdPatch: async (id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventLivestreamIdPatch', 'id', id)
            const localVarPath = `/api/EventLivestream/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventLivestreamUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdPut: async (id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventLivestreamIdPut', 'id', id)
            const localVarPath = `/api/EventLivestream/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventLivestreamUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventLivestreamCreateDto} [eventLivestreamCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamPost: async (eventLivestreamCreateDto?: EventLivestreamCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EventLivestream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventLivestreamCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventLivestreamApi - functional programming interface
 * @export
 */
export const EventLivestreamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventLivestreamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventLivestreamDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLivestreamDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamIdPatch(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLivestreamDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamIdPatch(id, eventLivestreamUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamIdPut(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLivestreamDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamIdPut(id, eventLivestreamUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventLivestreamCreateDto} [eventLivestreamCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventLivestreamPost(eventLivestreamCreateDto?: EventLivestreamCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLivestreamDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventLivestreamPost(eventLivestreamCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventLivestreamApi - factory interface
 * @export
 */
export const EventLivestreamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventLivestreamApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamGet(options?: any): AxiosPromise<Array<EventLivestreamDto>> {
            return localVarFp.apiEventLivestreamGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEventLivestreamIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdGet(id: number, options?: any): AxiosPromise<EventLivestreamDetailsDto> {
            return localVarFp.apiEventLivestreamIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdPatch(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any): AxiosPromise<EventLivestreamDetailsDto> {
            return localVarFp.apiEventLivestreamIdPatch(id, eventLivestreamUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamIdPut(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any): AxiosPromise<EventLivestreamDetailsDto> {
            return localVarFp.apiEventLivestreamIdPut(id, eventLivestreamUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventLivestreamCreateDto} [eventLivestreamCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventLivestreamPost(eventLivestreamCreateDto?: EventLivestreamCreateDto, options?: any): AxiosPromise<EventLivestreamDetailsDto> {
            return localVarFp.apiEventLivestreamPost(eventLivestreamCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventLivestreamApi - object-oriented interface
 * @export
 * @class EventLivestreamApi
 * @extends {BaseAPI}
 */
export class EventLivestreamApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamGet(options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamIdDelete(id: number, options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamIdGet(id: number, options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamIdPatch(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamIdPatch(id, eventLivestreamUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventLivestreamUpdateDto} [eventLivestreamUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamIdPut(id: number, eventLivestreamUpdateDto?: EventLivestreamUpdateDto, options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamIdPut(id, eventLivestreamUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventLivestreamCreateDto} [eventLivestreamCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventLivestreamApi
     */
    public apiEventLivestreamPost(eventLivestreamCreateDto?: EventLivestreamCreateDto, options?: any) {
        return EventLivestreamApiFp(this.configuration).apiEventLivestreamPost(eventLivestreamCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventTitleApi - axios parameter creator
 * @export
 */
export const EventTitleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EventTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventTitleIdDelete', 'id', id)
            const localVarPath = `/api/EventTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventTitleIdGet', 'id', id)
            const localVarPath = `/api/EventTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdPatch: async (id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventTitleIdPatch', 'id', id)
            const localVarPath = `/api/EventTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventTitleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdPut: async (id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiEventTitleIdPut', 'id', id)
            const localVarPath = `/api/EventTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventTitleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventTitleCreateDto} [eventTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitlePost: async (eventTitleCreateDto?: EventTitleCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/EventTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventTitleCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventTitleApi - functional programming interface
 * @export
 */
export const EventTitleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventTitleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventTitleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitleIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitleIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitleIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitleIdPatch(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitleIdPatch(id, eventTitleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitleIdPut(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitleIdPut(id, eventTitleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventTitleCreateDto} [eventTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEventTitlePost(eventTitleCreateDto?: EventTitleCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEventTitlePost(eventTitleCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventTitleApi - factory interface
 * @export
 */
export const EventTitleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventTitleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleGet(options?: any): AxiosPromise<Array<EventTitleDto>> {
            return localVarFp.apiEventTitleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEventTitleIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdGet(id: number, options?: any): AxiosPromise<EventTitleDetailsDto> {
            return localVarFp.apiEventTitleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdPatch(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any): AxiosPromise<EventTitleDetailsDto> {
            return localVarFp.apiEventTitleIdPatch(id, eventTitleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitleIdPut(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any): AxiosPromise<EventTitleDetailsDto> {
            return localVarFp.apiEventTitleIdPut(id, eventTitleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventTitleCreateDto} [eventTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEventTitlePost(eventTitleCreateDto?: EventTitleCreateDto, options?: any): AxiosPromise<EventTitleDetailsDto> {
            return localVarFp.apiEventTitlePost(eventTitleCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventTitleApi - object-oriented interface
 * @export
 * @class EventTitleApi
 * @extends {BaseAPI}
 */
export class EventTitleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitleGet(options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitleIdDelete(id: number, options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitleIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitleIdGet(id: number, options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitleIdPatch(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitleIdPatch(id, eventTitleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EventTitleUpdateDto} [eventTitleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitleIdPut(id: number, eventTitleUpdateDto?: EventTitleUpdateDto, options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitleIdPut(id, eventTitleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventTitleCreateDto} [eventTitleCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTitleApi
     */
    public apiEventTitlePost(eventTitleCreateDto?: EventTitleCreateDto, options?: any) {
        return EventTitleApiFp(this.configuration).apiEventTitlePost(eventTitleCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalAuthorizationApi - axios parameter creator
 * @export
 */
export const ExternalAuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KenticoForgottenPassword} [kenticoForgottenPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationForgottenPasswordPost: async (kenticoForgottenPassword?: KenticoForgottenPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/forgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoForgottenPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoGetUserInfoDto} [kenticoGetUserInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationGetUserInfoPost: async (kenticoGetUserInfoDto?: KenticoGetUserInfoDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/getUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoGetUserInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoLogoutDto} [kenticoLogoutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationLogoutPost: async (kenticoLogoutDto?: KenticoLogoutDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoLogoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoRegisterDto} [kenticoRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationRegisterPost: async (kenticoRegisterDto?: KenticoRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoResetPassword} [kenticoResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationResetPasswordPost: async (kenticoResetPassword?: KenticoResetPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoResetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoResetPasswordWithToken} [kenticoResetPasswordWithToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationResetPasswordWithTokenPost: async (kenticoResetPasswordWithToken?: KenticoResetPasswordWithToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/resetPasswordWithToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoResetPasswordWithToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoWorkstationBase} [kenticoWorkstationBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationWorkstationGetAllWorkstationsPost: async (kenticoWorkstationBase?: KenticoWorkstationBase, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/workstation/getAllWorkstations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoWorkstationBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KenticoWorkstation} [kenticoWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationWorkstationGetWorkstationPost: async (kenticoWorkstation?: KenticoWorkstation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ExternalAuthorization/workstation/getWorkstation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kenticoWorkstation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalAuthorizationApi - functional programming interface
 * @export
 */
export const ExternalAuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalAuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KenticoForgottenPassword} [kenticoForgottenPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword?: KenticoForgottenPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoGetUserInfoDto} [kenticoGetUserInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto?: KenticoGetUserInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoLogoutDto} [kenticoLogoutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationLogoutPost(kenticoLogoutDto?: KenticoLogoutDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationLogoutPost(kenticoLogoutDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoRegisterDto} [kenticoRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationRegisterPost(kenticoRegisterDto?: KenticoRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationRegisterPost(kenticoRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoResetPassword} [kenticoResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationResetPasswordPost(kenticoResetPassword?: KenticoResetPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationResetPasswordPost(kenticoResetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoResetPasswordWithToken} [kenticoResetPasswordWithToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken?: KenticoResetPasswordWithToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoWorkstationBase} [kenticoWorkstationBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase?: KenticoWorkstationBase, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KenticoWorkstation} [kenticoWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation?: KenticoWorkstation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalAuthorizationApi - factory interface
 * @export
 */
export const ExternalAuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalAuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {KenticoForgottenPassword} [kenticoForgottenPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword?: KenticoForgottenPassword, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoGetUserInfoDto} [kenticoGetUserInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto?: KenticoGetUserInfoDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoLogoutDto} [kenticoLogoutDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationLogoutPost(kenticoLogoutDto?: KenticoLogoutDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationLogoutPost(kenticoLogoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoRegisterDto} [kenticoRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationRegisterPost(kenticoRegisterDto?: KenticoRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationRegisterPost(kenticoRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoResetPassword} [kenticoResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationResetPasswordPost(kenticoResetPassword?: KenticoResetPassword, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationResetPasswordPost(kenticoResetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoResetPasswordWithToken} [kenticoResetPasswordWithToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken?: KenticoResetPasswordWithToken, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoWorkstationBase} [kenticoWorkstationBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase?: KenticoWorkstationBase, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KenticoWorkstation} [kenticoWorkstation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation?: KenticoWorkstation, options?: any): AxiosPromise<void> {
            return localVarFp.apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalAuthorizationApi - object-oriented interface
 * @export
 * @class ExternalAuthorizationApi
 * @extends {BaseAPI}
 */
export class ExternalAuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {KenticoForgottenPassword} [kenticoForgottenPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword?: KenticoForgottenPassword, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationForgottenPasswordPost(kenticoForgottenPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoGetUserInfoDto} [kenticoGetUserInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto?: KenticoGetUserInfoDto, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationGetUserInfoPost(kenticoGetUserInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoLogoutDto} [kenticoLogoutDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationLogoutPost(kenticoLogoutDto?: KenticoLogoutDto, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationLogoutPost(kenticoLogoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoRegisterDto} [kenticoRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationRegisterPost(kenticoRegisterDto?: KenticoRegisterDto, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationRegisterPost(kenticoRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoResetPassword} [kenticoResetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationResetPasswordPost(kenticoResetPassword?: KenticoResetPassword, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationResetPasswordPost(kenticoResetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoResetPasswordWithToken} [kenticoResetPasswordWithToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken?: KenticoResetPasswordWithToken, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationResetPasswordWithTokenPost(kenticoResetPasswordWithToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoWorkstationBase} [kenticoWorkstationBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase?: KenticoWorkstationBase, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationWorkstationGetAllWorkstationsPost(kenticoWorkstationBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KenticoWorkstation} [kenticoWorkstation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAuthorizationApi
     */
    public apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation?: KenticoWorkstation, options?: any) {
        return ExternalAuthorizationApiFp(this.configuration).apiExternalAuthorizationWorkstationGetWorkstationPost(kenticoWorkstation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDelete: async (fileId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileFileIdGet: async (fileId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/File/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost: async (file?: any, fileType?: FileType, isPublic?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileType !== undefined) { 
                localVarFormParams.append('FileType', new Blob([JSON.stringify(fileType)], { type: "application/json", }));
            }
    
            if (isPublic !== undefined) { 
                localVarFormParams.append('IsPublic', isPublic as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDelete(fileId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDelete(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileFileIdGet(fileId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePost(file, fileType, isPublic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDelete(fileId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiFileDelete(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileFileIdGet(fileId: number, options?: any): AxiosPromise<any> {
            return localVarFp.apiFileFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.apiFilePost(file, fileType, isPublic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {number} [fileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDelete(fileId?: number, options?: any) {
        return FileApiFp(this.configuration).apiFileDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileFileIdGet(fileId: number, options?: any) {
        return FileApiFp(this.configuration).apiFileFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {FileType} [fileType] 
     * @param {boolean} [isPublic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any) {
        return FileApiFp(this.configuration).apiFilePost(file, fileType, isPublic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguageIdDelete', 'id', id)
            const localVarPath = `/api/Language/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguageIdGet', 'id', id)
            const localVarPath = `/api/Language/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdPatch: async (id: number, languageUpdateDto?: LanguageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguageIdPatch', 'id', id)
            const localVarPath = `/api/Language/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdPut: async (id: number, languageUpdateDto?: LanguageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLanguageIdPut', 'id', id)
            const localVarPath = `/api/Language/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LanguageCreateDto} [languageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagePost: async (languageCreateDto?: LanguageCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageIdPatch(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageIdPatch(id, languageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguageIdPut(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguageIdPut(id, languageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LanguageCreateDto} [languageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagePost(languageCreateDto?: LanguageCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagePost(languageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageGet(options?: any): AxiosPromise<Array<LanguageDto>> {
            return localVarFp.apiLanguageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLanguageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdGet(id: number, options?: any): AxiosPromise<LanguageDetailsDto> {
            return localVarFp.apiLanguageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdPatch(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any): AxiosPromise<LanguageDetailsDto> {
            return localVarFp.apiLanguageIdPatch(id, languageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguageUpdateDto} [languageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguageIdPut(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any): AxiosPromise<LanguageDetailsDto> {
            return localVarFp.apiLanguageIdPut(id, languageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LanguageCreateDto} [languageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagePost(languageCreateDto?: LanguageCreateDto, options?: any): AxiosPromise<LanguageDetailsDto> {
            return localVarFp.apiLanguagePost(languageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageGet(options?: any) {
        return LanguageApiFp(this.configuration).apiLanguageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageIdDelete(id: number, options?: any) {
        return LanguageApiFp(this.configuration).apiLanguageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageIdGet(id: number, options?: any) {
        return LanguageApiFp(this.configuration).apiLanguageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LanguageUpdateDto} [languageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageIdPatch(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any) {
        return LanguageApiFp(this.configuration).apiLanguageIdPatch(id, languageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LanguageUpdateDto} [languageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguageIdPut(id: number, languageUpdateDto?: LanguageUpdateDto, options?: any) {
        return LanguageApiFp(this.configuration).apiLanguageIdPut(id, languageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguageCreateDto} [languageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLanguagePost(languageCreateDto?: LanguageCreateDto, options?: any) {
        return LanguageApiFp(this.configuration).apiLanguagePost(languageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModuleApi - axios parameter creator
 * @export
 */
export const ModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiModuleIdDelete', 'id', id)
            const localVarPath = `/api/Module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiModuleIdGet', 'id', id)
            const localVarPath = `/api/Module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdPatch: async (id: number, moduleUpdateDto?: ModuleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiModuleIdPatch', 'id', id)
            const localVarPath = `/api/Module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdPut: async (id: number, moduleUpdateDto?: ModuleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiModuleIdPut', 'id', id)
            const localVarPath = `/api/Module/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdSectionsOrderPost: async (id: number, orderDto?: Array<OrderDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiModuleIdSectionsOrderPost', 'id', id)
            const localVarPath = `/api/Module/{id}/sectionsOrder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModuleCreateDto} [moduleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulePost: async (moduleCreateDto?: ModuleCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moduleCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleApi - functional programming interface
 * @export
 */
export const ModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleIdPatch(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleIdPatch(id, moduleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleIdPut(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleIdPut(id, moduleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModuleIdSectionsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModuleIdSectionsOrderPost(id, orderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModuleCreateDto} [moduleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModulePost(moduleCreateDto?: ModuleCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModuleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModulePost(moduleCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModuleApi - factory interface
 * @export
 */
export const ModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleGet(options?: any): AxiosPromise<Array<ModuleDto>> {
            return localVarFp.apiModuleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiModuleIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdGet(id: number, options?: any): AxiosPromise<ModuleDetailsDto> {
            return localVarFp.apiModuleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdPatch(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any): AxiosPromise<ModuleDetailsDto> {
            return localVarFp.apiModuleIdPatch(id, moduleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ModuleUpdateDto} [moduleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdPut(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any): AxiosPromise<ModuleDetailsDto> {
            return localVarFp.apiModuleIdPut(id, moduleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModuleIdSectionsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): AxiosPromise<ModuleDetailsDto> {
            return localVarFp.apiModuleIdSectionsOrderPost(id, orderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModuleCreateDto} [moduleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulePost(moduleCreateDto?: ModuleCreateDto, options?: any): AxiosPromise<ModuleDetailsDto> {
            return localVarFp.apiModulePost(moduleCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleApi - object-oriented interface
 * @export
 * @class ModuleApi
 * @extends {BaseAPI}
 */
export class ModuleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleGet(options?: any) {
        return ModuleApiFp(this.configuration).apiModuleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleIdDelete(id: number, options?: any) {
        return ModuleApiFp(this.configuration).apiModuleIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleIdGet(id: number, options?: any) {
        return ModuleApiFp(this.configuration).apiModuleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ModuleUpdateDto} [moduleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleIdPatch(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any) {
        return ModuleApiFp(this.configuration).apiModuleIdPatch(id, moduleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ModuleUpdateDto} [moduleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleIdPut(id: number, moduleUpdateDto?: ModuleUpdateDto, options?: any) {
        return ModuleApiFp(this.configuration).apiModuleIdPut(id, moduleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<OrderDto>} [orderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModuleIdSectionsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any) {
        return ModuleApiFp(this.configuration).apiModuleIdSectionsOrderPost(id, orderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModuleCreateDto} [moduleCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModulePost(moduleCreateDto?: ModuleCreateDto, options?: any) {
        return ModuleApiFp(this.configuration).apiModulePost(moduleCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCurrentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetCurrentOrganisationDto} [setCurrentOrganisationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCurrentPost: async (setCurrentOrganisationDto?: SetCurrentOrganisationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setCurrentOrganisationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdDelete', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdGet', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPatch: async (id: number, organisationUpdateDto?: OrganisationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdPatch', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPut: async (id: number, organisationUpdateDto?: OrganisationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdPut', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost: async (organisationCreateDto?: OrganisationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateVimeoTokenDto} [updateVimeoTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationVimeoTokenPost: async (updateVimeoTokenDto?: UpdateVimeoTokenDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/vimeoToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVimeoTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationCurrentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationCurrentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetCurrentOrganisationDto} [setCurrentOrganisationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationCurrentPost(setCurrentOrganisationDto?: SetCurrentOrganisationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationCurrentPost(setCurrentOrganisationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdPatch(id, organisationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdPut(id, organisationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationPost(organisationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateVimeoTokenDto} [updateVimeoTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationVimeoTokenPost(updateVimeoTokenDto?: UpdateVimeoTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationVimeoTokenPost(updateVimeoTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCurrentGet(options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetCurrentOrganisationDto} [setCurrentOrganisationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationCurrentPost(setCurrentOrganisationDto?: SetCurrentOrganisationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationCurrentPost(setCurrentOrganisationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet(options?: any): AxiosPromise<Array<OrganisationDto>> {
            return localVarFp.apiOrganisationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdGet(id: number, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdPatch(id, organisationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdPut(id, organisationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationPost(organisationCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateVimeoTokenDto} [updateVimeoTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationVimeoTokenPost(updateVimeoTokenDto?: UpdateVimeoTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationVimeoTokenPost(updateVimeoTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationCurrentGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetCurrentOrganisationDto} [setCurrentOrganisationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationCurrentPost(setCurrentOrganisationDto?: SetCurrentOrganisationDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationCurrentPost(setCurrentOrganisationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdDelete(id: number, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdGet(id: number, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUpdateDto} [organisationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdPatch(id, organisationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUpdateDto} [organisationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdPut(id, organisationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationCreateDto} [organisationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationPost(organisationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateVimeoTokenDto} [updateVimeoTokenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationVimeoTokenPost(updateVimeoTokenDto?: UpdateVimeoTokenDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationVimeoTokenPost(updateVimeoTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationFileApi - axios parameter creator
 * @export
 */
export const OrganisationFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFileDelete: async (fileId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fileId 
         * @param {number} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFileFileIdGet: async (fileId: number, organisationId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiOrganisationFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/OrganisationFile/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFilePost: async (file?: any, fileType?: FileType, isPublic?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileType !== undefined) { 
                localVarFormParams.append('FileType', new Blob([JSON.stringify(fileType)], { type: "application/json", }));
            }
    
            if (isPublic !== undefined) { 
                localVarFormParams.append('IsPublic', isPublic as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationFileApi - functional programming interface
 * @export
 */
export const OrganisationFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationFileDelete(fileId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationFileDelete(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fileId 
         * @param {number} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationFileFileIdGet(fileId: number, organisationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationFileFileIdGet(fileId, organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationFilePost(file, fileType, isPublic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationFileApi - factory interface
 * @export
 */
export const OrganisationFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationFileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [fileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFileDelete(fileId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOrganisationFileDelete(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fileId 
         * @param {number} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFileFileIdGet(fileId: number, organisationId?: number, options?: any): AxiosPromise<any> {
            return localVarFp.apiOrganisationFileFileIdGet(fileId, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {FileType} [fileType] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.apiOrganisationFilePost(file, fileType, isPublic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationFileApi - object-oriented interface
 * @export
 * @class OrganisationFileApi
 * @extends {BaseAPI}
 */
export class OrganisationFileApi extends BaseAPI {
    /**
     * 
     * @param {number} [fileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationFileApi
     */
    public apiOrganisationFileDelete(fileId?: number, options?: any) {
        return OrganisationFileApiFp(this.configuration).apiOrganisationFileDelete(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fileId 
     * @param {number} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationFileApi
     */
    public apiOrganisationFileFileIdGet(fileId: number, organisationId?: number, options?: any) {
        return OrganisationFileApiFp(this.configuration).apiOrganisationFileFileIdGet(fileId, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {FileType} [fileType] 
     * @param {boolean} [isPublic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationFileApi
     */
    public apiOrganisationFilePost(file?: any, fileType?: FileType, isPublic?: boolean, options?: any) {
        return OrganisationFileApiFp(this.configuration).apiOrganisationFilePost(file, fileType, isPublic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationUserApi - axios parameter creator
 * @export
 */
export const OrganisationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser/achievements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsProgressGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser/achievements/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsUserIdGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiOrganisationUserAchievementsUserIdGet', 'userId', userId)
            const localVarPath = `/api/OrganisationUser/achievements/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdDelete', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdGet', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPatch: async (id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdPatch', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPut: async (id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdPut', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationUserInvitationDto} [organisationUserInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserInvitePost: async (organisationUserInvitationDto?: OrganisationUserInvitationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserOrganisationOrganisationIdGet: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationUserOrganisationOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/OrganisationUser/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserPost: async (organisationUserCreateDto?: OrganisationUserCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationUserApi - functional programming interface
 * @export
 */
export const OrganisationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserAchievementsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchievementGainedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserAchievementsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserAchievementsProgressGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchievementProgressDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserAchievementsProgressGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserAchievementsUserIdGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AchievementGainedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserAchievementsUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdPut(id, organisationUserUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationUserInvitationDto} [organisationUserInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserInvitePost(organisationUserInvitationDto?: OrganisationUserInvitationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserInvitePost(organisationUserInvitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserPost(organisationUserCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationUserApi - factory interface
 * @export
 */
export const OrganisationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsGet(options?: any): AxiosPromise<Array<AchievementGainedDto>> {
            return localVarFp.apiOrganisationUserAchievementsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsProgressGet(options?: any): AxiosPromise<Array<AchievementProgressDto>> {
            return localVarFp.apiOrganisationUserAchievementsProgressGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserAchievementsUserIdGet(userId: number, options?: any): AxiosPromise<Array<AchievementGainedDto>> {
            return localVarFp.apiOrganisationUserAchievementsUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserGet(options?: any): AxiosPromise<Array<OrganisationUserDto>> {
            return localVarFp.apiOrganisationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdGet(id: number, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdPut(id, organisationUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationUserInvitationDto} [organisationUserInvitationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserInvitePost(organisationUserInvitationDto?: OrganisationUserInvitationDto, options?: any): AxiosPromise<OrganisationUserDto> {
            return localVarFp.apiOrganisationUserInvitePost(organisationUserInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any): AxiosPromise<Array<OrganisationUserDto>> {
            return localVarFp.apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserPost(organisationUserCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationUserApi - object-oriented interface
 * @export
 * @class OrganisationUserApi
 * @extends {BaseAPI}
 */
export class OrganisationUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserAchievementsGet(options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserAchievementsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserAchievementsProgressGet(options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserAchievementsProgressGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserAchievementsUserIdGet(userId: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserAchievementsUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserGet(options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdDelete(id: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdGet(id: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdPut(id, organisationUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationUserInvitationDto} [organisationUserInvitationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserInvitePost(organisationUserInvitationDto?: OrganisationUserInvitationDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserInvitePost(organisationUserInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserPost(organisationUserCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParentEventApi - axios parameter creator
 * @export
 */
export const ParentEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ParentEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiParentEventIdDelete', 'id', id)
            const localVarPath = `/api/ParentEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiParentEventIdGet', 'id', id)
            const localVarPath = `/api/ParentEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdPatch: async (id: number, parentEventUpdateDto?: ParentEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiParentEventIdPatch', 'id', id)
            const localVarPath = `/api/ParentEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdPut: async (id: number, parentEventUpdateDto?: ParentEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiParentEventIdPut', 'id', id)
            const localVarPath = `/api/ParentEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParentEventCreateDto} [parentEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventPost: async (parentEventCreateDto?: ParentEventCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ParentEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parentEventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParentEventApi - functional programming interface
 * @export
 */
export const ParentEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParentEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParentEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventIdPatch(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventIdPatch(id, parentEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventIdPut(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventIdPut(id, parentEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParentEventCreateDto} [parentEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiParentEventPost(parentEventCreateDto?: ParentEventCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParentEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiParentEventPost(parentEventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParentEventApi - factory interface
 * @export
 */
export const ParentEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParentEventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventGet(options?: any): AxiosPromise<Array<ParentEventDto>> {
            return localVarFp.apiParentEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiParentEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdGet(id: number, options?: any): AxiosPromise<ParentEventDetailsDto> {
            return localVarFp.apiParentEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdPatch(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any): AxiosPromise<ParentEventDetailsDto> {
            return localVarFp.apiParentEventIdPatch(id, parentEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventIdPut(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any): AxiosPromise<ParentEventDetailsDto> {
            return localVarFp.apiParentEventIdPut(id, parentEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParentEventCreateDto} [parentEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiParentEventPost(parentEventCreateDto?: ParentEventCreateDto, options?: any): AxiosPromise<ParentEventDetailsDto> {
            return localVarFp.apiParentEventPost(parentEventCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParentEventApi - object-oriented interface
 * @export
 * @class ParentEventApi
 * @extends {BaseAPI}
 */
export class ParentEventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventGet(options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventIdDelete(id: number, options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventIdGet(id: number, options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventIdPatch(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventIdPatch(id, parentEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ParentEventUpdateDto} [parentEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventIdPut(id: number, parentEventUpdateDto?: ParentEventUpdateDto, options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventIdPut(id, parentEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParentEventCreateDto} [parentEventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParentEventApi
     */
    public apiParentEventPost(parentEventCreateDto?: ParentEventCreateDto, options?: any) {
        return ParentEventApiFp(this.configuration).apiParentEventPost(parentEventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdDelete', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdGet', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch: async (id: number, productUpdateDto?: ProductUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPatch', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut: async (id: number, productUpdateDto?: ProductUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPut', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost: async (productCreateDto?: ProductCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductIdProductIdGet: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiProductProductIdProductIdGet', 'productId', productId)
            const localVarPath = `/api/Product/ProductId/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPatch(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPut(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPost(productCreateDto?: ProductCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPost(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductProductIdProductIdGet(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductProductIdProductIdGet(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet(options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.apiProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet(id: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPatch(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPut(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductPost(productCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductProductIdProductIdGet(productId: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductProductIdProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductGet(options?: any) {
        return ProductApiFp(this.configuration).apiProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdDelete(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdGet(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdPatch(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdPut(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductPost(productCreateDto?: ProductCreateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductPost(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductProductIdProductIdGet(productId: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductProductIdProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductAvailabilityLanguageApi - axios parameter creator
 * @export
 */
export const ProductAvailabilityLanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductAvailabilityLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductAvailabilityLanguageIdDelete', 'id', id)
            const localVarPath = `/api/ProductAvailabilityLanguage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductAvailabilityLanguageIdGet', 'id', id)
            const localVarPath = `/api/ProductAvailabilityLanguage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdPatch: async (id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductAvailabilityLanguageIdPatch', 'id', id)
            const localVarPath = `/api/ProductAvailabilityLanguage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productAvailabilityLanguageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdPut: async (id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductAvailabilityLanguageIdPut', 'id', id)
            const localVarPath = `/api/ProductAvailabilityLanguage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productAvailabilityLanguageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductAvailabilityLanguageCreateDto} [productAvailabilityLanguageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguagePost: async (productAvailabilityLanguageCreateDto?: ProductAvailabilityLanguageCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductAvailabilityLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productAvailabilityLanguageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAvailabilityLanguageApi - functional programming interface
 * @export
 */
export const ProductAvailabilityLanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAvailabilityLanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductAvailabilityLanguageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAvailabilityLanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguageIdPatch(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAvailabilityLanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguageIdPatch(id, productAvailabilityLanguageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguageIdPut(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAvailabilityLanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguageIdPut(id, productAvailabilityLanguageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductAvailabilityLanguageCreateDto} [productAvailabilityLanguageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto?: ProductAvailabilityLanguageCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAvailabilityLanguageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAvailabilityLanguageApi - factory interface
 * @export
 */
export const ProductAvailabilityLanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAvailabilityLanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageGet(options?: any): AxiosPromise<Array<ProductAvailabilityLanguageDto>> {
            return localVarFp.apiProductAvailabilityLanguageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductAvailabilityLanguageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdGet(id: number, options?: any): AxiosPromise<ProductAvailabilityLanguageDetailsDto> {
            return localVarFp.apiProductAvailabilityLanguageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdPatch(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any): AxiosPromise<ProductAvailabilityLanguageDetailsDto> {
            return localVarFp.apiProductAvailabilityLanguageIdPatch(id, productAvailabilityLanguageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguageIdPut(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any): AxiosPromise<ProductAvailabilityLanguageDetailsDto> {
            return localVarFp.apiProductAvailabilityLanguageIdPut(id, productAvailabilityLanguageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductAvailabilityLanguageCreateDto} [productAvailabilityLanguageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto?: ProductAvailabilityLanguageCreateDto, options?: any): AxiosPromise<ProductAvailabilityLanguageDetailsDto> {
            return localVarFp.apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAvailabilityLanguageApi - object-oriented interface
 * @export
 * @class ProductAvailabilityLanguageApi
 * @extends {BaseAPI}
 */
export class ProductAvailabilityLanguageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguageGet(options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguageIdDelete(id: number, options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguageIdGet(id: number, options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguageIdPatch(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguageIdPatch(id, productAvailabilityLanguageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductAvailabilityLanguageUpdateDto} [productAvailabilityLanguageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguageIdPut(id: number, productAvailabilityLanguageUpdateDto?: ProductAvailabilityLanguageUpdateDto, options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguageIdPut(id, productAvailabilityLanguageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAvailabilityLanguageCreateDto} [productAvailabilityLanguageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAvailabilityLanguageApi
     */
    public apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto?: ProductAvailabilityLanguageCreateDto, options?: any) {
        return ProductAvailabilityLanguageApiFp(this.configuration).apiProductAvailabilityLanguagePost(productAvailabilityLanguageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReCaptchaApi - axios parameter creator
 * @export
 */
export const ReCaptchaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReCaptchaDto} [reCaptchaDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRecaptcha2Post: async (reCaptchaDto?: ReCaptchaDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/VerifyRecaptcha2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reCaptchaDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReCaptchaApi - functional programming interface
 * @export
 */
export const ReCaptchaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReCaptchaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReCaptchaDto} [reCaptchaDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyRecaptcha2Post(reCaptchaDto?: ReCaptchaDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRecaptcha2Post(reCaptchaDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReCaptchaApi - factory interface
 * @export
 */
export const ReCaptchaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReCaptchaApiFp(configuration)
    return {
        /**
         * 
         * @param {ReCaptchaDto} [reCaptchaDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRecaptcha2Post(reCaptchaDto?: ReCaptchaDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.verifyRecaptcha2Post(reCaptchaDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReCaptchaApi - object-oriented interface
 * @export
 * @class ReCaptchaApi
 * @extends {BaseAPI}
 */
export class ReCaptchaApi extends BaseAPI {
    /**
     * 
     * @param {ReCaptchaDto} [reCaptchaDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReCaptchaApi
     */
    public verifyRecaptcha2Post(reCaptchaDto?: ReCaptchaDto, options?: any) {
        return ReCaptchaApiFp(this.configuration).verifyRecaptcha2Post(reCaptchaDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingMissingLocalesGet: async (lang?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Reporting/missing-locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingMissingLocalesGet(lang?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<Array<MissingLanguageDto>>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingMissingLocalesGet(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingMissingLocalesGet(lang?: string, options?: any): AxiosPromise<Array<Array<Array<MissingLanguageDto>>>> {
            return localVarFp.apiReportingMissingLocalesGet(lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
    /**
     * 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingMissingLocalesGet(lang?: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingMissingLocalesGet(lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionApi - axios parameter creator
 * @export
 */
export const SectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Section`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdDelete', 'id', id)
            const localVarPath = `/api/Section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdGet', 'id', id)
            const localVarPath = `/api/Section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdItemsOrderPost: async (id: number, orderDto?: Array<OrderDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdItemsOrderPost', 'id', id)
            const localVarPath = `/api/Section/{id}/itemsOrder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPatch: async (id: number, sectionUpdateDto?: SectionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdPatch', 'id', id)
            const localVarPath = `/api/Section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPut: async (id: number, sectionUpdateDto?: SectionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionIdPut', 'id', id)
            const localVarPath = `/api/Section/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionCreateDto} [sectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionPost: async (sectionCreateDto?: SectionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Section`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdItemsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdItemsOrderPost(id, orderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdPatch(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdPatch(id, sectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionIdPut(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionIdPut(id, sectionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionCreateDto} [sectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionPost(sectionCreateDto?: SectionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionPost(sectionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionGet(options?: any): AxiosPromise<Array<SectionDto>> {
            return localVarFp.apiSectionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdGet(id: number, options?: any): AxiosPromise<SectionDetailsDto> {
            return localVarFp.apiSectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OrderDto>} [orderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdItemsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any): AxiosPromise<SectionDetailsDto> {
            return localVarFp.apiSectionIdItemsOrderPost(id, orderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPatch(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any): AxiosPromise<SectionDetailsDto> {
            return localVarFp.apiSectionIdPatch(id, sectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionUpdateDto} [sectionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionIdPut(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any): AxiosPromise<SectionDetailsDto> {
            return localVarFp.apiSectionIdPut(id, sectionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionCreateDto} [sectionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionPost(sectionCreateDto?: SectionCreateDto, options?: any): AxiosPromise<SectionDetailsDto> {
            return localVarFp.apiSectionPost(sectionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionGet(options?: any) {
        return SectionApiFp(this.configuration).apiSectionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionIdDelete(id: number, options?: any) {
        return SectionApiFp(this.configuration).apiSectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionIdGet(id: number, options?: any) {
        return SectionApiFp(this.configuration).apiSectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<OrderDto>} [orderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionIdItemsOrderPost(id: number, orderDto?: Array<OrderDto>, options?: any) {
        return SectionApiFp(this.configuration).apiSectionIdItemsOrderPost(id, orderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionUpdateDto} [sectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionIdPatch(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any) {
        return SectionApiFp(this.configuration).apiSectionIdPatch(id, sectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionUpdateDto} [sectionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionIdPut(id: number, sectionUpdateDto?: SectionUpdateDto, options?: any) {
        return SectionApiFp(this.configuration).apiSectionIdPut(id, sectionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionCreateDto} [sectionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public apiSectionPost(sectionCreateDto?: SectionCreateDto, options?: any) {
        return SectionApiFp(this.configuration).apiSectionPost(sectionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionFileItemApi - axios parameter creator
 * @export
 */
export const SectionFileItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionFileItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionFileItemIdDelete', 'id', id)
            const localVarPath = `/api/SectionFileItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionFileItemIdGet', 'id', id)
            const localVarPath = `/api/SectionFileItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdPatch: async (id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionFileItemIdPatch', 'id', id)
            const localVarPath = `/api/SectionFileItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionFileItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdPut: async (id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionFileItemIdPut', 'id', id)
            const localVarPath = `/api/SectionFileItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionFileItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionFileItemCreateDto} [sectionFileItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemPost: async (sectionFileItemCreateDto?: SectionFileItemCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionFileItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionFileItemCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionFileItemApi - functional programming interface
 * @export
 */
export const SectionFileItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionFileItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionFileItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionFileItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemIdPatch(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionFileItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemIdPatch(id, sectionFileItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemIdPut(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionFileItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemIdPut(id, sectionFileItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionFileItemCreateDto} [sectionFileItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionFileItemPost(sectionFileItemCreateDto?: SectionFileItemCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionFileItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionFileItemPost(sectionFileItemCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionFileItemApi - factory interface
 * @export
 */
export const SectionFileItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionFileItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemGet(options?: any): AxiosPromise<Array<SectionFileItemDto>> {
            return localVarFp.apiSectionFileItemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSectionFileItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdGet(id: number, options?: any): AxiosPromise<SectionFileItemDetailsDto> {
            return localVarFp.apiSectionFileItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdPatch(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any): AxiosPromise<SectionFileItemDetailsDto> {
            return localVarFp.apiSectionFileItemIdPatch(id, sectionFileItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemIdPut(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any): AxiosPromise<SectionFileItemDetailsDto> {
            return localVarFp.apiSectionFileItemIdPut(id, sectionFileItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionFileItemCreateDto} [sectionFileItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionFileItemPost(sectionFileItemCreateDto?: SectionFileItemCreateDto, options?: any): AxiosPromise<SectionFileItemDetailsDto> {
            return localVarFp.apiSectionFileItemPost(sectionFileItemCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionFileItemApi - object-oriented interface
 * @export
 * @class SectionFileItemApi
 * @extends {BaseAPI}
 */
export class SectionFileItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemGet(options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemIdDelete(id: number, options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemIdGet(id: number, options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemIdPatch(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemIdPatch(id, sectionFileItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionFileItemUpdateDto} [sectionFileItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemIdPut(id: number, sectionFileItemUpdateDto?: SectionFileItemUpdateDto, options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemIdPut(id, sectionFileItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionFileItemCreateDto} [sectionFileItemCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionFileItemApi
     */
    public apiSectionFileItemPost(sectionFileItemCreateDto?: SectionFileItemCreateDto, options?: any) {
        return SectionFileItemApiFp(this.configuration).apiSectionFileItemPost(sectionFileItemCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionScormItemApi - axios parameter creator
 * @export
 */
export const SectionScormItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionScormItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionScormItemIdDelete', 'id', id)
            const localVarPath = `/api/SectionScormItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionScormItemIdGet', 'id', id)
            const localVarPath = `/api/SectionScormItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdPatch: async (id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionScormItemIdPatch', 'id', id)
            const localVarPath = `/api/SectionScormItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionScormItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdPut: async (id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionScormItemIdPut', 'id', id)
            const localVarPath = `/api/SectionScormItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionScormItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionScormItemCreateDto} [sectionScormItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemPost: async (sectionScormItemCreateDto?: SectionScormItemCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionScormItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionScormItemCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionScormItemApi - functional programming interface
 * @export
 */
export const SectionScormItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionScormItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionScormItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionScormItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemIdPatch(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionScormItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemIdPatch(id, sectionScormItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemIdPut(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionScormItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemIdPut(id, sectionScormItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionScormItemCreateDto} [sectionScormItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionScormItemPost(sectionScormItemCreateDto?: SectionScormItemCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionScormItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionScormItemPost(sectionScormItemCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionScormItemApi - factory interface
 * @export
 */
export const SectionScormItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionScormItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemGet(options?: any): AxiosPromise<Array<SectionScormItemDto>> {
            return localVarFp.apiSectionScormItemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSectionScormItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdGet(id: number, options?: any): AxiosPromise<SectionScormItemDetailsDto> {
            return localVarFp.apiSectionScormItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdPatch(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any): AxiosPromise<SectionScormItemDetailsDto> {
            return localVarFp.apiSectionScormItemIdPatch(id, sectionScormItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemIdPut(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any): AxiosPromise<SectionScormItemDetailsDto> {
            return localVarFp.apiSectionScormItemIdPut(id, sectionScormItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionScormItemCreateDto} [sectionScormItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionScormItemPost(sectionScormItemCreateDto?: SectionScormItemCreateDto, options?: any): AxiosPromise<SectionScormItemDetailsDto> {
            return localVarFp.apiSectionScormItemPost(sectionScormItemCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionScormItemApi - object-oriented interface
 * @export
 * @class SectionScormItemApi
 * @extends {BaseAPI}
 */
export class SectionScormItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemGet(options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemIdDelete(id: number, options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemIdGet(id: number, options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemIdPatch(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemIdPatch(id, sectionScormItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionScormItemUpdateDto} [sectionScormItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemIdPut(id: number, sectionScormItemUpdateDto?: SectionScormItemUpdateDto, options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemIdPut(id, sectionScormItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionScormItemCreateDto} [sectionScormItemCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionScormItemApi
     */
    public apiSectionScormItemPost(sectionScormItemCreateDto?: SectionScormItemCreateDto, options?: any) {
        return SectionScormItemApiFp(this.configuration).apiSectionScormItemPost(sectionScormItemCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionTextItemApi - axios parameter creator
 * @export
 */
export const SectionTextItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionTextItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionTextItemIdDelete', 'id', id)
            const localVarPath = `/api/SectionTextItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionTextItemIdGet', 'id', id)
            const localVarPath = `/api/SectionTextItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdPatch: async (id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionTextItemIdPatch', 'id', id)
            const localVarPath = `/api/SectionTextItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionTextItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdPut: async (id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionTextItemIdPut', 'id', id)
            const localVarPath = `/api/SectionTextItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionTextItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionTextItemCreateDto} [sectionTextItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemPost: async (sectionTextItemCreateDto?: SectionTextItemCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionTextItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionTextItemCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionTextItemApi - functional programming interface
 * @export
 */
export const SectionTextItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionTextItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionTextItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionTextItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemIdPatch(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionTextItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemIdPatch(id, sectionTextItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemIdPut(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionTextItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemIdPut(id, sectionTextItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionTextItemCreateDto} [sectionTextItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionTextItemPost(sectionTextItemCreateDto?: SectionTextItemCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionTextItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionTextItemPost(sectionTextItemCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionTextItemApi - factory interface
 * @export
 */
export const SectionTextItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionTextItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemGet(options?: any): AxiosPromise<Array<SectionTextItemDto>> {
            return localVarFp.apiSectionTextItemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSectionTextItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdGet(id: number, options?: any): AxiosPromise<SectionTextItemDetailsDto> {
            return localVarFp.apiSectionTextItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdPatch(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any): AxiosPromise<SectionTextItemDetailsDto> {
            return localVarFp.apiSectionTextItemIdPatch(id, sectionTextItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemIdPut(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any): AxiosPromise<SectionTextItemDetailsDto> {
            return localVarFp.apiSectionTextItemIdPut(id, sectionTextItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionTextItemCreateDto} [sectionTextItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionTextItemPost(sectionTextItemCreateDto?: SectionTextItemCreateDto, options?: any): AxiosPromise<SectionTextItemDetailsDto> {
            return localVarFp.apiSectionTextItemPost(sectionTextItemCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionTextItemApi - object-oriented interface
 * @export
 * @class SectionTextItemApi
 * @extends {BaseAPI}
 */
export class SectionTextItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemGet(options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemIdDelete(id: number, options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemIdGet(id: number, options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemIdPatch(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemIdPatch(id, sectionTextItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionTextItemUpdateDto} [sectionTextItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemIdPut(id: number, sectionTextItemUpdateDto?: SectionTextItemUpdateDto, options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemIdPut(id, sectionTextItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionTextItemCreateDto} [sectionTextItemCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionTextItemApi
     */
    public apiSectionTextItemPost(sectionTextItemCreateDto?: SectionTextItemCreateDto, options?: any) {
        return SectionTextItemApiFp(this.configuration).apiSectionTextItemPost(sectionTextItemCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionVideoItemApi - axios parameter creator
 * @export
 */
export const SectionVideoItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionVideoItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionVideoItemIdDelete', 'id', id)
            const localVarPath = `/api/SectionVideoItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionVideoItemIdGet', 'id', id)
            const localVarPath = `/api/SectionVideoItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdPatch: async (id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionVideoItemIdPatch', 'id', id)
            const localVarPath = `/api/SectionVideoItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionVideoItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdPut: async (id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionVideoItemIdPut', 'id', id)
            const localVarPath = `/api/SectionVideoItem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionVideoItemUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SectionVideoItemCreateDto} [sectionVideoItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemPost: async (sectionVideoItemCreateDto?: SectionVideoItemCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SectionVideoItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectionVideoItemCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionVideoItemApi - functional programming interface
 * @export
 */
export const SectionVideoItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionVideoItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionVideoItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionVideoItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemIdPatch(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionVideoItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemIdPatch(id, sectionVideoItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemIdPut(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionVideoItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemIdPut(id, sectionVideoItemUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SectionVideoItemCreateDto} [sectionVideoItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionVideoItemPost(sectionVideoItemCreateDto?: SectionVideoItemCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionVideoItemDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionVideoItemPost(sectionVideoItemCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionVideoItemApi - factory interface
 * @export
 */
export const SectionVideoItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionVideoItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemGet(options?: any): AxiosPromise<Array<SectionVideoItemDto>> {
            return localVarFp.apiSectionVideoItemGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSectionVideoItemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdGet(id: number, options?: any): AxiosPromise<SectionVideoItemDetailsDto> {
            return localVarFp.apiSectionVideoItemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdPatch(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any): AxiosPromise<SectionVideoItemDetailsDto> {
            return localVarFp.apiSectionVideoItemIdPatch(id, sectionVideoItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemIdPut(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any): AxiosPromise<SectionVideoItemDetailsDto> {
            return localVarFp.apiSectionVideoItemIdPut(id, sectionVideoItemUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SectionVideoItemCreateDto} [sectionVideoItemCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionVideoItemPost(sectionVideoItemCreateDto?: SectionVideoItemCreateDto, options?: any): AxiosPromise<SectionVideoItemDetailsDto> {
            return localVarFp.apiSectionVideoItemPost(sectionVideoItemCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionVideoItemApi - object-oriented interface
 * @export
 * @class SectionVideoItemApi
 * @extends {BaseAPI}
 */
export class SectionVideoItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemGet(options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemIdDelete(id: number, options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemIdGet(id: number, options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemIdPatch(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemIdPatch(id, sectionVideoItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SectionVideoItemUpdateDto} [sectionVideoItemUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemIdPut(id: number, sectionVideoItemUpdateDto?: SectionVideoItemUpdateDto, options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemIdPut(id, sectionVideoItemUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionVideoItemCreateDto} [sectionVideoItemCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionVideoItemApi
     */
    public apiSectionVideoItemPost(sectionVideoItemCreateDto?: SectionVideoItemCreateDto, options?: any) {
        return SectionVideoItemApiFp(this.configuration).apiSectionVideoItemPost(sectionVideoItemCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SocialEmbedApi - axios parameter creator
 * @export
 */
export const SocialEmbedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SocialEmbed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSocialEmbedIdDelete', 'id', id)
            const localVarPath = `/api/SocialEmbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSocialEmbedIdGet', 'id', id)
            const localVarPath = `/api/SocialEmbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdPatch: async (id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSocialEmbedIdPatch', 'id', id)
            const localVarPath = `/api/SocialEmbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialEmbedUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdPut: async (id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSocialEmbedIdPut', 'id', id)
            const localVarPath = `/api/SocialEmbed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialEmbedUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialEmbedCreateDto} [socialEmbedCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedPost: async (socialEmbedCreateDto?: SocialEmbedCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SocialEmbed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialEmbedCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialEmbedApi - functional programming interface
 * @export
 */
export const SocialEmbedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialEmbedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SocialEmbedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialEmbedDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedIdPatch(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialEmbedDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedIdPatch(id, socialEmbedUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedIdPut(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialEmbedDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedIdPut(id, socialEmbedUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SocialEmbedCreateDto} [socialEmbedCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialEmbedPost(socialEmbedCreateDto?: SocialEmbedCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialEmbedDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialEmbedPost(socialEmbedCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SocialEmbedApi - factory interface
 * @export
 */
export const SocialEmbedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialEmbedApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedGet(options?: any): AxiosPromise<Array<SocialEmbedDto>> {
            return localVarFp.apiSocialEmbedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSocialEmbedIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdGet(id: number, options?: any): AxiosPromise<SocialEmbedDetailsDto> {
            return localVarFp.apiSocialEmbedIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdPatch(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any): AxiosPromise<SocialEmbedDetailsDto> {
            return localVarFp.apiSocialEmbedIdPatch(id, socialEmbedUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedIdPut(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any): AxiosPromise<SocialEmbedDetailsDto> {
            return localVarFp.apiSocialEmbedIdPut(id, socialEmbedUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialEmbedCreateDto} [socialEmbedCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialEmbedPost(socialEmbedCreateDto?: SocialEmbedCreateDto, options?: any): AxiosPromise<SocialEmbedDetailsDto> {
            return localVarFp.apiSocialEmbedPost(socialEmbedCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialEmbedApi - object-oriented interface
 * @export
 * @class SocialEmbedApi
 * @extends {BaseAPI}
 */
export class SocialEmbedApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedGet(options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedIdDelete(id: number, options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedIdGet(id: number, options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedIdPatch(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedIdPatch(id, socialEmbedUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SocialEmbedUpdateDto} [socialEmbedUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedIdPut(id: number, socialEmbedUpdateDto?: SocialEmbedUpdateDto, options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedIdPut(id, socialEmbedUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialEmbedCreateDto} [socialEmbedCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialEmbedApi
     */
    public apiSocialEmbedPost(socialEmbedCreateDto?: SocialEmbedCreateDto, options?: any) {
        return SocialEmbedApiFp(this.configuration).apiSocialEmbedPost(socialEmbedCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationApi - axios parameter creator
 * @export
 */
export const TranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTranslationIdDelete', 'id', id)
            const localVarPath = `/api/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTranslationIdGet', 'id', id)
            const localVarPath = `/api/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdPatch: async (id: number, translationUpdateDto?: TranslationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTranslationIdPatch', 'id', id)
            const localVarPath = `/api/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdPut: async (id: number, translationUpdateDto?: TranslationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTranslationIdPut', 'id', id)
            const localVarPath = `/api/Translation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslationCreateDto} [translationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationPost: async (translationCreateDto?: TranslationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TranslationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationIdPatch(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationIdPatch(id, translationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationIdPut(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationIdPut(id, translationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslationCreateDto} [translationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationPost(translationCreateDto?: TranslationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationPost(translationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationGet(options?: any): AxiosPromise<Array<TranslationDto>> {
            return localVarFp.apiTranslationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTranslationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdGet(id: number, options?: any): AxiosPromise<TranslationDetailsDto> {
            return localVarFp.apiTranslationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdPatch(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any): AxiosPromise<TranslationDetailsDto> {
            return localVarFp.apiTranslationIdPatch(id, translationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationUpdateDto} [translationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationIdPut(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any): AxiosPromise<TranslationDetailsDto> {
            return localVarFp.apiTranslationIdPut(id, translationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslationCreateDto} [translationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationPost(translationCreateDto?: TranslationCreateDto, options?: any): AxiosPromise<TranslationDetailsDto> {
            return localVarFp.apiTranslationPost(translationCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationGet(options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationIdDelete(id: number, options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationIdGet(id: number, options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TranslationUpdateDto} [translationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationIdPatch(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationIdPatch(id, translationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TranslationUpdateDto} [translationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationIdPut(id: number, translationUpdateDto?: TranslationUpdateDto, options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationIdPut(id, translationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationCreateDto} [translationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public apiTranslationPost(translationCreateDto?: TranslationCreateDto, options?: any) {
        return TranslationApiFp(this.configuration).apiTranslationPost(translationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VimeoLiveEventApi - axios parameter creator
 * @export
 */
export const VimeoLiveEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VimeoLiveEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoLiveEventIdDelete', 'id', id)
            const localVarPath = `/api/VimeoLiveEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoLiveEventIdGet', 'id', id)
            const localVarPath = `/api/VimeoLiveEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdPatch: async (id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoLiveEventIdPatch', 'id', id)
            const localVarPath = `/api/VimeoLiveEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoLiveEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdPut: async (id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoLiveEventIdPut', 'id', id)
            const localVarPath = `/api/VimeoLiveEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoLiveEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VimeoLiveEventCreateDto} [vimeoLiveEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventPost: async (vimeoLiveEventCreateDto?: VimeoLiveEventCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VimeoLiveEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoLiveEventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VimeoLiveEventApi - functional programming interface
 * @export
 */
export const VimeoLiveEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VimeoLiveEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoLiveEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoLiveEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventIdPatch(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoLiveEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventIdPatch(id, vimeoLiveEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventIdPut(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoLiveEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventIdPut(id, vimeoLiveEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VimeoLiveEventCreateDto} [vimeoLiveEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoLiveEventPost(vimeoLiveEventCreateDto?: VimeoLiveEventCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoLiveEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoLiveEventPost(vimeoLiveEventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VimeoLiveEventApi - factory interface
 * @export
 */
export const VimeoLiveEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VimeoLiveEventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventGet(options?: any): AxiosPromise<Array<VimeoLiveEventDto>> {
            return localVarFp.apiVimeoLiveEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiVimeoLiveEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdGet(id: number, options?: any): AxiosPromise<VimeoLiveEventDetailsDto> {
            return localVarFp.apiVimeoLiveEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdPatch(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any): AxiosPromise<VimeoLiveEventDetailsDto> {
            return localVarFp.apiVimeoLiveEventIdPatch(id, vimeoLiveEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventIdPut(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any): AxiosPromise<VimeoLiveEventDetailsDto> {
            return localVarFp.apiVimeoLiveEventIdPut(id, vimeoLiveEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VimeoLiveEventCreateDto} [vimeoLiveEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoLiveEventPost(vimeoLiveEventCreateDto?: VimeoLiveEventCreateDto, options?: any): AxiosPromise<VimeoLiveEventDetailsDto> {
            return localVarFp.apiVimeoLiveEventPost(vimeoLiveEventCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VimeoLiveEventApi - object-oriented interface
 * @export
 * @class VimeoLiveEventApi
 * @extends {BaseAPI}
 */
export class VimeoLiveEventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventGet(options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventIdDelete(id: number, options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventIdGet(id: number, options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventIdPatch(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventIdPatch(id, vimeoLiveEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VimeoLiveEventUpdateDto} [vimeoLiveEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventIdPut(id: number, vimeoLiveEventUpdateDto?: VimeoLiveEventUpdateDto, options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventIdPut(id, vimeoLiveEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VimeoLiveEventCreateDto} [vimeoLiveEventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoLiveEventApi
     */
    public apiVimeoLiveEventPost(vimeoLiveEventCreateDto?: VimeoLiveEventCreateDto, options?: any) {
        return VimeoLiveEventApiFp(this.configuration).apiVimeoLiveEventPost(vimeoLiveEventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VimeoVideoApi - axios parameter creator
 * @export
 */
export const VimeoVideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoFilterByTagsGet: async (tags?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VimeoVideo/filterByTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VimeoVideo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoVideoIdDelete', 'id', id)
            const localVarPath = `/api/VimeoVideo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoVideoIdGet', 'id', id)
            const localVarPath = `/api/VimeoVideo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdPatch: async (id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoVideoIdPatch', 'id', id)
            const localVarPath = `/api/VimeoVideo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoVideoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdPut: async (id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVimeoVideoIdPut', 'id', id)
            const localVarPath = `/api/VimeoVideo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoVideoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VimeoVideoCreateDto} [vimeoVideoCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoPost: async (vimeoVideoCreateDto?: VimeoVideoCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/VimeoVideo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoVideoCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VimeoVideoApi - functional programming interface
 * @export
 */
export const VimeoVideoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VimeoVideoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoFilterByTagsGet(tags?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoVideoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoFilterByTagsGet(tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoVideoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoVideoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoIdPatch(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoVideoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoIdPatch(id, vimeoVideoUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoIdPut(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoVideoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoIdPut(id, vimeoVideoUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VimeoVideoCreateDto} [vimeoVideoCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVimeoVideoPost(vimeoVideoCreateDto?: VimeoVideoCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoVideoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVimeoVideoPost(vimeoVideoCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VimeoVideoApi - factory interface
 * @export
 */
export const VimeoVideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VimeoVideoApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoFilterByTagsGet(tags?: Array<string>, options?: any): AxiosPromise<Array<VimeoVideoDto>> {
            return localVarFp.apiVimeoVideoFilterByTagsGet(tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoGet(options?: any): AxiosPromise<Array<VimeoVideoDto>> {
            return localVarFp.apiVimeoVideoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiVimeoVideoIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdGet(id: number, options?: any): AxiosPromise<VimeoVideoDetailsDto> {
            return localVarFp.apiVimeoVideoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdPatch(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any): AxiosPromise<VimeoVideoDetailsDto> {
            return localVarFp.apiVimeoVideoIdPatch(id, vimeoVideoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoIdPut(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any): AxiosPromise<VimeoVideoDetailsDto> {
            return localVarFp.apiVimeoVideoIdPut(id, vimeoVideoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VimeoVideoCreateDto} [vimeoVideoCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVimeoVideoPost(vimeoVideoCreateDto?: VimeoVideoCreateDto, options?: any): AxiosPromise<VimeoVideoDetailsDto> {
            return localVarFp.apiVimeoVideoPost(vimeoVideoCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VimeoVideoApi - object-oriented interface
 * @export
 * @class VimeoVideoApi
 * @extends {BaseAPI}
 */
export class VimeoVideoApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoFilterByTagsGet(tags?: Array<string>, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoFilterByTagsGet(tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoGet(options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoIdDelete(id: number, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoIdGet(id: number, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoIdPatch(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoIdPatch(id, vimeoVideoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VimeoVideoUpdateDto} [vimeoVideoUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoIdPut(id: number, vimeoVideoUpdateDto?: VimeoVideoUpdateDto, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoIdPut(id, vimeoVideoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VimeoVideoCreateDto} [vimeoVideoCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideoApi
     */
    public apiVimeoVideoPost(vimeoVideoCreateDto?: VimeoVideoCreateDto, options?: any) {
        return VimeoVideoApiFp(this.configuration).apiVimeoVideoPost(vimeoVideoCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WiredPlusApi - axios parameter creator
 * @export
 */
export const WiredPlusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<DynamicRowDto>} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusBulkDynamicRowUpdatePost: async (dynamicRowDto?: Array<DynamicRowDto>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/BulkDynamicRowUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicRowDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusCreateContactPost: async (contactCreateDto?: ContactCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/CreateContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DynamicRowDto} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusCreateDynamicRowPost: async (dynamicRowDto?: DynamicRowDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/CreateDynamicRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicRowDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusGetByEmailGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/GetByEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusGetByIdGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendTriggerCampaignToContactsDto} [sendTriggerCampaignToContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusSendTriggerCampaignToContactsPost: async (sendTriggerCampaignToContactsDto?: SendTriggerCampaignToContactsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/SendTriggerCampaignToContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTriggerCampaignToContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendTriggerCampaignToDynamicRowsDto} [sendTriggerCampaignToDynamicRowsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusSendTriggerCampaignToDynamicRowsPost: async (sendTriggerCampaignToDynamicRowsDto?: SendTriggerCampaignToDynamicRowsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/SendTriggerCampaignToDynamicRows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTriggerCampaignToDynamicRowsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusUpdateContactPost: async (contactCreateDto?: ContactCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WiredPlus/UpdateContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WiredPlusApi - functional programming interface
 * @export
 */
export const WiredPlusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WiredPlusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<DynamicRowDto>} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto?: Array<DynamicRowDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DynamicRowCreateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusCreateContactPost(contactCreateDto?: ContactCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusCreateContactPost(contactCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DynamicRowDto} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusCreateDynamicRowPost(dynamicRowDto?: DynamicRowDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicRowCreateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusCreateDynamicRowPost(dynamicRowDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusGetByEmailGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusGetByEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusGetByIdGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusGetByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendTriggerCampaignToContactsDto} [sendTriggerCampaignToContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto?: SendTriggerCampaignToContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WiredPlusResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendTriggerCampaignToDynamicRowsDto} [sendTriggerCampaignToDynamicRowsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto?: SendTriggerCampaignToDynamicRowsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WiredPlusResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWiredPlusUpdateContactPost(contactCreateDto?: ContactCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWiredPlusUpdateContactPost(contactCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WiredPlusApi - factory interface
 * @export
 */
export const WiredPlusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WiredPlusApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<DynamicRowDto>} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto?: Array<DynamicRowDto>, options?: any): AxiosPromise<Array<DynamicRowCreateDto>> {
            return localVarFp.apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusCreateContactPost(contactCreateDto?: ContactCreateDto, options?: any): AxiosPromise<ContactDto> {
            return localVarFp.apiWiredPlusCreateContactPost(contactCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DynamicRowDto} [dynamicRowDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusCreateDynamicRowPost(dynamicRowDto?: DynamicRowDto, options?: any): AxiosPromise<DynamicRowCreateDto> {
            return localVarFp.apiWiredPlusCreateDynamicRowPost(dynamicRowDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusGetByEmailGet(email?: string, options?: any): AxiosPromise<ContactDto> {
            return localVarFp.apiWiredPlusGetByEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusGetByIdGet(id?: number, options?: any): AxiosPromise<ContactDto> {
            return localVarFp.apiWiredPlusGetByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendTriggerCampaignToContactsDto} [sendTriggerCampaignToContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto?: SendTriggerCampaignToContactsDto, options?: any): AxiosPromise<WiredPlusResponseDto> {
            return localVarFp.apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendTriggerCampaignToDynamicRowsDto} [sendTriggerCampaignToDynamicRowsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto?: SendTriggerCampaignToDynamicRowsDto, options?: any): AxiosPromise<WiredPlusResponseDto> {
            return localVarFp.apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactCreateDto} [contactCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWiredPlusUpdateContactPost(contactCreateDto?: ContactCreateDto, options?: any): AxiosPromise<ContactDto> {
            return localVarFp.apiWiredPlusUpdateContactPost(contactCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WiredPlusApi - object-oriented interface
 * @export
 * @class WiredPlusApi
 * @extends {BaseAPI}
 */
export class WiredPlusApi extends BaseAPI {
    /**
     * 
     * @param {Array<DynamicRowDto>} [dynamicRowDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto?: Array<DynamicRowDto>, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusBulkDynamicRowUpdatePost(dynamicRowDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCreateDto} [contactCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusCreateContactPost(contactCreateDto?: ContactCreateDto, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusCreateContactPost(contactCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicRowDto} [dynamicRowDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusCreateDynamicRowPost(dynamicRowDto?: DynamicRowDto, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusCreateDynamicRowPost(dynamicRowDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusGetByEmailGet(email?: string, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusGetByEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusGetByIdGet(id?: number, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusGetByIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendTriggerCampaignToContactsDto} [sendTriggerCampaignToContactsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto?: SendTriggerCampaignToContactsDto, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusSendTriggerCampaignToContactsPost(sendTriggerCampaignToContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendTriggerCampaignToDynamicRowsDto} [sendTriggerCampaignToDynamicRowsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto?: SendTriggerCampaignToDynamicRowsDto, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusSendTriggerCampaignToDynamicRowsPost(sendTriggerCampaignToDynamicRowsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactCreateDto} [contactCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WiredPlusApi
     */
    public apiWiredPlusUpdateContactPost(contactCreateDto?: ContactCreateDto, options?: any) {
        return WiredPlusApiFp(this.configuration).apiWiredPlusUpdateContactPost(contactCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkStationApi - axios parameter creator
 * @export
 */
export const WorkStationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkStation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkStationIdDelete', 'id', id)
            const localVarPath = `/api/WorkStation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkStationIdGet', 'id', id)
            const localVarPath = `/api/WorkStation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdPatch: async (id: number, workStationUpdateDto?: WorkStationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkStationIdPatch', 'id', id)
            const localVarPath = `/api/WorkStation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workStationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdPut: async (id: number, workStationUpdateDto?: WorkStationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkStationIdPut', 'id', id)
            const localVarPath = `/api/WorkStation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workStationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkStationCreateDto} [workStationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationPost: async (workStationCreateDto?: WorkStationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkStation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workStationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkStationApi - functional programming interface
 * @export
 */
export const WorkStationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkStationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkStationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkStationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationIdPatch(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkStationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationIdPatch(id, workStationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationIdPut(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkStationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationIdPut(id, workStationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkStationCreateDto} [workStationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkStationPost(workStationCreateDto?: WorkStationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkStationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkStationPost(workStationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkStationApi - factory interface
 * @export
 */
export const WorkStationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkStationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationGet(options?: any): AxiosPromise<Array<WorkStationDto>> {
            return localVarFp.apiWorkStationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkStationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdGet(id: number, options?: any): AxiosPromise<WorkStationDetailsDto> {
            return localVarFp.apiWorkStationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdPatch(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any): AxiosPromise<WorkStationDetailsDto> {
            return localVarFp.apiWorkStationIdPatch(id, workStationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WorkStationUpdateDto} [workStationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationIdPut(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any): AxiosPromise<WorkStationDetailsDto> {
            return localVarFp.apiWorkStationIdPut(id, workStationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkStationCreateDto} [workStationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkStationPost(workStationCreateDto?: WorkStationCreateDto, options?: any): AxiosPromise<WorkStationDetailsDto> {
            return localVarFp.apiWorkStationPost(workStationCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkStationApi - object-oriented interface
 * @export
 * @class WorkStationApi
 * @extends {BaseAPI}
 */
export class WorkStationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationGet(options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationIdDelete(id: number, options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationIdGet(id: number, options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WorkStationUpdateDto} [workStationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationIdPatch(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationIdPatch(id, workStationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WorkStationUpdateDto} [workStationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationIdPut(id: number, workStationUpdateDto?: WorkStationUpdateDto, options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationIdPut(id, workStationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkStationCreateDto} [workStationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkStationApi
     */
    public apiWorkStationPost(workStationCreateDto?: WorkStationCreateDto, options?: any) {
        return WorkStationApiFp(this.configuration).apiWorkStationPost(workStationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


