import { message } from "antd";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { OrganisationApi, OrganisationDetailsDto, OrganisationUpdateDto, OrganisationUserApi, OrganisationUserInvitationDto, OrganisationUserRole, UpdateVimeoTokenDto } from "../../../../api";
import { setCurrentBrandId, setOrganisationDetails, setOrganisations } from "../../../../redux/organisationsSlice";
import ApiService from "../../../../services/apiService";
import { RoutingPaths } from "../../../routingContainer";
import OrganisationFormEdit from "./organisationsFormEdit";

export default function OrganisationFormEditContainer() {

    const apiService = useInjection(ApiService);
    const organisationApi = apiService.getApi(OrganisationApi);
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const [organisation, setOrganisation] = useState<OrganisationDetailsDto>(); // todo!!! use REDUX

    const setLocalOrganisationDetails = async () => {
        const organisationDetails = await organisationApi.apiOrganisationIdGet(+id);

        setOrganisation(organisationDetails.data)
    };

    useEffect(() => {
        setLocalOrganisationDetails();

        dispatch(setCurrentBrandId(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const onEditSubmit = async (value: OrganisationUpdateDto & UpdateVimeoTokenDto) => {
        try {
            const result = await organisationApi.apiOrganisationIdPut(+id, value);
            setOrganisation(result.data);

            if (value.token) {
                await organisationApi.apiOrganisationVimeoTokenPost({ organisationId: +id, token: value.token });
            }
            
            organisationApi.apiOrganisationGet().then(o => dispatch(setOrganisations(o.data)));

            message.success("Brand information updated");
        } catch (e) {
            return e.response.data;
        }
    };

    const deleteBrand = async () => {
        organisationApi.apiOrganisationIdDelete(+id).then( x => {
            organisationApi.apiOrganisationGet().then(o => {
                dispatch(setOrganisations(o.data));
                history.replace(RoutingPaths.Organisations);
                message.info("Brand has been deleted");
            });
        });
    }

    const onInviteSubmit = async (value: OrganisationUserInvitationDto) => {
        const organisationUserApi = apiService.getApi(OrganisationUserApi);
    
        try {
            value.organisationId = +id;
            value.role = OrganisationUserRole.BrandAdmin;
            await organisationUserApi.apiOrganisationUserInvitePost(value);
    
            apiService.getApi(OrganisationApi).apiOrganisationIdGet(+id).then(getResponse => {
                dispatch(setOrganisationDetails(getResponse.data));
                message.info("Invitation sent");
            });
        } catch (e) {
            return e.response.data;
        }
    }

    return <>
        <OrganisationFormEdit 
            deleteBrand={deleteBrand}
            onEditSubmit={onEditSubmit}
            onInviteSubmit={onInviteSubmit}
            organisation={organisation}
        />
    </>;
}
